<div class="row rh-main">
    <div class="col-lg-12">
        <div class="panel panel-default">
            <div class="panel-heading">Request <label>{{requestNumberFull}}: {{chRequestDesc}}</label></div>
            <div class="panel-body">

                <div class="row">
                    <div class="col-lg-5">
                        <label class="ebLabel">
                            Requested by:
                        </label>
                        <label class="requestLabel"> {{chRequestor}}</label>
                    </div>
                    <div class="col-lg-4">
                        <label class="ebLabel">
                            Request status: <label class="labelWidth"></label>
                        </label>
                        <label class="StatusLabel"
                            [ngStyle]="{'color':statusFontColor, 'backgroundColor':statusBgColor}">{{chRequestStatus}}</label>
                    </div>
                    <div class="col-lg-3">

                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5">
                        <label class="labelOn"></label><label class="ebLabel">
                            on:
                        </label>
                        <label class="requestLabel"> {{chRequestDate | customDateFormatPipe }}</label>
                    </div>
                    <div class="col-lg-5">
                        <label class="ebLabel">
                            Requested delivery:
                        </label>
                        <label class="requestLabel"> {{chReqDelivery | customDateFormatPipe }}</label>
                    </div>
                    <div class="col-lg-2" *ngIf="toolFrm ==''">
                        <button id="btnRequest" type="submit" name="button" (click)="gotoRequestPage()"
                            class="ebBtn ebBtn_color_darkGreen" style="margin-top:3px;min-width:100px;">Return to
                            Request
                            <i class="ebIcon ebIcon_quickSearch"></i>
                        </button>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- <form novalidate [formGroup]="changeDetailForm"> -->
    <div class="row rh-request rh-request-1">
        <div class="col-lg-7">
            <div class="panel panel-default">
                <div class="panel-heading">Request Info</div>
                <div class="panel-body">
                    <div class="row">
                        <div class="col-lg-3"><label class="ebLabel">Type of Request</label></div>
                        <div class="col-lg-9">
                            <input [ngModel]="changeData.typeOfRequest" type="text"
                                class="ebInput ebInput_labeled_top requestViewMode" style="width:220px;" readonly />
                            <input [ngModel]="changeData.parentType" type="text"
                                class="ebInput ebInput_labeled_top requestViewMode" style="width:250px;" readonly />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3"><label class="ebLabel">Long Description</label></div>
                        <div class="col-lg-9">
                            <textarea [ngModel]="changeData.longDescription" rows="9"
                                class="ebTextArea  ebTextArea_labeled_top ebTextArea_width_full requestViewMode"
                                readonly></textarea>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-lg-5">&nbsp;</div>
    </div>

    <div class="row rh-request">
        <div class="col-lg-12">
            <div class="panel panel-default">
                <div class="panel-heading">
                    Related Changes
                </div>

                <div class="panel-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <ag-grid-angular class="ag-theme-balham relatedChanges-grid" [rowData]="relatedChangeData"
                                [columnDefs]="columnDefs" [modules]="modules" (gridReady)="onGridReady($event)"
                                [animateRows]="true" [gridOptions]="gridOptions"
                                (selectionChanged)="onSelectionChanged()" (rowDataChanged)="onRowDataChanged()">
                            </ag-grid-angular>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="row rh-request">
        <div class="col-lg-12">
            <div class="panel panel-default">
                <div class="panel-heading">Change Status</div>
                <div class="panel-body">
                    <div class="row rh-row">

                        <div class="col-lg-7">
                            <div id="statusBox">

                                <div class="col-lg-7">
                                    <label class="ebLabel">Status</label>
                                    <input type="text" id="txtStatus" [ngModel]="changeLogStatusDesc"
                                        [ngStyle]="{'color':chStatusFontColor, 'backgroundColor':chStatusBgColor}"
                                        class="StatusLabel" readonly>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row rh-request rh-request-1">
        <div class="col-lg-7">
            <div class="panel panel-default">
                <div class="panel-heading">Change Info</div>

                <div class="panel-body">
                    <div class="row">
                        <div class="col-lg-2"><label class="ebLabel">Category</label></div>
                        <div class="col-lg-5">
                            <input [ngModel]="changeData.categoryName" type="text"
                                class="ebInput ebInput_labeled_top requestViewMode" style="width:270px;" readonly />
                        </div>
                        <div class="col-lg-1"><label class="ebLabel">Version</label></div>
                        <div class="col-lg-4">
                            <input [ngModel]="changeData.version" type="text"
                                class="ebInput ebInput_labeled_top requestDatetxt requestViewMode" readonly />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2"><label class="ebLabel">Executed By</label></div>
                        <div class="col-lg-5">
                            <input [ngModel]="changeData.executedBy" type="text"
                                class="ebInput ebInput_labeled_top requestViewMode" style="width:270px;" readonly />
                        </div>
                        <div class="col-lg-1"><label class="ebLabel">On</label></div>
                        <div class="col-lg-4">
                            <input type="text" [ngModel]="changeData.executionOn"
                                class="ebInput ebInput_labeled_top requestDatetxt requestViewMode" readonly />
                            &nbsp;&nbsp;&nbsp;<input [ngModel]="changeData.executionTime" type="text"
                                class="ebInput ebInput_labeled_top requestViewMode" style="width:60px;" readonly />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2"><label class="ebLabel">Node(s)</label></div>
                        <div class="col-lg-10">
                            <input [ngModel]="changeData.nodes" type="text"
                                class="ebInput ebInput_labeled_top requestViewMode" style="width:270px;" readonly />
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <div class="panel panel-default">
                <div class="panel-heading">Change Result</div>
                <div class="panel-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <textarea [ngModel]="changeData.changeResult" rows="6"
                                class="ebTextArea  ebTextArea_labeled_top ebTextArea_width_full requestViewMode"
                                readonly></textarea>
                        </div>
                    </div>
                    <div class="row" style="height: 2px;">
                        <div class="col-lg-12">&nbsp;</div>
                    </div>
                </div>
            </div>
        </div>

    </div>

<!-- </form> -->

<div class="row rh-request" *ngIf="showFilesDiv">
    <div class="col-lg-12">
        <div class="panel panel-default">
            <div class="panel-heading">
                Attachments of all related changes
            </div>

            <div class="panel-body">
                <div class="row">
                    <div class="col-lg-12">
                        <table class="table table-striped table-bordered">

                            <tbody>
                                <tr *ngFor="let files of changeLogFileList">
                                    <td>
                                        <a (click)="getChangeFile(files)" class="fileLink">
                                            {{files.fileName}}
                                        </a>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
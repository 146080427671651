<h2 mat-dialog-title>{{pgTitle}}</h2>
<mat-dialog-content>
<!-- The Modal -->
<div id="firewallModal">
   
    
        <div class="row rh-request">
            <div class="col-lg-12" >
            <div class="panel panel-default">
                                
                <div class="panel-body">                
                    <div class="row inputBoxRow">                        
                            <div class="col-lg-12">
                                <div [innerHTML]="fwrulesDisp"></div>
                            </div>                           
                                                                            
                    </div>    

                    
                </div>

            </div>
            </div>
        </div>        
          
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">

    <div class="firewallFooter">
       
    <span>
        <button type="button" class="ebBtn" (click) = "close()">Close</button>
    </span>
    </div>

</mat-dialog-actions>

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
.catch(err => console.error(err));

/*if(document.baseURI.indexOf("localhost")==-1) {
  if(document.cookie.indexOf("ISTCookie2")==-1)
  {
    if(document.baseURI.indexOf("localhost")!=-1) {
      var querystring=window.location.href;
      querystring=querystring.replace('http://localhost:4200','');
     querystring=querystring.replace('/#','');
      window.location.href = "http://localhost:51682?v2=" + querystring;      
    }
   else if(document.baseURI.indexOf("isrtest")!=-1) {
      var querystring=window.location.href;
      querystring=querystring.replace('https://isrtest.enn.ericsson.net','');
      querystring=querystring.replace('/#','');
      window.location.href = "https://isrtest.enn.ericsson.net?v2=" + querystring;      
    }
    else if(document.baseURI.indexOf("isr")!=-1) {
      var querystring=window.location.href;
      querystring=querystring.replace('https://isr.enn.ericsson.net','');
      querystring=querystring.replace('/#','');
      window.location.href = "https://isr.enn.ericsson.net?v2=" + querystring;
    }
  } 
  // else{    
  //   var su_fwchanges;
  //   if(document.cookie.indexOf("su_fwchanges=True")	!=-1){
  //     su_fwchanges=true;
  //   }else{
  //     su_fwchanges=false;
  //   }    
  //   //su_fwchanges = document.cookie.substring(document.cookie.indexOf("su_fwchanges"),document.cookie.indexOf("Now")).replace("&",'').replace("username=",'').replace("su_fwchanges=",'')
    
  //    if(su_fwchanges==false){
  //     if(document.baseURI.indexOf("isrtest")!=-1) {
  //      window.location.href = "https://isrtest.enn.ericsson.net";
  //     }
  //     else if(document.baseURI.indexOf("isr")!=-1) {
  //       window.location.href = "https://isr.enn.ericsson.net";
  //     }
  //    } else{
  //         platformBrowserDynamic().bootstrapModule(AppModule)
  //         .catch(err => console.error(err));     
  //     } 
  // }
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
  
}    else{   
        platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
}*/

import { Component, OnInit } from '@angular/core';
import { SharedService } from '../Shared/shared.service';

@Component({
  selector: 'app-message-box-header',
  templateUrl: './message-box-header.component.html',
  styleUrls: ['./message-box-header.component.css']
})
export class MessageBoxHeaderComponent implements OnInit {
  message = "";
  headerMessage = "";

  constructor(private sharedSvc:SharedService) { }

  ngOnInit(): void {
    this.headerMessage = this.sharedSvc.messageBoxHeaderMsg;
    this.message = this.sharedSvc.messageBoxDetailMsg;
  }

}

<!-- <h1>I am component</h1>
<br><br> -->
<!-- <app-first></app-first> -->
<!-- <app-directive-example></app-directive-example> -->

<!-- <h1 class="header-nav">Routing</h1>
<hr>
<div class="container">
    <div class="row">
        <ul class="nav nav-pills">
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact : true}"><a routerLink="/">Home</a></li>
            <li routerLinkActive="active"><a routerLink="/blog">Blog</a></li>
            <li routerLinkActive="active"><a routerLink="/about">About</a></li>
        </ul>
    </div>
</div>
<div ><h2> Place for components</h2>
<router-outlet></router-outlet>
</div> -->

<!-- <app-template-driven></app-template-driven> -->

<!-- <app-reactive-forms></app-reactive-forms> -->

<!-- <app-list></app-list> -->

<!-- <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait. </p>  
</ngx-spinner> -->

<!-- <mat-progress-bar mode="indeterminate" *ngIf="progressBarService.visibility | async"></mat-progress-bar> -->
<!-- <mat-progress-bar mode="indeterminate" [hidden]="!(progressBarService.visibility | async)"></mat-progress-bar> -->
<!--<div *ngIf="isDataAvailable">-->
 <app-header></app-header>
 
<div [ngClass]="{'container':islogin, 'containerlogin': !islogin}" id="pageContent">
    <!-- [ngClass]="hideheader" class="container" <app-isr></app-isr> -->
    <router-outlet></router-outlet>
   
</div>
<app-footer></app-footer>
<!--</div>-->



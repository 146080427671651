import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import {UntypedFormGroup, FormControl,UntypedFormBuilder, Validators, FormGroupName } from '@angular/forms';
import {SendRequestViewService} from '../send-request-view/send-request-view.service';
import {SharedService} from '../Shared/shared.service';
import {RequestModel, TemplateModel} from '../model/request.model';
import {sampleReq} from '../model/request.model';
import {RequestDefinitionsModel} from '../model/requestDefinitions.model';
import{userModel} from '../model/user.model';
import{LoginModel} from '../model/login.model';
import {StatusList,StatusNames,TypeOfRequests} from '../model/enum.model';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HtmlParser } from '@angular/compiler';
import { NgxSpinnerService } from 'ngx-spinner';

//import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-send-request-view',
  templateUrl: './send-request-view.component.html',
  styleUrls: ['./send-request-view.component.css'],
  providers: [RequestModel,RequestDefinitionsModel,userModel,LoginModel,TemplateModel]
})
export class SendRequestViewComponent implements OnInit {  
  @ViewChild('tooltipTemplate', { read: TemplateRef }) tooltipTemplate!: TemplateRef<any>;
  tooltipContent: string = "Please note that incomplete templates will be rejected.<br><br>After pressing the 'Download template' button you must open or save the template to your local drive (or desktop).<br><font color='red'> Watch your screen carefully: An extra protection message might be shown on the top or bottom of the page.</font> <br><br>When having completely filled the template do not forget to SAVE IT! Remember where you have saved it, so you can browse & upload it in the next step.";
  tooltipRef: any;

  sendRequestForm: UntypedFormGroup;
  formData: FormData = new FormData();
  submitted = false;
  fileBytes: Uint8Array | null = null;
  templateFile : any = '';
  requestType:String='';
  requestSubType:String='';
  templatePath:string;
  attachFile:any = '';

  comapny:string='';
  userName:String='';
  devMode:boolean=false;
  approverListOnly:Boolean=false;
  manager:String='';
  managerEmail:String='';
  authorized:Boolean=false;
  ISTCookieValue: string;
  showSubTypeOfRequest:boolean = true;
  showApprovers:Boolean = false;
  fileMetadata: any;
  tempdateDate:any;
  title = 'appBootstrap';  
  closeResult: string;
  // Safe HTML content
  tooltipHtml: string;
  uploadHtmlcontent:string = "<html>To attach a file to this request, click <b>Choose file</b> or <b>Browse</b> to select the file.</html>";
  uploadAlertMsg:string = "Only file extentions xls*, doc*, zip, 7z and pdf are allowed. Max. 8 MB"
  panelCount:number = 0;
  isForm:Boolean = false;
  isWrongFile:Boolean = false;
  DoAttach:Boolean = false;

  constructor(private formBuilder: UntypedFormBuilder, 
    private sendRequestViewSvc: SendRequestViewService,
     private sharedService: SharedService,
     private reqModel:RequestModel, 
     private userModel:userModel, 
     private loginModel:LoginModel,
     public reqDefinitionModel:RequestDefinitionsModel,
     private cookieService: CookieService,
     private router: Router, 
     private sanitizer: DomSanitizer,
     private viewContainerRef: ViewContainerRef,
    private templateModel:TemplateModel,
     private SpinnerService: NgxSpinnerService) {
      // Define HTML content and sanitize it
      //this.tooltipHtml = ("Please note that incomplete templates will be rejected.<br><br>After pressing the 'Download template' button you must open or save the template to your local drive (or desktop).<br><font color='red'> Watch your screen carefully: An extra protection message might be shown on the top or bottom of the page.</font> <br><br>When having completely filled the template do not forget to SAVE IT! Remember where you have saved it, so you can browse & upload it in the next step.").replace(/<br\s*[\/]?>/gi, "\n");
    //this.tooltipHtml = this.sanitizer.bypassSecurityTrustHtml("Please note that incomplete templates will be rejected.<br><br>After pressing the 'Download template' button you must open or save the template to your local drive (or desktop).<br><font color='red'> Watch your screen carefully: An extra protection message might be shown on the top or bottom of the page.</font> <br><br>When having completely filled the template do not forget to SAVE IT! Remember where you have saved it, so you can browse & upload it in the next step.");
    this.tooltipHtml =  "<span>Please note that incomplete templates will be rejected.<br><br>After pressing the 'Download template' button you must open or save the template to your local drive (or desktop).<br><font color='red'> Watch your screen carefully: An extra protection message might be shown on the top or bottom of the page.</font> <br><br>When having completely filled the template do not forget to SAVE IT! Remember where you have saved it, so you can browse & upload it in the next step.</span>";
      }

  ngOnInit() {
    if(window.history.state.reqData !=undefined){
    this.sendRequestForm=this.formBuilder.group({
      downloadFile: [''],
      templateFile: [''],
      addInfo:[''],
      longDescription:[''],
      attchaFile:['']
      });      
      //this.loginModel=window.history.state.loginModel;
      this.userModel=window.history.state.userModel;
      this.reqModel= window.history.state.reqData;
      this.requestType=this.reqModel.requestTypeText;
      this.requestSubType=this.reqModel.requestSubTypeText;
      this.reqDefinitionModel=window.history.state.reqDefinitions;

      this.authorized=this.reqModel.authorized;
      
      if(!this.authorized && this.reqModel.hasApprovers){
      this.showApprovers = true;
      }

      if(this.reqModel.isParentRequest)
      {
        this.showSubTypeOfRequest = false;
      }
     /* this.userName=this.userModel.email;
      this.devMode=this.userModel.devMode;*/
     
    this.ISTCookieValue = this.cookieService.get('ISTCookie2');
        this.userName = this.userModel.email;
        var devmode1 = this.ISTCookieValue.substring(this.ISTCookieValue.indexOf("devmode"));
      this.devMode =  devmode1.substring(devmode1.indexOf("devmode"), devmode1.length).replace("devmode=", '') == 'true' ? true : false;
      
      console.log('testing',this.devMode);
      this.approverListOnly=false;
    }
    else
    {
      this.router.navigate(['/createNewRequest']);
    }
   
    this.isForm = this.reqDefinitionModel.hasForm;
    this.DoAttach = this.reqDefinitionModel.doAttach;
    /*this.sendRequestViewSvc.getFileMetadata(this.templatePath).subscribe(
      (data) => {
        this.fileMetadata = data;
        this.tempdateDate = this.fileMetadata.modified;
      },
      (error) => {
        console.error('Error fetching file metadata:', error);
      }
    );*/   
   this.templateModel.templatePath = this.reqDefinitionModel.formFile;
    this.sendRequestViewSvc.GetUrlData(this.templateModel).subscribe(date => {
      this.tempdateDate = date;
      console.log('File last modified date:', date);
    });

  }
  get validated() { return this.sendRequestForm.controls; }
  getManager(message: string) {
    this.manager = message;
  }
  getManagerEmail(message: string) {
    this.managerEmail = message;
  }
  GetTemplate(){
    //var url = this.sharedService.API_URL + "GetFile.ashx?file=" + "temp\\DownloadTemplate\\" + this.reqDefinitionModel.formFile;
    //var url = this.sharedService.API_URL + "GetFile.ashx?file=" + "App_Data\\forms\\" + this.reqDefinitionModel.formFile;
    this.templatePath =this.sharedService.API_URL + "GetFile.ashx?file=" + "App_Data\\forms\\" + this.reqDefinitionModel.formFile;
    if(this.templatePath != "" && this.templatePath != undefined)
    {
    window.open(this.templatePath);
    }
    else
    {
      this.sharedService.showMessageBox("No files are there");
    }
  }
  handleFileInput(files: FileList){
    if(files.length > 0){
      const file =files.item(0);
      this.isWrongFile = false;

      //pdf , zip , xls , xlsx , xlsm , doc, docx
      const allowedTypes = ['application/pdf','application/zip','application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel.sheet.macroEnabled.12','application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ];

      if (allowedTypes.includes(file.type)) {
        this.templateFile = files.item(0);
        this.convertFileToBytes(this.templateFile);
        console.log(this.templateFile.name.split('.').pop());
        if(this.reqDefinitionModel.fileExtension != this.templateFile.name.split('.').pop())
        {
          this.isWrongFile = true;
        }
      } else {
       // this.message = 'Invalid file type. Please select a JPG, PNG, or PDF file.';
       this.sharedService.showMessageBox(this.uploadAlertMsg);
       const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
       if (fileInput) {
        fileInput.value = ''; // Clear the file input
      }
        this.templateFile = ''; // Reset if the file type is invalid
      }
    
    }
  }
  attachFileInput(files: FileList){
    if(files.length > 0){
      this.attachFile = files.item(0);     
    }
  }
  convertFileToBytes(file: File): void {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.result) {
        // Convert ArrayBuffer to Uint8Array
        this.fileBytes = new Uint8Array(reader.result as ArrayBuffer);
        console.log('File as bytes:', this.fileBytes);
      }
    };

    reader.onerror = (error) => {
      console.error('Error reading file:', error);
    };

    reader.readAsArrayBuffer(file); // Read file as ArrayBuffer
  }
  // open(content) {
  //   this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }
  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return  `with: ${reason}`;
  //   }
  // }
  SendRequest(){
    this.submitted = true;
    console.log('test msg',this.managerEmail);
    if (this.sendRequestForm.invalid) {
      return;
  }
  else if(this.isForm && (this.templateFile == undefined || this.templateFile ==''))
  {
    this.sharedService.showMessageBox("Template File is required!!");
  }
  else if(this.isWrongFile)
  {
    return;
  }
  else if(!this.isForm && this.sendRequestForm.value.longDescription.trim() =='')
    {
      this.sharedService.showMessageBox("You have not entered your request.!!");
    }
  else if ( this.showApprovers  && 
    (this.managerEmail == undefined || this.managerEmail == null || this.managerEmail == '')){
   this.sharedService.showMessageBox("Please select approver!!");
    }
  else{
    this.SpinnerService.show();
    // this.reqModel.tor= window.history.state.formData.requestSubType.substring(0,2);
    // this.reqModel.subtor= window.history.state.formData.requestSubType.substring(2,4);

    // //to do..
    
  /* this.reqModel.requestType=window.history.state.formData.requestType; 
     this.reqModel.requestSubType=window.history.state.formData.requestSubType; 
     this.reqModel.shortDesc=window.history.state.formData.shortDesc; 
     this.reqModel.requestedDeliveryDate=window.history.state.formData.requestedDeliveryDate; 
    */
    // this.reqModel.isPartOfProject=window.history.state.formData.isPartOfProject;
    // this.reqModel.projectNameRequest=window.history.state.formData.projectNameRequest;
    // this.reqModel.projectID=window.history.state.formData.projectID;
    // this.reqModel.ericssonProject=window.history.state.formData.ericssonProject;
    try{    
    this.reqModel.addInfo=this.sendRequestForm.value.addInfo;
    //this.reqModel.templateFile=this.templateFile;//this.fileBytes;
    if(this.DoAttach)
    {
      this.templateFile = this.attachFile ;
    }

    if(this.templateFile != undefined && this.templateFile != ''){
    this.reqModel.templateFileType=this.templateFile.name.split('.').pop();//this.templateFile.type;    
    this.reqModel.templateFileName=this.templateFile.name;
    }
    else{
      this.reqModel.templateFileType = "";
      this.reqModel.templateFileName = "";
    }
   // this.reqModel.=this.templateFile.;
    this.reqModel.task=1;

    this.reqModel.requestCreatedOn=new Date();
    //to do..
    //this.reqModel.requestCreatedBy='komal.gawande@ericsson.com';
    this.reqModel.requestCreatedBy=this.userModel.userID;
    // this.reqModel.requestTTL=window.history.state.formData.requestTTL;
    // this.reqModel.hasApprovers=window.history.state.formData.hasApprovers; 
    if ( this.reqModel.requestAcceptance)
    {
      this.reqModel.status=StatusList.Requesting;
    }
    else
    {
      this.reqModel.status = StatusList.Assigned
    }
     this.reqModel.userName = this.userModel.email;
     this.reqModel.devmode = this.devMode;//this.userModel.devMode;
     console.log('devmd check', this.reqModel.devmode);
     this.reqModel.fullUserName = this.userModel.fullName;
     this.reqModel.longDescription = this.sendRequestForm.value.longDescription;
    // if(!this.authorized){
    if(this.showApprovers){
     this.reqModel.myManager = this.managerEmail;
     }
        //to do.. vembu
     this.reqModel.requestAcceptedBy = null;
  //    this.reqModel.requestNumber=''
  //    var _formData = {
  //     status:  this.reqModel.status,      
  // };  
    
    this.formData.append('formData', JSON.stringify(this.reqModel));
    this.formData.append('userData', JSON.stringify(this.userModel));
    this.formData.append('templatefile',this.templateFile);    
    //this.sendRequestViewSvc.AddRequest(this.reqModel,this.formData).subscribe(data=>{
    this.sendRequestViewSvc.AddRequest(this.templateFile,this.formData).subscribe(data=>{
      if(data != undefined && data.requestNumber!="" && data.requestNumber.length > 0){
        if(!data.isEmailSent){
          this.sharedService.showMessageBox("SMTP sending error to departmetsFailure sending mail.");
        }
        var str = "<html>Your request has been stored as request number <b style='font-size:17px'>"+ data.requestNumber +"</b> and will be evaluated. You will receive notifications upon next steps.</html>";
        this.sharedService.showMessageBoxHeader("Request created",str);       
        //this.sharedService.showMessageBox(str);
        this.router.navigate(['/createNewRequest']);
        //alert('Request created successfully');
      }else{
        //yet to change the msg
        alert('Something went wrong pls check with administrator');
      }        
      this.SpinnerService.hide(); 
    });
  }
  catch (error)
  {
    this.SpinnerService.hide();
  }
  }
}

showTooltip(event: MouseEvent) {
  const tooltip = this.viewContainerRef.createEmbeddedView(this.tooltipTemplate);
  const tooltipElement = tooltip.rootNodes[0];
  tooltipElement.style.position = 'absolute';
  tooltipElement.style.left = `${event.pageX}px`;
  tooltipElement.style.top = `${event.pageY}px`;
}

hideTooltip() {
  this.viewContainerRef.clear();
}

countVisiblePanels = function() {
  return this.panelCount++; //$scope.panels.filter(panel => panel.visible).length;
};

getFileExtension(filename: string): string {
  const parts = filename.split('.');
  return parts.length > 1 ? parts[parts.length - 1] : ''; // Returns extension or empty string if no extension
}

}

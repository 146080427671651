import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import {UntypedFormGroup, FormControl,UntypedFormBuilder, Validators } from '@angular/forms';
import {SetApproverService} from './set-approver.service';
@Component({
  selector: 'app-set-approver',
  templateUrl: './set-approver.component.html',
  styleUrls: ['./set-approver.component.css']
})
export class SetApproverComponent implements OnInit {
  @Output() manager = new EventEmitter<string>();
  @Output() managerEmail = new EventEmitter<string>();
  //@Output('manager') manager:string;
  //@Input('managerEmail') managerEmail:string;
  @Input('devMode') devMode:Boolean;
  @Input('userName') userName:string;  
  @Input('approverListOnly') approverListOnly:Boolean;
  //@Input('company') company:string;
  company:string='';
  managersList:any[];
  submitted:any;
  validated:any;
  showApprover:boolean=true;
  setApproverForm: UntypedFormGroup;
  /*radioEricChecked:boolean = false;
  radiocustChecked:boolean = false;*/
  radiocount:any = -1;
  radioapprover:string = 'ERICSSON';
  constructor(private formBuilder: UntypedFormBuilder,private setApproverSvc:SetApproverService) { }

  ngOnInit() {
    this.company=this.userName.substring(this.userName.indexOf('@')+1,this.userName.indexOf('.com'))
    this.radiocount = this.company.toLowerCase().indexOf('ericsson');

    if(this.radiocount != -1){ this.radioapprover = 'ERICSSON'} else { this.radioapprover = 'CUSTOMERS' }

    this.setApproverForm=this.formBuilder.group({      
      rbApprover:  new FormControl(this.radioapprover),
      ddlApprover:  [null]       
      });    
      
      if(this.approverListOnly==false){
        this.showApprover=true;
       // if(this.radiocount != -1){          
          this.setApproverForm.value.rbApprover=this.radioapprover;         
          this.GetManagers(this.radioapprover);
          /*this.radioapprover = 'ERICSSON';
          this.radiocustChecked = false;
          this.radioEricChecked = true;*/
       // }else{
       //   this.setApproverForm.value.rbApprover='CUSTOMERS';
        //  this.GetManagers('CUSTOMERS');
          /*this.radioapprover = 'CUSTOMERS';
          this.radiocustChecked = true;
          this.radioEricChecked = false;*/
       // }
      }else{
        this.showApprover=false;
        this.GetManagers('vodafone');
      }
  }
  GetManagers(approverValue){
    this.setApproverSvc.GetManagers(approverValue).subscribe(data=>{
      this.managersList=data.table;   
      if(this.devMode==true){
        this.managersList.push([{userName:this.userName}]);
      }
      
    });
  }
  GetSelectedManager(event){
    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;   
    this.manager.emit(selectedOptions[selectedIndex].text);
    this.managerEmail.emit(selectedOptions[selectedIndex].value);
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
 
@Injectable({
  providedIn: 'root'
})
export class GlobalParamsService {
 
  _requestNumber=new BehaviorSubject<string>('');  
  requestNumber=this._requestNumber.asObservable();

  //developmentMode: BehaviorSubject<String>;
  private _developmentEnv=new BehaviorSubject<string>('test');  
  developmentEnv=this._developmentEnv.asObservable();

  _statusMessage=new BehaviorSubject<string>('');  
  statusMessage=this._statusMessage.asObservable();

  _version=new BehaviorSubject<string>('');  
  version=this._version.asObservable();

  _isEricssonLogin=new BehaviorSubject<boolean>(false);  
  isEricssonLogin=this._isEricssonLogin.asObservable();

  //devMode=new BehaviorSubject(false);
  selectedRequestCategory=new BehaviorSubject<any>("");
  selectedRequestType=new BehaviorSubject<any>("");
  selectedRequestListType=new BehaviorSubject<any>(0);

  searchKeyword=new BehaviorSubject<String>("");
  selectedRequestLabel=new BehaviorSubject<any>("");
  selectedSearchCategory=new BehaviorSubject<any>("");
  selectedSearchField=new BehaviorSubject<any>("");
  selectedSearchText=new BehaviorSubject<any>("");  
  selectedRequestSupplier=new BehaviorSubject<any>("");

  /* For request info page*/
     requestParentType=new BehaviorSubject<any>("");  
     requestParentTypeDesc=new BehaviorSubject<any>("");  
     requestSubType=new BehaviorSubject<any>(""); 
     requestSubTypeDesc=new BehaviorSubject<any>("");   
     isParentRequest=new BehaviorSubject<boolean>(false);
  /* --- End of request info */

  constructor() {
    if(document.baseURI.indexOf("localhost")!=-1) {
        this._developmentEnv = new BehaviorSubject<string>("localhost");
      }
      else if(document.baseURI.indexOf("isrtest")!=-1) {
        this._developmentEnv = new BehaviorSubject<string>("test");
      }
      else if(document.baseURI.indexOf("isr")!=-1) {
        this._developmentEnv = new BehaviorSubject<string>("prod");
    }
    this.developmentEnv=this._developmentEnv.asObservable();

    /*if(window.location.href.indexOf('?')!=-1) {
    var queryString= window.location.href.split("?")[1];
    this._version=new BehaviorSubject<string>(
      queryString.split("&")[1].split("=")[1]);
     // this._version=new BehaviorSubject<string>("1.1.1");
    this.version=this._version.asObservable();
    }else{      
      this.version=new BehaviorSubject<string>("");
    }*/    
    
  }
 
}
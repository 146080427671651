import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import { SharedService } from '../../Shared/shared.service'

@Component({
  selector: 'app-continue-unfinished-request',
  templateUrl: './continue-unfinished-request.component.html',
  styleUrls: ['./continue-unfinished-request.component.css']
})
export class ContinueUnfinishedRequestComponent implements OnInit {
  public user: any;
  constructor(
    private dialogRef: MatDialogRef<ContinueUnfinishedRequestComponent>,
    private cookieSvc: CookieService, private location: Location,
    private sharedSvc: SharedService) { }

    typeOfReq: any = '';

  ngOnInit(): void {
    this.typeOfReq = this.dialogRef._containerInstance._config.data.typeOfRequest; /** ISR-1962 */
  }
  Continue() {
    this.cookieSvc.set('V2CreateRequest', 'ContinueRequest', { path: '/' });
    window.location.href = this.sharedSvc.ISR_URL + "Default.aspx";
  }
  New() {
    this.cookieSvc.set('V2CreateRequest', 'NewRequest', { path: '/' });
    window.location.href = this.sharedSvc.ISR_URL + "Default.aspx";
  }
  close() {
    this.dialogRef.close();
  }
}

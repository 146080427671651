import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { RequestOverviewService } from '../request-overview/request-overview.service';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { GridRequestFileComponent } from '../grid-request-file/grid-request-file.component';
import { AgGridAngular } from '@ag-grid-community/angular';
import { Router } from '@angular/router';
import { GlobalParamsService } from '../global/globalParams.service';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { SharedService } from '../Shared/shared.service';
import { MenuService } from '../global/menu.service';
import { userModel } from '../model/user.model'; //ISR-2065 --Fix
@Component({
  selector: 'app-request-overview',
  templateUrl: './request-overview.component.html',
  styleUrls: ['./request-overview.component.css']
})
export class RequestOverviewComponent implements OnInit {
  public gridOptions: AgGridAngular
  modules = AllCommunityModules;
  columnDefs: any = [];
  requestOverviewData: any = [];
  temprequestOverviewData: any = []; //ISR-2065 --Fix
  requestOverviewForm: UntypedFormGroup;
  listRequestCategory: any;
  listRequestSupplier: any;
  listRequestType: any = [];
  requestLabel = "R";
  private gridApi;
  private gridColumnApi;
  btUpdateSelectionStatus = true;
  updateSelectionTitle = 'Please make selection first.';
  statusMessage: string = '';
  prevRequestCategory: string = '';
  prevRequestSupplier: string = '';
  prevRequestType: string = '';
  prevRequestListType: string = '';
  showTypeDiv: Boolean = true;
  searchKeyword: String = '';
  clearTitle = 'Reset the request overview selection fields.';

  isAdvanceSearch = 0;
  searchCategoryList: any = [];
  searchCategory = "";
  datePipe = new DatePipe("en-US");

  searchFormData: any;
  searchIn: any = [];
  searchField = "";
  searchTxt = "";

  prevRequestLabel: string = '';
  prevSearchCategory: string = '';
  prevSearchField: string = '';
  prevSearchText: string = '';

  searchLoadingStatus = false; //ISR-2065 --Fix
  isChecked = true; //ISR-2065
  requestNoSearch: any = ''; //ISR-2065
  myRequest: boolean; //ISR-2065
  myRequestValue: boolean; //ISR-2065


  constructor(private requestOverviewSvc: RequestOverviewService, private router: Router,
    private globalParamsService: GlobalParamsService, private cookieService: CookieService,
    private sharedSVC: SharedService, private sharedService: SharedService, private menuService: MenuService, private objUserModel: userModel) {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
  }

  ngOnInit() {
    //ISR-2065 --Fix
    this.objUserModel.email = this.cookieService.get('userEmail');
    if (window.localStorage.getItem('isAuthorized') == 'true' &&
      this.cookieService.get('ISTCookie2') != '' &&
      this.cookieService.get('ISTCookie2') != null) {
      this.sharedService.GetUserByEmail(this.objUserModel).subscribe(data => {
        this.objUserModel = data.table[0];
        console.log(this.objUserModel);
      });
    }
    this.searchLoadingStatus = false;
    //ISR-2065 --Fix

    // var searchElement = document.getElementById('qSearchInfo');
    // searchElement.style.display = "block";

    this.globalParamsService._requestNumber.next('');

    //@ViewChild('agGrid') agGrid: AgGridAngular;
    this.requestOverviewForm = new UntypedFormGroup({
      requestCategoryControl: new UntypedFormControl(),
      requestType: new UntypedFormControl(),
      requestListType: new UntypedFormControl(""),
      requestLabelControl: new UntypedFormControl('R'),
      searchCategoryControl: new UntypedFormControl(),
      searchFieldControl: new UntypedFormControl(),
      searchTxtControl: new UntypedFormControl(),
      requestsupplier: new UntypedFormControl(),
      requestNoSearch: new UntypedFormControl(), //ISR-2065
      myRequest: new UntypedFormControl(this.myRequest), //ISR-2065
    });
    this.requestLabel = "R";

    this.searchCategoryList.push({ value: 'Changes', text: 'Changes' });
    //this.searchCategoryList.push({ value: 'Request', text: 'Request' });

    this.searchCategory = this.searchCategoryList[0].value;

    //this.requestOverviewForm.get('requestListType').setValue(1);
    this.globalParamsService.selectedRequestCategory.subscribe(val => this.prevRequestCategory = val);
    this.globalParamsService.selectedRequestType.subscribe(val => this.prevRequestType = val);
    this.globalParamsService.selectedRequestListType.subscribe(val => this.prevRequestListType = val);
    this.globalParamsService.selectedRequestSupplier.subscribe(val => this.prevRequestSupplier = val);
    /*if(this.prevRequestSupplier!=''){
      this.requestOverviewForm.get('requestsupplier').setValue(this.prevRequestSupplier);
    }*/
    if (this.prevRequestListType != '') {
      this.requestOverviewForm.get('requestListType').setValue(this.prevRequestListType);
    }

    this.globalParamsService.selectedRequestLabel.subscribe(val => this.prevRequestLabel = val);
    if (this.prevRequestLabel != '') {
      this.requestOverviewForm.get('requestLabelControl').setValue(this.prevRequestLabel);
      this.requestLabel = this.prevRequestLabel;

      //this.GetReqCategory(this.requestOverviewForm);
    }

    this.globalParamsService.selectedSearchCategory.subscribe(val => this.prevSearchCategory = val);
    this.globalParamsService.selectedSearchField.subscribe(val => this.prevSearchField = val);
    if (this.prevSearchField != '') {
      this.requestOverviewForm.get('searchFieldControl').setValue(this.prevSearchField);
    }
    this.globalParamsService.selectedSearchText.subscribe(val => this.prevSearchText = val);

    if (this.requestLabel == "A") {
      //this.requestOverviewForm.get('requestListType').setValue(0);
      this.isAdvanceSearch = 1;
    }
    else if (this.requestLabel == "S") {
      this.isAdvanceSearch = 2;
    }
    else {
      this.isAdvanceSearch = 0;
    }

    this.bindRequestCategory();
    this.bindAdvanceSearch();
    this.bindSupplier();
    this.initGrid();
    this.globalParamsService.searchKeyword.subscribe(val => this.searchKeyword = val);
    this.requestOverviewForm.get('requestNoSearch').setValue(this.searchKeyword); //ISR-2065 --Fix
    this.requestNoSearch = this.searchKeyword; //ISR-2065 --Fix
    if (this.searchKeyword != '') {
      this.searchLoadingStatus = true; //ISR-2065 --Fix
      var _search = {
        keyword: this.searchKeyword
      };
      this.requestOverviewSvc.GetQuickSearchRequestOverviewResult(_search).subscribe(data => {
        this.requestOverviewData = data.table;
        this.globalParamsService.searchKeyword.next('');
        this.searchKeyword = '';
        this.searchLoadingStatus = false; //ISR-2065 --Fix
        if (this.requestOverviewData.length > 0) {
          this.globalParamsService._statusMessage.next("Double click on a row to open details.");
          this.globalParamsService.statusMessage.subscribe(stat => this.statusMessage = stat);
          if (this.requestOverviewData.length == 1) {
            this.router.navigate(['/request/' + this.requestOverviewData[0].requestNumber]);
          }
        } else {
          this.globalParamsService._statusMessage.next("");
          this.globalParamsService.statusMessage.subscribe(stat => this.statusMessage = stat);
        }
      });
    }
  }

  onGridReady(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    try {
      // (param.api as any).context.beanWrappers.tooltipManager.beanInstance.MOUSEOVER_SHOW_TOOLTIP_TIMEOUT = 0;
    } catch (e) {
      console.error(e);
    }
  }
  onRowDoubleClick(row) {
    //console.log(row.data);
    //return;
    this.globalParamsService._statusMessage.next("");
    this.globalParamsService.statusMessage.subscribe(stat => this.statusMessage = stat);

    this.globalParamsService.selectedRequestCategory.next(this.requestOverviewForm.value.requestCategoryControl);
    this.globalParamsService.selectedRequestType.next(this.requestOverviewForm.value.requestType);
    this.globalParamsService.selectedRequestListType.next(this.requestOverviewForm.value.requestListType);

    this.globalParamsService.selectedRequestLabel.next(this.requestOverviewForm.value.requestLabelControl);
    this.globalParamsService.selectedSearchCategory.next(this.requestOverviewForm.value.searchCategoryControl);
    this.globalParamsService.selectedSearchField.next(this.requestOverviewForm.value.searchFieldControl);
    this.globalParamsService.selectedSearchText.next(this.requestOverviewForm.value.searchTxtControl);
    this.globalParamsService.selectedRequestSupplier.next(this.requestOverviewForm.value.requestsupplier);

    this.cookieService.set('ReqNoDisp', row.data.requestNumber);
    if (this.requestOverviewForm.value.requestCategoryControl == "9801" || this.requestOverviewForm.value.searchCategoryControl == "Changes") {
      this.cookieService.set('CLReq', 'yes');
    }
    else {
      this.cookieService.set('CLReq', 'no');
    }
    if ((this.requestOverviewForm.value.requestLabelControl == "A" && this.requestOverviewForm.value.searchCategoryControl == "Changes") ||
      this.requestOverviewForm.value.requestLabelControl == "S") {
      this.router.navigate(['/request/' + row.data.requestNo]);
    }
    else {
      this.router.navigate(['/request/' + row.data.requestNumber]);
    }

  }

  bindRequestCategory() {
    this.requestOverviewSvc.GetRequestOverviewCategory().subscribe(data => {
      this.listRequestCategory = data.table;

      if (this.prevRequestCategory != '' && this.prevRequestCategory != null) {
        this.requestOverviewForm.get('requestCategoryControl').setValue(this.prevRequestCategory);
        this.GetRequestOverviewRequestType(this.prevRequestCategory, this.requestOverviewForm);
      }
    });

  }

  bindSupplier() {
    this.requestOverviewSvc.GetRequestOverviewSupplier().subscribe(data => {
      this.listRequestSupplier = data.table;

      if (this.prevRequestSupplier != '' && this.prevRequestSupplier != null) {
        this.requestOverviewForm.get('requestsupplier').setValue(this.prevRequestSupplier);
        this.updateSelection(this.requestOverviewForm);
      }

      /*if(this.prevRequestCategory!='' && this.prevRequestCategory != null){
        this.requestOverviewForm.get('requestCategory').setValue(this.prevRequestCategory);
        this.GetRequestOverviewRequestType(this.prevRequestCategory,this.requestOverviewForm);
      }*/
    });

  }

  bindAdvanceSearch() {
    this.requestOverviewSvc.loadSearchForm().subscribe(data => {
      this.searchFormData = data;
      this.searchIn = this.searchFormData.changeReqSearch;
      this.searchField = this.searchFormData.changeReqSearch[0].name;

      if (this.requestLabel == "A") {
        if (this.prevSearchCategory != '') {
          this.requestOverviewForm.get('searchCategoryControl').setValue(this.prevSearchCategory);
          this.searchCategory = this.prevSearchCategory;
          this.GetSearchFields(this.requestOverviewForm);

          this.isAdvanceSearch = 1;
          this.btUpdateSelectionStatus = false;
          this.updateSelectionTitle = '';

          if (this.prevSearchField != '') {
            this.searchField = this.prevSearchField;
            this.requestOverviewForm.get('searchFieldControl').setValue(this.prevSearchField);
          }
          if (this.prevSearchText != '') {
            this.searchTxt = this.prevSearchText;
            this.requestOverviewForm.get('searchTxtControl').setValue(this.prevSearchText);
          }

          this.updateSelection(this.requestOverviewForm);

        }
      }

    });

  }

  //ISR-2065 --Fix
  slideCheck(requestOverviewForm) {
    if (requestOverviewForm.value.requestCategoryControl != "null" && requestOverviewForm.value.requestType != "null") {
      console.log(requestOverviewForm.get('myRequest').value);
      this.myRequestValue = requestOverviewForm.get('myRequest').value;
      this.btUpdateSelectionStatus = false;
    }
  }
  //ISR-2065 --Fix

  GetReqCategory(requestOverviewForm) {
    console.log(requestOverviewForm.value);
    this.initGrid();
    this.listRequestType = [];
    requestOverviewForm.get('requestType').reset();
    requestOverviewForm.get('requestCategoryControl').reset();
    requestOverviewForm.get('searchCategoryControl').reset();
    requestOverviewForm.get('searchFieldControl').reset();
    requestOverviewForm.get('searchTxtControl').reset();
    requestOverviewForm.get('requestsupplier').reset();
    this.requestOverviewData = [];
    if (requestOverviewForm.value.requestLabelControl == "A") {
      //this.requestOverviewForm.get('requestListType').setValue(0);
      this.isAdvanceSearch = 1;
      this.btUpdateSelectionStatus = false;
      this.updateSelectionTitle = '';
      //this.initGrid();
    }
    else if (requestOverviewForm.value.requestLabelControl == "S") {
      //this.requestOverviewForm.get('requestListType').setValue(0);
      this.isAdvanceSearch = 2;
      this.btUpdateSelectionStatus = true;
      this.updateSelectionTitle = 'Please make selection first.';
      this.prevRequestSupplier = '';
      //this.initGrid();
    }
    else {
      this.isAdvanceSearch = 0;
      this.btUpdateSelectionStatus = true;
      this.updateSelectionTitle = 'Please make selection first.';
      this.prevRequestCategory = '';
    }
  }

  GetSearchFields(requestOverviewForm) {
    console.log(this.requestOverviewForm.value);
    if (this.requestOverviewForm.value.searchCategoryControl == "Changes") {
      //this.requestOverviewForm.get('requestListType').setValue(0);
      this.searchIn = this.searchFormData.changeReqSearch;
      this.searchField = this.searchFormData.changeReqSearch[0].name;
      this.searchCategory == "Changes";
    }
    else if (this.requestOverviewForm.value.searchCategoryControl == "Request") {
      //this.requestOverviewForm.get('requestListType').setValue(0);
      this.searchIn = this.searchFormData.requestSearch;
      this.searchField = "Anywhere";
    }
    //this.initGrid();
  }

  GetRequestOverviewRequestType(parentId, requestOverviewForm) {
    this.initGrid();
    this.listRequestType = [];
    requestOverviewForm.get('requestType').reset();
    this.requestOverviewData = [];
    if (requestOverviewForm.value.requestCategoryControl != null && requestOverviewForm.value.requestCategoryControl != "null" && requestOverviewForm.value.requestType != null && requestOverviewForm.value.requestType != "null") {
      this.btUpdateSelectionStatus = false;
      this.updateSelectionTitle = '';
    } else {
      this.btUpdateSelectionStatus = true;
      this.updateSelectionTitle = 'Please make selection first.';
    }

    if (parentId != "0: null") {
      this.requestOverviewSvc.GetRequestOverviewRequestType(parentId).subscribe(data => {
        //console.log(data);
        //console.log(data.table);
        if (data.table.length == 0) {
          this.btUpdateSelectionStatus = false;
          this.updateSelectionTitle = '';
          this.showTypeDiv = false;
          this.requestOverviewForm.get('requestType').setValue(0);
          if (this.prevRequestCategory != '') {
            if (this.prevRequestCategory != '9801') {
              if (this.prevRequestType != null) {
                this.updateSelection(this.requestOverviewForm);
              }
            }
            else {
              this.updateSelection(this.requestOverviewForm);
            }
            //this.updateSelection(this.requestOverviewForm);
          }
        }
        else {
          this.listRequestType = data.table;
          this.showTypeDiv = true;
          if (this.prevRequestType != '') {
            this.requestOverviewForm.get('requestType').setValue(this.prevRequestType);
            this.btUpdateSelectionStatus = false;
            if (this.prevRequestCategory != '9801') {
              if (this.prevRequestType != null) {
                this.updateSelection(this.requestOverviewForm);
              }
            }
            else {
              this.updateSelection(this.requestOverviewForm);
            }
          }
        }
      });
    }
    else {
      this.listRequestType = [];
    }
    if (this.requestOverviewForm.value.requestCategoryControl == 9801) {
      this.requestOverviewForm.get('requestListType').setValue(0);
    }
  }

  GetRequestType(requestOverviewForm) {
    if (requestOverviewForm.value.requestCategoryControl != "null" && requestOverviewForm.value.requestType != "null") {
      //this.btUpdateSelectionStatus = false;
      this.updateSelectionTitle = '';
    } else {
      this.btUpdateSelectionStatus = true;
      this.updateSelectionTitle = 'Please make selection first.';
    }
  }
  
  //ISR-2065 --Fix
  onChangeRequestListType(requestOverviewForm) {
    if (requestOverviewForm.value.requestListType != null || requestOverviewForm.value.requestListType != '') {
      this.btUpdateSelectionStatus = false;
    } else {
      if (requestOverviewForm.value.requestCategoryControl == "null" && requestOverviewForm.value.requestType == "null") {
        this.btUpdateSelectionStatus = true;
      } else {
        this.btUpdateSelectionStatus = false;
      }
    }
  }
  //ISR-2065 --Fix

  initGrid() {
    this.gridOptions = <AgGridAngular>{
      context: {
        componentParent: this
      },
      defaultColDef: {
        sortable: true,
        filter: true
      },
    };


    if (this.requestOverviewForm.value.requestCategoryControl == 9801) {
      this.columnDefs = [
        { headerName: 'Request Number', field: 'requestNumber', width: 100 },
        { headerName: 'Requestor', field: 'requestCreatedBy', width: 200, resizable: true, tooltipField: 'email' },
        { headerName: 'Requested On', field: 'requestCreatedOn', width: 130 },
        { headerName: 'Closed On', field: 'closedOn', width: 130 },
        { headerName: 'Description', field: 'shortDesc', width: 300, resizable: true, tooltipField: 'shortDesc', autoHeight: true, cellStyle: { 'white-space': 'normal', 'line-height': 1.5 } },
        { headerName: 'Status', field: 'requestStatus', width: 180, tooltipField: 'requestStatus' }

      ];
    }
    else if (this.requestOverviewForm.value.requestLabelControl == "A" && (this.searchCategory == "Changes" || this.requestOverviewForm.value.searchCategoryControl == "Changes")) {
      this.columnDefs = [
        { headerName: 'Request Number', field: 'requestNo', width: 100 },
        { headerName: 'Requestor', field: 'requestor', width: 200, resizable: true, tooltipField: 'requestor' },
        { headerName: 'Requested On', field: 'requestCreatedOnDisp', width: 130 },
        { headerName: 'Closed On', field: 'closedOn', width: 130 },
        { headerName: 'Description', field: 'shortDescription', width: 300, resizable: true, tooltipField: 'shortDescription', autoHeight: true, cellStyle: { 'white-space': 'normal', 'line-height': 1.5 } },
        { headerName: 'Status', field: 'overallStatusDesc', width: 180, tooltipField: 'overallStatusDesc' }

      ];
    }
    else if (this.requestOverviewForm.value.requestLabelControl == "S") {
      this.columnDefs = [
        { headerName: 'Request Number', field: 'requestNo', width: 100 },
        { headerName: 'Requestor', field: 'requestor', width: 200, resizable: true, tooltipField: 'requestor' },
        { headerName: 'Requested On', field: 'requestCreatedOn', width: 130 },
        { headerName: 'Description', field: 'description', width: 300, resizable: true, tooltipField: 'description', autoHeight: true, cellStyle: { 'white-space': 'normal', 'line-height': 1.5 } },
        { headerName: 'Supplier Status', field: 'supplierStatus', width: 130, tooltipField: 'supplierStatus' },
        { headerName: 'Supplier Remarks', field: 'supplierRemarks', tooltipField: 'supplierRemarks' }
      ];
    }
    else {
      this.columnDefs = [
        { headerName: 'Request Number', field: 'requestNumber', width: 100 },
        { headerName: 'CT Number', field: 'cTnumber', width: 90 },
        { headerName: 'Requestor', field: 'requestCreatedBy', width: 200, resizable: true, tooltipField: 'email' },
        { headerName: 'Requested On', field: 'requestCreatedOn', width: 130 },
        { headerName: 'Closed On', field: 'closedOn', width: 130 },
        { headerName: 'Description', field: 'shortDesc', width: 300, resizable: true, tooltipField: 'shortDesc', autoHeight: true, cellStyle: { 'white-space': 'normal', 'line-height': 1.5 } },
        { headerName: 'Offer Approved', field: 'offerApproved', width: 140 },
        { headerName: 'Offer Response On', field: 'offerApprovedOn', resizable: true, width: 120 },
        { headerName: 'Design Engineer', field: 'designEngineer', resizable: true, width: 95, tooltipField: 'designEngineer', autoHeight: true, cellStyle: { 'white-space': 'pre', 'line-height': 1.5 } },
        { headerName: 'Build Engineer', field: 'buildEngineer', resizable: true, width: 95, tooltipField: 'buildEngineer', autoHeight: true, cellStyle: { 'white-space': 'pre', 'line-height': 1.5 } },
        { headerName: 'Status', field: 'requestStatus', width: 180, tooltipField: 'requestStatus' },
        { headerName: 'Customer Progress', field: 'customerProgress', width: 300, resizable: true, tooltipField: 'customerProgress', autoHeight: true, cellStyle: { 'white-space': 'pre', 'line-height': 1.5 } },
        // {headerName: 'Request file', cellRendererFramework:GridRequestFileComponent, width:100, tooltipValueGetter:()=>{ return 'Request file'}}
        { headerName: 'Request file', cellRendererFramework: GridRequestFileComponent, width: 100, tooltipField: 'fileToolTip' }
      ];
    }

  }

  gotoISR() {
    if (document.baseURI.indexOf("localhost") != -1)
      // window.location.href = "http://localhost:51682/Default.aspx?req=su";
      window.location.href = "http://localhost:4200/#/isr";
    else if (document.baseURI.indexOf("isrtest") != -1)
      window.location.href = "https://isrtest.enn.ericsson.net?req=su";
    else if (document.baseURI.indexOf("isr") != -1)
      window.location.href = "https://isr.enn.ericsson.net?req=su";
  }

  updateSelection(requestOverviewForm) {

    this.searchLoadingStatus = true; //ISR-2065 --Fix
    //this.gridColumnApi.columnController.columnDefs[1].hide=false;
    this.gridColumnApi.columnModel.columnDefs[1].hide = false;
    if (requestOverviewForm.value.requestLabelControl == "A") {
      var searchRequest = {
        category: requestOverviewForm.value.searchCategoryControl,
        searchIn: requestOverviewForm.value.searchFieldControl,
        searchTxt: requestOverviewForm.value.searchTxtControl,
        userId: ''
      }
      this.requestOverviewSvc.findSearchData(searchRequest).subscribe(data => {
        console.log(data);
        this.searchLoadingStatus = false; //ISR-2065 --Fix
        this.requestOverviewData = data.lstSearchData;
        //ISR-2065 --Fix
        let requestOverViewData = [];
        this.temprequestOverviewData = this.requestOverviewData;
        if (this.myRequestValue === true) {
          requestOverViewData = this.requestOverviewData.filter(t => t.requestor == this.objUserModel.fullName);
          this.requestOverviewData = requestOverViewData;
        } else if (requestOverviewForm.get('requestNoSearch').value != '' || this.requestNoSearch != '') {
          requestOverViewData = [];
          this.temprequestOverviewData = this.requestOverviewData;
          requestOverViewData = this.requestOverviewData.filter(t => t.shortDescription.includes(requestOverviewForm.get('requestNoSearch').value) ||
            t.requestor.includes(requestOverviewForm.get('requestNoSearch').value));
          this.requestOverviewData = requestOverViewData;
        }
        else {
          this.requestOverviewData = this.temprequestOverviewData;
        }
        //ISR-2065 --Fix
        if (this.requestOverviewData.length > 0) {
          this.globalParamsService._statusMessage.next("Double click on a row to open details.");
          this.globalParamsService.statusMessage.subscribe(stat => this.statusMessage = stat);
        } else {
          this.globalParamsService._statusMessage.next("");
          this.globalParamsService.statusMessage.subscribe(stat => this.statusMessage = stat);
        }

      });
    }
    else if (requestOverviewForm.value.requestLabelControl == "S") {
      this.requestOverviewSvc.GetISRRequestOverviewSupplierData(requestOverviewForm.value.requestsupplier).subscribe(data => {
        console.log(data);
        this.searchLoadingStatus = false; //ISR-2065 --Fix
        this.requestOverviewData = data.table;
        //ISR-2065 --Fix
        let requestOverViewData = [];
        this.temprequestOverviewData = this.requestOverviewData;
        if (this.myRequestValue === true) {
          requestOverViewData = this.requestOverviewData.filter(t => t.requestor == this.objUserModel.fullName);
          this.requestOverviewData = requestOverViewData;
        } else if (requestOverviewForm.get('requestNoSearch').value != '' || this.requestNoSearch != '') {
          requestOverViewData = [];
          this.temprequestOverviewData = this.requestOverviewData;
          requestOverViewData = this.requestOverviewData.filter(t => t.description.includes(requestOverviewForm.get('requestNoSearch').value)
            || t.requestor.includes(requestOverviewForm.get('requestNoSearch').value)
            || t.supplierRemarks.includes(requestOverviewForm.get('requestNoSearch').value));
          this.requestOverviewData = requestOverViewData;
        }
        else {
          this.requestOverviewData = this.temprequestOverviewData;
        }
        //ISR-2065 --Fix
        if (this.requestOverviewData.length > 0) {
          this.globalParamsService._statusMessage.next("Double click on a row to open details.");
          this.globalParamsService.statusMessage.subscribe(stat => this.statusMessage = stat);
        } else {
          this.globalParamsService._statusMessage.next("");
          this.globalParamsService.statusMessage.subscribe(stat => this.statusMessage = stat);
        }
      });
    }
    else {

      // if(requestOverviewForm.value.requestCategory==9){
      //   this.gridApi.columnController.setColumnVisible('cTnumber',true);
      //   this.gridApi.columnController.setColumnVisible('offerApproved',true);
      //   this.gridApi.columnController.setColumnVisible('offerApprovedOn',true);
      //   this.gridApi.columnController.setColumnVisible('designEngineer',true);
      //   this.gridApi.columnController.setColumnVisible('buildEngineer',true);
      //   this.gridApi.columnController.setColumnVisible('customerProgress',true);
      //   this.gridApi.columnController.setColumnVisible('requestCreatedOn',false);
      // }else{
      //   this.gridApi.columnController.setColumnVisible('cTnumber',false);
      //   this.gridApi.columnController.setColumnVisible('offerApproved',false);
      //   this.gridApi.columnController.setColumnVisible('offerApprovedOn',false);
      //   this.gridApi.columnController.setColumnVisible('designEngineer',false);
      //   this.gridApi.columnController.setColumnVisible('buildEngineer',false);
      //   this.gridApi.columnController.setColumnVisible('customerProgress',false);
      //   this.gridApi.columnController.setColumnVisible('requestCreatedOn',true);
      //   if(requestOverviewForm.value.requestCategory==9801) {
      //     //this.gridApi.columnController.setColumnVisible('RequestFile',false);
      //   }
      // }
      if ((requestOverviewForm.value.requestCategoryControl === null || requestOverviewForm.value.requestCategoryControl == - '')
        && (requestOverviewForm.value.requestType === null || requestOverviewForm.value.requestType === '')
        && (requestOverviewForm.value.requestListType === null || requestOverviewForm.value.requestListType === '')) {
        console.log('OTHERWISE..........');
        //ISR-2065 --Fix
        let requestOverViewData = [];
        if (this.myRequestValue === true) {
          this.temprequestOverviewData = this.requestOverviewData;
          requestOverViewData = this.requestOverviewData.filter(t => t.requestCreatedBy == this.objUserModel.fullName);
          this.requestOverviewData = requestOverViewData;
        } else {
          this.requestOverviewData = this.temprequestOverviewData;
        }
        this.searchLoadingStatus = false;
        //ISR-2065 --Fix
      } else {
        this.requestOverviewSvc.GetISRRequestOverviewData(requestOverviewForm.value.requestCategoryControl, requestOverviewForm.value.requestType, requestOverviewForm.value.requestListType).subscribe(data => {
          if (data.table.length > 0) {
            this.searchLoadingStatus = false; //ISR-2065 --Fix
            if (requestOverviewForm.value.requestCategoryControl == 9) {
              //console.log(data.table);
              //console.log(data.table[0].useServiceData);
              //console.log(data.table[0].doOffer);
              var isOffer, isUsr;
              if (data.table[0].doOffer == true) {
                //console.log('yes');
                //this.gridApi.columnController.setColumnVisible('offerApproved',true);
                //this.gridApi.columnController.setColumnVisible('offerApprovedOn',true);
                isOffer = 1;
              } else {
                //console.log('no');
                //this.gridApi.columnController.setColumnVisible('offerApproved',false);
                //this.gridApi.columnController.setColumnVisible('offerApprovedOn',false);
                isOffer = 0;
              }
              if (data.table[0].useServiceData == true) {
                //console.log('info yes');
                //this.gridApi.columnController.setColumnVisible('designEngineer',true);
                //this.gridApi.columnController.setColumnVisible('buildEngineer',true);
                //this.gridApi.columnController.setColumnVisible('customerProgress',true);
                isUsr = 1;
              } else {
                //console.log('info no');
                //this.gridApi.columnController.setColumnVisible('designEngineer',false);
                //this.gridApi.columnController.setColumnVisible('buildEngineer',false);
                //this.gridApi.columnController.setColumnVisible('customerProgress',false);
                isUsr = 0;
              }

              if (isOffer == 1 && isUsr == 1) {
                var colDefboth = [
                  { headerName: 'Request Number', field: 'requestNumber', width: 100 },
                  { headerName: 'CT Number', field: 'cTnumber', width: 90 },
                  { headerName: 'Closed On', field: 'closedOn', width: 130 },
                  { headerName: 'Requestor', field: 'requestCreatedBy', width: 200, resizable: true, tooltipField: 'email' },
                  { headerName: 'Description', field: 'shortDesc', width: 300, resizable: true, tooltipField: 'shortDesc', autoHeight: true, cellStyle: { 'white-space': 'normal', 'line-height': 1.5 } },
                  { headerName: 'Offer Approved', field: 'offerApproved', width: 140 },
                  { headerName: 'Offer Response On', field: 'offerApprovedOn', resizable: true, width: 120 },
                  { headerName: 'Design Engineer', field: 'designEngineer', resizable: true, width: 95, tooltipField: 'designEngineer', autoHeight: true, cellStyle: { 'white-space': 'pre', 'line-height': 1.5 } },
                  { headerName: 'Build Engineer', field: 'buildEngineer', resizable: true, width: 95, tooltipField: 'buildEngineer', autoHeight: true, cellStyle: { 'white-space': 'pre', 'line-height': 1.5 } },
                  //Vembu - ISR-2103 - RequestOverview: enhancement on status
                  { headerName: 'WorkflowActive', field: 'workflowActive', resizable: true, width: 100, tooltipField: 'workflowActive', autoHeight: true, cellStyle: { 'white-space': 'pre', 'line-height': 1.5 } },
                  { headerName: 'Status', field: 'requestStatus', width: 130, tooltipField: 'requestStatus' },
                  { headerName: 'Customer Progress', field: 'customerProgress', width: 300, resizable: true, tooltipField: 'customerProgress', autoHeight: true, cellStyle: { 'white-space': 'pre', 'line-height': 1.5 } }

                ];
                this.gridApi.setColumnDefs(colDefboth);
              }
              else if (isOffer == 1 && isUsr != 1) {
                var colDefOff = [
                  { headerName: 'Request Number', field: 'requestNumber', width: 100 },
                  { headerName: 'CT Number', field: 'cTnumber', width: 90 },
                  { headerName: 'Closed On', field: 'closedOn', width: 130 },
                  { headerName: 'Requestor', field: 'requestCreatedBy', width: 200, resizable: true, tooltipField: 'email' },
                  { headerName: 'Description', field: 'shortDesc', width: 300, resizable: true, tooltipField: 'shortDesc', autoHeight: true, cellStyle: { 'white-space': 'normal', 'line-height': 1.5 } },
                  { headerName: 'Offer Approved', field: 'offerApproved', width: 140 },
                  { headerName: 'Offer Response On', field: 'offerApprovedOn', resizable: true, width: 120 },
                  { headerName: 'WorkflowActive', field: 'workflowActive', resizable: true, width: 100, tooltipField: 'workflowActive', autoHeight: true, cellStyle: { 'white-space': 'pre', 'line-height': 1.5 } },
                  { headerName: 'Status', field: 'requestStatus', width: 130, tooltipField: 'requestStatus' }

                ];
                this.gridApi.setColumnDefs(colDefOff);
              }
              else if (isOffer != 1 && isUsr == 1) {
                var colDefUsr = [
                  { headerName: 'Request Number', field: 'requestNumber', width: 100 },
                  { headerName: 'CT Number', field: 'cTnumber', width: 90 },
                  { headerName: 'Closed On', field: 'closedOn', width: 130 },
                  { headerName: 'Requestor', field: 'requestCreatedBy', width: 200, resizable: true, tooltipField: 'email' },
                  { headerName: 'Description', field: 'shortDesc', width: 300, resizable: true, tooltipField: 'shortDesc', autoHeight: true, cellStyle: { 'white-space': 'normal', 'line-height': 1.5 } },
                  { headerName: 'Design Engineer', field: 'designEngineer', resizable: true, width: 95, tooltipField: 'designEngineer', autoHeight: true, cellStyle: { 'white-space': 'pre', 'line-height': 1.5 } },
                  { headerName: 'Build Engineer', field: 'buildEngineer', resizable: true, width: 95, tooltipField: 'buildEngineer', autoHeight: true, cellStyle: { 'white-space': 'pre', 'line-height': 1.5 } },
                  { headerName: 'WorkflowActive', field: 'workflowActive', resizable: true, width: 100, tooltipField: 'workflowActive', autoHeight: true, cellStyle: { 'white-space': 'pre', 'line-height': 1.5 } },
                  { headerName: 'Status', field: 'requestStatus', width: 130, tooltipField: 'requestStatus' },
                  { headerName: 'Customer Progress', field: 'customerProgress', width: 300, resizable: true, tooltipField: 'customerProgress', autoHeight: true, cellStyle: { 'white-space': 'pre', 'line-height': 1.5 } }

                ];
                this.gridApi.setColumnDefs(colDefUsr);
              }
              if (isOffer != 1 && isUsr != 1) {
                var colDefNo = [
                  { headerName: 'Request Number', field: 'requestNumber', width: 100 },
                  { headerName: 'CT Number', field: 'cTnumber', width: 90 },
                  { headerName: 'Closed On', field: 'closedOn', width: 130 },
                  { headerName: 'Requestor', field: 'requestCreatedBy', width: 200, resizable: true, tooltipField: 'email' },
                  { headerName: 'Description', field: 'shortDesc', width: 300, resizable: true, tooltipField: 'shortDesc', autoHeight: true, cellStyle: { 'white-space': 'normal', 'line-height': 1.5 } },
                  { headerName: 'WorkflowActive', field: 'workflowActive', resizable: true, width: 100, tooltipField: 'workflowActive', autoHeight: true, cellStyle: { 'white-space': 'pre', 'line-height': 1.5 } },
                  { headerName: 'Status', field: 'requestStatus', width: 130, tooltipField: 'requestStatus' }

                ];
                this.gridApi.setColumnDefs(colDefNo);
              }
              //this.gridApi.columnController.setColumnVisible('requestCreatedOn',false);
            } else {
              /*
              this.gridApi.columnController.setColumnVisible('cTnumber',false);
              this.gridApi.columnController.setColumnVisible('offerApproved',false);
              this.gridApi.columnController.setColumnVisible('offerApprovedOn',false);
              this.gridApi.columnController.setColumnVisible('designEngineer',false);
              this.gridApi.columnController.setColumnVisible('buildEngineer',false);
              this.gridApi.columnController.setColumnVisible('customerProgress',false);
              this.gridApi.columnController.setColumnVisible('requestCreatedOn',true);*/
              //console.log('not nr');
              var colDefOther = [
                { headerName: 'Request Number', field: 'requestNumber', width: 100 },
                { headerName: 'Requestor', field: 'requestCreatedBy', width: 200, resizable: true, tooltipField: 'email' },
                { headerName: 'Requested On', field: 'requestCreatedOn', width: 130 },
                { headerName: 'Closed On', field: 'closedOn', width: 130 },
                { headerName: 'Description', field: 'shortDesc', width: 300, resizable: true, tooltipField: 'shortDesc', autoHeight: true, cellStyle: { 'white-space': 'normal', 'line-height': 1.5 } },
                { headerName: 'WorkflowActive', field: 'workflowActive', resizable: true, width: 200, tooltipField: 'workflowActive', autoHeight: true, cellStyle: { 'white-space': 'pre', 'line-height': 1.5 } },
                { headerName: 'Status', field: 'requestStatus', width: 130, tooltipField: 'requestStatus' },
                { headerName: 'Request file', cellRendererFramework: GridRequestFileComponent, width: 100, tooltipField: 'fileToolTip' }
              ];
              this.gridApi.setColumnDefs(colDefOther);
            }
            if (requestOverviewForm.value.requestCategoryControl == 9801) {
              //this.gridApi.columnController.setColumnVisible('RequestFile',false);
            }

            //this.gridApi.refreshCells();
            this.requestOverviewData = data.table;
            this.globalParamsService._statusMessage.next("Double click on a row to open details.");
            this.globalParamsService.statusMessage.subscribe(stat => this.statusMessage = stat);

          } else {
            this.searchLoadingStatus = false; //ISR-2065 --Fix
            this.requestOverviewData = [];
            this.globalParamsService._statusMessage.next("");
            this.globalParamsService.statusMessage.subscribe(stat => this.statusMessage = stat);
          }
          if (this.requestOverviewForm.value.requestListType == 0) {
            this.gridColumnApi.setColumnVisible('closedOn', true)
          } else {
            this.gridColumnApi.setColumnVisible('closedOn', false)
          }
          //ISR-2065 --Fix
          let requestOverViewData = [];
          this.temprequestOverviewData = this.requestOverviewData;
          if (this.myRequestValue === true) {
            requestOverViewData = this.requestOverviewData.filter(t => t.requestCreatedBy == this.objUserModel.fullName);
            this.requestOverviewData = requestOverViewData;
          } else if (requestOverviewForm.get('requestNoSearch').value != '' || this.requestNoSearch != '') {
            requestOverViewData = [];
            this.temprequestOverviewData = this.requestOverviewData;
            requestOverViewData = this.requestOverviewData.filter(t => t.shortDesc.includes(requestOverviewForm.get('requestNoSearch').value) ||
              t.requestCreatedBy.includes(requestOverviewForm.get('requestNoSearch').value) || t.shortDesc.includes(requestOverviewForm.get('requestNoSearch').value) ||
              t.workflowActive.includes(requestOverviewForm.get('requestNoSearch').value));
            this.requestOverviewData = requestOverViewData;
          }
          else {
            this.requestOverviewData = this.temprequestOverviewData;
          }
          //ISR-2065 --Fix
        });
      }
    }
  }

  clear() {
    //console.log('test');
    //this.router.navigate(['/ro'])
    //window.location.href = this.sharedSVC.ISR_URL + "#/ro";
    this.globalParamsService._statusMessage.next("");

    this.globalParamsService.selectedRequestCategory.next("");
    this.globalParamsService.selectedRequestType.next("");
    this.globalParamsService.selectedRequestListType.next("");

    this.globalParamsService.selectedRequestLabel.next("");
    this.globalParamsService.selectedSearchCategory.next("");
    this.globalParamsService.selectedSearchField.next("");
    this.globalParamsService.selectedSearchText.next("");
    this.globalParamsService.selectedRequestSupplier.next("");

    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/ro']);
  }

  GetRequestOverviewSupplier(requestOverviewForm) {
    this.initGrid();
    this.requestOverviewData = [];
    if (requestOverviewForm.value.requestsupplier != null &&
      requestOverviewForm.value.requestsupplier != "null") {
      this.btUpdateSelectionStatus = false;
      this.updateSelectionTitle = '';
    } else {
      this.btUpdateSelectionStatus = true;
      this.updateSelectionTitle = 'Please make selection first.';
    }

  }

}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {SharedService} from '../Shared/shared.service'
//import { variable } from '@angular/compiler/src/output/output_ast';
import { delay } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _http: HttpClient,private sharedService:SharedService) { }
  getToken(email):Observable<any>{        
    return this._http.post(this.sharedService.API_URL + '/api/getToken/' , email );
  };

 

  
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import{userModel} from '../model/user.model';
import { SharedService } from '../Shared/shared.service';
@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  public userEmail="";
    private objUserModel=new userModel;
   private _userInfo=new BehaviorSubject<userModel>(new userModel);
   userInfo=this._userInfo.asObservable();

   constructor(private sharedService:SharedService) {}
  async getUserInfo(userModel) { 
    return await this.sharedService.GetUserByEmail(userModel);
 } 
}
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter, first, takeUntil } from 'rxjs/operators';
import { emailDefinitionModel } from '../model/user.model';
import { LoginService } from 'src/app/login/login.service';
import { SharedService } from '../Shared/shared.service';
//import { AuthService } from '../auth/auth.service';
//import { AuthService } from '../auth/auth-service.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Subject } from 'rxjs';
import { InteractionStatus } from '@azure/msal-browser';
import { userModel, validateOtp } from '../model/user.model';
import { LoginOTPService } from '../login-otp/login.service';
import { GlobalParamsService } from '../global/globalParams.service';
import { Console } from 'console';
//import { AzureAuthService } from '../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [emailDefinitionModel, validateOtp]
})
export class LoginComponent implements OnInit {//,OnDestroy  {
  form!: FormGroup;
  loading = false;
  submitted = false;
  isEricsson = false;
  isLogin = true;
  isAutoFocus = true;
  faq_data = this.loginService.data;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private validateOtp: validateOtp,
    private emailDefModel: emailDefinitionModel,
    private loginService: LoginService,
    private sharedService: SharedService,
    //private readonly authService: AuthService
    private msalAuthService: MsalService,
    private broadcastService: MsalBroadcastService,
    private loginOTPservice: LoginOTPService,
    private globalParamsService: GlobalParamsService,
    //private azureAuthservice:AzureAuthService    
    // private accountService: AccountService,
    // private alertService: AlertService    
  ) { }

  displayStyle = "none";

  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    //throw new Error('Method not implemented.');
  }
  ngOnInit(): void {
    this.isAutoFocus = true;
    //var str = document.baseURI
    //yet to resolve for invalid screen
    if (window.localStorage.getItem("loginState") == 'eric-inp') {
      this.isLogin = false;
      window.localStorage.setItem("loginState", 'eric-inp-log')
    } else { this.isLogin = true; }

    //var btn_autoLog = document.getElementById('auto-login');
    // btn_autoLog.focus();

    this.form = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email,
      Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]]
      // password: ['', Validators.required]
    });

    //this.form.get('username').setValidators(Validators.email); 

    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(async () => {
        try {
          if ((window.localStorage.getItem("loginState") == 'eric-inp' ||
            window.localStorage.getItem("loginState") == 'eric-inp-log') &&
            this.msalAuthService.instance.getAllAccounts().length > 0) {
            this.isLogin = false;
            var email = this.msalAuthService.instance.getAllAccounts()[0].username;
            let splitEmail = email.split("@");
            var domain = splitEmail[1];
            await this.ValidateUser();
            /* As per latest request
            if(domain.toLowerCase() == 'ericsson.com'){   
               await this.ValidateUser();
            }
            else{ 
             this.sharedService.clearStorage();
              this.sharedService.showMessageBox('You are not a Ericsson user. Enter your business email address and click on send OTP to use ISR');
              this.isLogin = true; 
            }*/
            //console.log("login ok");
          }
        }
        catch {
          this.isLogin = true;
        }
        //this.setLoginDisplay();
      });

    // this.sharedService.clearStorage();

    /*this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      //this.globalParamsService.isEricssonLogin.subscribe(isEric=>this.isEricsson=isEric); 
      if(this.msalAuthService.instance.getAllAccounts().length>0)
      {
      console.log("login done");
      this.ValidateUser();
      }
    }); */

  }

  get f() { return this.form.controls; }

  async onSubmit() {
    if (!this.isAutoFocus) { this.isAutoFocus = true; }

    this.sharedService.clearStorage();
    this.submitted = true;

    // reset alerts on submit
    //this.alertService.clear();

    // stop here if form is invalid     

    if (this.form.invalid) {
      return;
    }

    var email = this.f.username.value;
    let splitEmail = email.split("@");
    var domain = splitEmail[1];

    /*  if(domain.toLowerCase() == 'ericsson1.com')
      {
      window.localStorage.setItem("loginState","eric");
      //this.globalParamsService._isEricssonLogin.next(true);
      //this.globalParamsService.isEricssonLogin.subscribe(isEric=>this.isEricsson=isEric); 
      //this.isEricsson = true;
      await this.ericssonLogin();
      }
      else{*/
    window.localStorage.setItem("loginState", "NonEric");
    //this.globalParamsService._isEricssonLogin.next(false);
    //this.isEricsson = false;
    await this.nonEricssonLogin();
    //}

    /*  this.accountService.login(this.f.username.value, this.f.password.value)
          .pipe(first())
          .subscribe({
              next: () => {
                  // get return url from query parameters or default to home page
                  const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                  this.router.navigateByUrl(returnUrl);
              },
              error: error => {
                 // this.alertService.error(error);
                  this.loading = false;
              }
          });*/
  }

  async ericssonLoginClick() {
    if (this.isAutoFocus) {
      window.localStorage.setItem("loginState", "eric");
      await this.ericssonLogin();
    }
    else {
      this.isAutoFocus = true;
    }

  }

  async ericssonLogin() {
    /*this.broadcastService.inProgress$
  .pipe(
    filter((status: InteractionStatus) => status === InteractionStatus.None),
    takeUntil(this._destroying$)
  )
  .subscribe(async () => {
  
    if(this.msalAuthService.instance.getAllAccounts().length>0){
    console.log("login ok");
    await this.ValidateUser();
    }
    //this.setLoginDisplay();
  });*/
    this.msalAuthService.loginRedirect();
    // await this.msalAuthService.loginRedirect();

    //this.msalAuthService.loginRedirect();
    /*this.msalAuthService.loginPopup()
      .subscribe({
        next: (result) => {
          console.log(result.idToken);
          //console.log('hello'+ result);
          //this.setLoginDisplay();
        },
        error: (error) => console.log(error)
      }); */

    /*const claims = this.authService.identityClaims;
    if (!claims) {
      console.log("no authentication");
       return null;     
    }
    //return claims['name'];
    console.log("Authenticated");
    return claims['preferred_username'];*/
  }


  async nonEricssonLogin() {
    this.sharedService.clearStorage();
    // If needed add other codes
    await this.ValidateUser();
  }

  async ValidateUser() {
    window.localStorage.setItem('isAuthorized', 'false');
    var loginState = window.localStorage.getItem("loginState");
    if (loginState == 'eric-inp-log' || loginState == 'eric-inp') {
      this.isEricsson = true;
      window.localStorage.setItem("loginState", "eric-comp");
    } else { this.isEricsson = false; }

    this.loading = true;
    this.emailDefModel.id = 14;
    this.emailDefModel.Toaddress = this.f.username.value;

    if (this.isEricsson) {
      this.emailDefModel.Type = 'eric';
      this.emailDefModel.Toaddress = this.msalAuthService.instance.getAllAccounts()[0].username;
      this.sendEmailDefinitionData();
    }
    else {
      this.emailDefModel.Type = 'nonEric';
      //String tt = this.sharedService.getClientIpAddress().subscribe();
      this.emailDefModel.iPAddress = "";
      this.getIpdata();
      /*(await this.sharedService.getClientIpAddress()).subscribe(data => {
        if(data.IPv4 != undefined){
          this.emailDefModel.iPAddress = data.IPv4;
        console.log("ipaddress",data);
        this.sendEmailDefinitionData();
        }
      }); */
    }
  }

  async getIpdata() {
    this.emailDefModel.iPAddress = "";
    this.sharedService.getIPAddress1().subscribe(data => {
      if (data["IPv4"] != undefined) {
        this.emailDefModel.iPAddress = data["IPv4"];
        this.sendEmailDefinitionData();
      }
    },
      error => {
        this.getIpdata2();
      }
    );
  }

  async getIpdata2() {
    this.emailDefModel.iPAddress = "";
    this.sharedService.getIPAddress2().subscribe(data => {
      if (data["ip"] != undefined) {
        this.emailDefModel.iPAddress = data["ip"];
        this.sendEmailDefinitionData();
      }
    },
      error => {
        this.sendEmailDefinitionData();
      }
    );
  }


  async sendEmailDefinitionData() {
    this.loginService.SendSmtpEmailDefinitionsMail(this.emailDefModel).subscribe(data => {
      //this.objUserModel=data.table[0];
      //var resp = data;
      var resp = data;
      //alert('hello'+ resp);
      if (resp == "true") {
        // this.router.navigate(['/loginOtp/'+this.emailDefModel.Toaddress]);
        if (this.isEricsson) {
          var email = this.msalAuthService.instance.getAllAccounts()[0].username; //this.msalAuthService.instance.getActiveAccount().username;
          let domain = email.split("@");
          this.validateOtp.domain = domain[1];
          this.validateOtp.otp = this.f.username.value;
          this.validateOtp.email = email;
          this.validateOtp.type = this.emailDefModel.Type;
          this.loginOTPservice.ValidateOTP(this.validateOtp).subscribe(async data => {
            //this.objUserModel=data.table[0];
            //var resp = data;
            var resp = data.table[0].resp;
            if (resp == 1) {
              await this.loginOTPservice.getToken(email);
              await this.loginOTPservice.setCookieValue(data);
              this.loginOTPservice.saveWebLog(this.validateOtp).subscribe(data => {
                //this.objUserModel=data.table[0];
                //var resp = data;
                //var resp = data.table[0].resp;
                if (data == true) {
                  window.localStorage.setItem('isAuthorized', 'true');
                  window.location.href = document.baseURI + '/#/home';
                  //this.router.navigate(["/home"]);
                }
              });
            }
            else if (resp == 4) { this.sharedService.showMessageBox('Invalid User - Please try again or check with Admininstrator'); }
          });

        }
        else {
          this.globalParamsService._isEricssonLogin.next(true);
          this.router.navigateByUrl('/loginOtp/' + this.emailDefModel.Toaddress);
        }
      }
      else {
        this.sharedService.showMessageBox(resp);
        this.sharedService.clearStorage();
        //this.sharedService.showMessageBox('You are not a Ericsson user. Enter your business email address and click on send OTP to use ISR');
        this.isLogin = true;
      }
      this.loading = false;
      return;
    });
  }

  focusElement(event) {
    var btn_sendOTP = document.getElementById('send-otp');
    btn_sendOTP.focus();

    var btn_autoLog = document.getElementById('auto-login');
    btn_autoLog.blur();

    this.isAutoFocus = false;
    /*console.log('testing button disablility',btn_sendOTP['disabled']) ;
    if(btn_sendOTP['disabled'])
    {
      txt_email.focus();
    }
    else
    {
      btn_sendOTP.focus();   
    }*/

  }

}

import { Component, OnInit } from '@angular/core';
import {GlobalParamsService} from '../../global/globalParams.service';
import { SharedService } from '../../Shared/shared.service';
import{userModel} from '../../model/user.model';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  providers: [userModel]
})
export class FooterComponent implements OnInit {
devEnv:string='';
devMode:boolean=false;
lblDevEnv:string=';'
version:string='';
statusMessage:string='';
  constructor(private globalParamsService:GlobalParamsService,private objUserModel:userModel,private sharedService:SharedService,
    private cookieService: CookieService) { }

  ngOnInit() {  
    this.globalParamsService.developmentEnv.subscribe(devEnv=>this.devEnv=devEnv);
    
    //if(window.location.href.indexOf('?')!=-1) {
      /*var queryString= window.location.href.split("?")[1];
      var userEmail=queryString.split("&")[0].split("=")[1];*/
      this.objUserModel.email=this.cookieService.get('userEmail');   
      if(window.localStorage.getItem('isAuthorized') == 'true' &&
      this.cookieService.get('ISTCookie2')!='' && 
      this.cookieService.get('ISTCookie2') != null ){   
       this.sharedService.GetUserByEmail(this.objUserModel).subscribe(data=>{
         this.objUserModel=data.table[0];
         this.devMode=this.objUserModel.devMode;  
         if(this.devEnv=="localhost") {  
          if(this.devMode==true){
            this.lblDevEnv="Using test database in devmode";
          }   else{
            this.lblDevEnv="Using test database" ;
          }      
        }
        else if(this.devEnv=="test") {
          if(this.devMode==true){
            this.lblDevEnv="Using test database in devmode";
          }   else{
            this.lblDevEnv="Using test database" ;
          }
        }
        else if(this.devEnv=="prod") {
          this.lblDevEnv="-";
      }
       }); 
      }
      //}
   
  this.globalParamsService.version.subscribe(ver=>this.version=ver);
  this.globalParamsService.statusMessage.subscribe(stat=>this.statusMessage=stat);
  }

}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {SharedService} from '../Shared/shared.service'
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class CreateNewRequestService {

  constructor(private _http: HttpClient,private sharedService:SharedService) { }

  GetMenu(email:string):Observable<any>{
    return this._http.get<any[]>(this.sharedService.API_URL + '/api/ISR/createNewRequest/GetMenu?email='+ email);
  };
}

import { Component, Inject, OnInit } from '@angular/core';
import { SharedService } from '../Shared/shared.service';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
  selector: 'app-message-box-header',
  templateUrl: './message-box-header.component.html',
  styleUrls: ['./message-box-header.component.css']
})
export class MessageBoxHeaderComponent implements OnInit {
  message = "";
  headerMessage = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string, title: string },private sharedSvc:SharedService) { }

  ngOnInit(): void {
    this.headerMessage = this.data.title;
    this.message = this.data.message;
    /*this.headerMessage = this.sharedSvc.messageBoxHeaderMsg;
    this.message = this.sharedSvc.messageBoxDetailMsg;*/
  }

}

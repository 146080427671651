
<form novalidate [formGroup]="requestViewForm">    
    <div class="row isr">
            <div class="col-lg-6">
            <div class="card card-primary">
                <div class="card-header">
                    <div class="card-title">Request</div>
                </div>
            </div>
            <div class="card card-primary">
                <div class="card-block"> 
                    <div class="row">
                        <div class="col-lg-6">
                            <label>Type of request:</label>                                                        
                        </div>
                        <div class="col-lg-6">
                            <label>{{reqParentTypeDesc}}</label> 
                            <!--<select formControlName="requestType" class="selectTypeOfRequest" (change)="bindSubTypeOfRequest($event)" [ngClass]="{ 'is-invalid': submitted && validated.requestType.errors }">
                                <option selected value="null">Select</option>
                                <option *ngFor="let reqType of listTypeOfRequest" [value]="reqType.itmId">{{reqType.itmName}}</option> 
                            </select>
                       <div *ngIf="submitted && validated.requestType.errors" class="ValidationLabel">
                        <div *ngIf="validated.requestType.errors.required">Type of request is required.</div>
                       </div>-->  
                    </div>
                    </div>                     
                </div>                       
                </div>
                
                <div *ngIf="showSubTypeOfRequest" class="card card-primary">
                    <div class="card-block"> 
                        <div class="row">
                            <div class="col-lg-6">
                                <label>Sub type of request:</label>
                            </div>
                            <div class="col-lg-6">
                               <!-- <select formControlName="requestSubType" class="selectTypeOfRequest" (change)="GetRequestDefinitions($event)" [ngClass]="{ 'is-invalid': submitted && validated.requestSubType.errors }"> 
                                    <option selected value="null">Select</option>
                                    <option *ngFor="let reqSubType of listSubTypeOfRequest" [value]="reqSubType.itmId">{{reqSubType.itmName}}</option> 
                                </select>
                                <div *ngIf="submitted && validated.requestSubType.errors" class="ValidationLabel">
                                    <div *ngIf="validated.requestSubType.errors.required">Sub type of request is required.</div>
                                   </div> -->
                                   <!--<input type="text" class="inputDescription" disabled="disabled" value="{{reqSubTypeDesc}}"/>-->
                                <label>{{reqSubTypeDesc}}</label> 
                            </div>
                        </div>                     
                    </div>                       
                    </div>
                    <!-- <div *ngIf="!reqDefinitionModel.oneTimeAllowed" class="card card-primary"> -->
                        <div class="card card-primary">
                        <div class="card-block"> 
                            <div class="row">
                                <div class="col-lg-6">
                                    <label>Short request description:</label>
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" formControlName="shortDesc" [ngClass]="{ 'is-invalid': submitted && validated.shortDesc.errors }" class="inputDescription">
                                    <div *ngIf="submitted && validated.shortDesc.errors" class="ValidationLabel">
                                        <div *ngIf="validated.shortDesc.errors.required">Short request description is required.</div>
                                       </div> 
                                </div>
                            </div>        
                            <div class="row" style="margin-top: 3px;">
                                <div class="col-lg-6" style="padding-top: 15px;">
                                    <label>Requested delivery date:</label>
                                </div>
                                <div class="col-lg-6">                                                  
                                    
                                     <mat-form-field appearance="fill" style="width: 120px;padding-top: 0px;" class="custom-class-name full-width" >
                                     <input matInput [min]="minDate" [matDatepicker]="picker"  formControlName="requestedDeliveryDateControl"/>
                                     <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                     <mat-datepicker #picker>
                                    </mat-datepicker> 
                                     </mat-form-field> 
                                     <button type="submit" style="padding: 2px;" class="button-design" (click)="selectSLADate()">Select SLA date</button>                
                                    <img style="padding-bottom: 5px; margin-left: 5px;"  title="{{requestedDeliveryDateNote}}"  src="assets/Images/info.png" >
                                </div>
                            </div>   
                        </div>                       
                        </div>
                        
                            
                        
                        <!-- <div *ngIf="reqDefinitionModel.oneTimeAllowed "> -->
                            <div>
                            <!--<div class="card card-primary">
                                <div class="card-block"> 
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <label>Is this a one time request for 3rd party?</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <input type="radio" formControlName="rbOneTimes" value="Yes" (change)="ChangeOneTime()">
                                            &nbsp;<label>Yes</label>&nbsp;&nbsp;
                                            <input type="radio" formControlName="rbOneTimes" value="No" (change)="ChangeOneTime()" >
                                            &nbsp;<label>No</label>&nbsp;      
                                        </div>
                                    </div>    
                                </div>                       
                                </div>
                                <div class="card card-primary">
                                    <div class="card-block"> 
                                        <div class="row" style="margin-top: 3px;">
                                            <div class="col-lg-6">
                                                <label>One-time user</label>
                                            </div>
                                        </div>
                                        <div class="row" style="margin-top: 3px;">
                                            <div class="col-lg-6">
                                                <label>Name:</label>
                                            </div>
                                            <div class="col-lg-6">
                                                <input type="text" formControlName="oneTimeName"> 
                                            </div>
                                        </div>    
                                        <div class="row" style="margin-top: 3px;">
                                            <div class="col-lg-6">
                                                <label>Email address:</label>
                                            </div>
                                            <div class="col-lg-6">
                                                <input type="text" formControlName="oneTimeEmailAddress"> 
                                            </div>
                                        </div> 
                                        <div class="row" style="margin-top: 3px;">
                                            <div class="col-lg-6">
                                                <label>Request description:</label>
                                            </div>
                                            <div class="col-lg-6">
                                                <input type="text" formControlName="oneTimeRequestDescription"> 
                                            </div>
                                        </div> 
                                    </div>                       
                                    </div>-->
                        </div> 
                        <div *ngIf = "enableRelatedServices" class="card card-primary" >
                            <div class="card-block"> 
                                <div class="row">

                                    <div class="col-lg-6">
                                        <label>Specify the service(s) this request is for:</label>
                                        <mat-list style="border: lightgray;border-width: 2px;
                                         border-style: solid;border-radius: 4px;padding-right: 2px;
                                          height:100px; overflow: auto;">                                           
                                          <mat-list-item *ngFor="let item of items; let i = index">
                                            <h3 mat-line> {{ item.service }} </h3>
                                            <button  mat-icon-button  color="warn" (click)="removeItem(i)">
                                              <mat-icon>delete</mat-icon>
                                            </button>
                                          </mat-list-item>
                                        </mat-list>
                                      </div>

                        <!--<mat-form-field>-->
                            <div class="col-lg-6" style="margin-top: 20px;">                                
                            <label>Add a service to the list :</label>
                           <!-- <input style="margin-left: 3px;" formControlName="newItem" matInput [(ngModel)]="newItem" placeholder="Item name"> -->                       
                          <!--</mat-form-field>-->
                          <mat-form-field class="example-full-width">
                            <mat-label>Service</mat-label>
                            <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                <!--<mat-autocomplete #auto="matAutocomplete">-->
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{ option.service }}
                             <!-- @for (option of filteredOptions | async; track option) {
                                <mat-option [value]="option">{{option.name}}</mat-option>
                              }-->
                            </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>

                          <button type="submit" class="button-design" (click)="addItem()">Add</button>
                          </div>                         
                          
                        </div>
                          </div>
                          </div>

                        <div class="card card-primary">
                            <div class="card-block"> 
                                <div class="row">
                                    <div class="col-lg-6">
                                        <button type="submit" class="button-design" (click)="backToMenu()">Menu</button>
                                    </div>
                                    <div class="col-lg-6" style="text-align: right;" >
                                        <button type="submit" class="button-design" (click)="GoToProjectRefandBudget()">{{btNext}}</button>
                                    </div>
                                </div>                                
                            </div>                       
                            </div>
            </div> 
    </div>
    </form>
   
  
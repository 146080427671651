import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import{Router,ActivatedRoute} from '@angular/router';
import { SharedService } from '../Shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  _showQuickSearch=new BehaviorSubject<boolean>(false);  
  showQuickSearch=this._showQuickSearch.asObservable();

  _isChecklistPresent=new BehaviorSubject<boolean>(false);  
  isChecklistPresent=this._isChecklistPresent.asObservable();

  _islogin=new BehaviorSubject<boolean>(false);  
  islogin=this._islogin.asObservable();
  
  constructor(private router:Router, private sharedService:SharedService) {  

    

   }
}

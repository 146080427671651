<br>
<form novalidate [formGroup]="requestOverviewForm">
    <div class="row isr-requestOverview">
        <div class="col-lg-12">
            <div class="card card-primary">
                <div class="card-header">
                    <div class="card-title">Request Overview - Request Selection Criteria</div>
                </div>
                <div class="card-block">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="row form-group">
                                <div class="col-lg-3">
                                    <label for="requestLabelControl">
                                        Select request based on
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <select formControlName="requestLabelControl" class="form-control"
                                        (change)="GetReqCategory(requestOverviewForm)">
                                        <option value="R">RequestType</option>
                                        <option value="A">Advanced Selection</option>
                                        <option value="S">Supplier</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row form-group" *ngIf="isAdvanceSearch==0">
                                <div class="col-lg-3">
                                    <label for="requestCategoryControl">
                                        Request Category
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <select formControlName="requestCategoryControl" class="form-control"
                                        (change)="GetRequestOverviewRequestType($event.target.value,requestOverviewForm)">
                                        <option [ngValue]="null">Select</option>
                                        <option *ngFor="let cat of listRequestCategory" [value]="cat.itmId">
                                            {{cat.itmName}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row form-group" *ngIf="isAdvanceSearch==1">
                                <div class="col-lg-3">
                                    <label for="searchCategoryControl">
                                        Select fields Category
                                    </label>
                                </div>
                                <div class="col-lg-3">
                                    <select formControlName="searchCategoryControl" class="form-control"
                                        (change)="GetSearchFields(requestOverviewForm)">
                                        <option *ngFor="let cat of searchCategoryList" [value]="cat.value">{{cat.text}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row form-group" *ngIf="isAdvanceSearch==2">
                                <div class="col-lg-3">
                                    <label for="requestsupplier">
                                        Supplier
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <select formControlName="requestsupplier" class="form-control"
                                        (change)="GetRequestOverviewSupplier(requestOverviewForm)">
                                        <option [ngValue]="null">Select</option>
                                        <option *ngFor="let req of listRequestSupplier" [value]="req.supplierID">
                                            {{req.supplierName}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="row form-group" *ngIf="showTypeDiv && isAdvanceSearch==0">
                                <div class="col-lg-3">
                                    <label for="requestType">
                                        Request Type
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <select formControlName="requestType" class="form-control"
                                        (change)="GetRequestType(requestOverviewForm)">
                                        <option selected value="null">Select</option>
                                        <option *ngFor="let reqType of listRequestType" [value]="reqType.itmId">
                                            {{reqType.itmName}}</option>
                                    </select>
                                </div>

                            </div>
                            <div class="row form-group" *ngIf="isAdvanceSearch==1">
                                <div class="col-lg-3">
                                    <label for="searchFieldControl">
                                        Select in field
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <select formControlName="searchFieldControl" class="form-control">
                                        <option *ngFor="let frm of searchIn" [value]="frm.name">{{frm.name}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="row form-group" *ngIf="isAdvanceSearch==0">
                                <div class="col-lg-3">
                                    <label for="requestListType">
                                        Request List Type
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <!-- <select formControlName="requestListType" [(ngModel)]="requestListTypeModel" class="ebSelect form-control" style="width:300px;"> -->
                                    <select formControlName="requestListType" class="form-control" (change)="onChangeRequestListType(requestOverviewForm)"> <!--ISR-2065 --Fix-->
                                        <option value="">No Extra Selection</option>
                                        <option value="1">Nonclosed requests</option>
                                        <option value="0">Closed requests</option>
                                    </select>
                                    <!-- <img  width = "15" height="10"  src="assets/Images/view-icon.png" > -->
                                </div>
                            </div>
                            <div class="row form-group" *ngIf="isAdvanceSearch==1">
                                <div class="col-lg-3">
                                    <label for="searchTxtControl">
                                        Select on Text
                                    </label>
                                </div>
                                <div class="col-lg-6">
                                    <input formControlName="searchTxtControl" type="text"
                                        class="ebInput requestSearchtxt" id="searchTxt" />
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-6">
                            <!--ISR-2065-->
                            <div class="row">
                                <div class="col-lg-12">
                                    <label class="ebLabel">Select on everyones/my request</label>
                                </div>
                                <div class="col-lg-12">
                                    <label class="ebSwitcher budgetSlider">
                                        <input type="checkbox" class="ebSwitcher-checkbox" formControlName="myRequest"
                                            [checked]="myRequest" (change)="slideCheck(requestOverviewForm)" />
                                        <div class="ebSwitcher-body">
                                            <div class="ebSwitcher-onLabel ebBgColor_darkGreen">My requests</div>
                                            <div class="ebSwitcher-switch"></div>
                                            <div class="ebSwitcher-offLabel">Everyones requests</div>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <label class="ebLabel">Select on Text</label><br />
                                    <input type="text" class="ebInput ebInput_width_full requestSearchtxt"
                                        ng-keypress="vm.onDefSearchEnterPress($event)"
                                        ng-mouseover="setStatusMessage(0);" ng-mouseleave="setStatusMessage(-1);"
                                        formControlName="requestNoSearch" />
                                </div>
                            </div>
                            <!--ISR-2065-->
                            <div class="row">
                                <div class="col-lg-4">
                                    <button (click)="clear()" [attr.title]="clearTitle"
                                        class="ebBtn ebBtn_color_darkBrown"
                                        style="min-width:20px;margin-top:3px;margin-left:33px;margin-bottom: 4px;">
                                        <i class="ebIcon ebIcon_clearSearch"></i>Clear</button>

                                </div>
                                <!--<div class="col-lg-2">
                                            <button type="submit" name="button" (click)="gotoISR()" class="ebBtn ebBtn_color_light" style="margin-top:3px;">Return to menu</button>
                                        </div>  -->
                            </div>
                            <div class="row">
                                <div class="col-lg-4">
                                    <button type="submit" name="button" [attr.title]="updateSelectionTitle"
                                        (click)="updateSelection(requestOverviewForm)"
                                        class="ebBtn ebBtn_color_darkGreen" [disabled]="btUpdateSelectionStatus && requestOverviewForm.value.requestListType == ''"
                                        style="margin-top:3px;">
                                        <i class="ebIcon ebIcon_quickSearch"></i> <!--ISR-2065 --Modification [&& requestOverviewForm.value.requestListType == '']-->
                                        Update Selection</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--
        //ISR-2065 --Fix
    -->
    <div style="margin-left:16px;" class="ebLoader-container" *ngIf="searchLoadingStatus == true">
        <span class="ebLoader-Dots ebLoader-Dots_color_paleBlue"></span>
    </div>
    <!--
        //ISR-2065 --Fix
    -->
    <div class="row isr-requestOverview">
        <div class="col-lg-12">
            <div class="card card-primary">
                <div class="card-block">
                    <div class="row">
                        <ag-grid-angular class="ag-theme-balham isr-requestOverview-grid"
                            [rowData]="requestOverviewData" [columnDefs]="columnDefs" [modules]="modules"
                            (gridReady)="onGridReady($event)" [animateRows]="true" [gridOptions]="gridOptions"
                            (rowDoubleClicked)="onRowDoubleClick($event)">
                        </ag-grid-angular>
                        <!-- <div id="myGrid" class="ag-theme-balham isr-requestOverview-grid"></div> -->
                    </div>
                </div>
            </div>
        </div>
        <!--ISR-2065-->
        <div class="col-lg-12"
            *ngIf="requestOverviewForm.value.requestListType==0 
            && !(requestOverviewForm.value.requestListType == 1 || requestOverviewForm.value.requestListType == '')">
            <div class="row card-block gridFooterText">
                <span>Double click on a row for details. Use filter via column headers to search within results.
                    <br />Only this and last years requests are shown.
                </span>
            </div>
        </div>
    </div>
</form>
<div style="height: 100%;">
  <div id="header">
    <div class="head-content">Select a request type to get more information about it</div>
    <div>
      <div class="head-button">
        <button class="button-style" (click)="accordion.closeAll()">Collapse All</button>
        <button class="button-style" (click)="accordion.openAll()">Expand All</button>
      </div>
    </div>
    <div class="body-content">
      <ngx-masonry [options]="{ horizontalOrder: true,gutter:4,percentPosition: true}">
        <mat-accordion multi>
          <div class="masonry-item" ngxMasonryItem *ngFor="let menu of resultData">

            <mat-expansion-panel (afterExpand)="panelevent(true)" (afterCollapse)="panelevent(false)" [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{menu.menuDescription}}
                </mat-panel-title>                
              </mat-expansion-panel-header>

              <div class="mat-style-card">
                <div *ngFor="let category of menu.catogory;">
                  <div class="mat-card-subtitle">{{category.categoryDescription}}</div>
                  <div class="item_content">
                  <div *ngFor="let menuitem of category.menuItems;" class="menu_items">                   
                      <ul>
                        <li>
                          <a class="mat-card-content" [routerLink]="['/createRequestInfo/' + menuitem.requestType]" 
                          [state]="{ CodeTypeId:menuitem.codeTypeId , CodeSubTypeId:menuitem.codeSubTypeId}">
                            {{menuitem.menuText}}
                          </a>
                        </li>
                      </ul>
                    
                  </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
        </mat-accordion>
      </ngx-masonry>
    </div>
  </div>
<!--<p>custom-tooltip works!</p>-->
<div id="custom-tooltip-container" (mouseenter)="recalculate()" (mouseleave)="hideTooltip()">
	<ng-content></ng-content>
	<div id="custom-tooltip-popup" #popupEl [style.top]="popupOffsetHeight" 
    [style.left]="popupOffsetWidth"
    [style.opacity]="popupVisible ? '1' : '0'" 
    [style.visibility]="popupVisible ? 'visible' : 'hidden'">
		<ng-container *ngTemplateOutlet="template"></ng-container>
		<!--<div id="custom-tooltip-popup-tail"></div>-->
	</div>
</div>

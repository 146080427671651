import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CreateNewRequestService } from './create-new-request.service';
import { MatCardModule } from '@angular/material/card';
import { CookieService } from 'ngx-cookie-service';
import { MatAccordion } from '@angular/material/expansion';
import { NgxMasonryComponent } from 'ngx-masonry';
import { NgxMasonryOptions } from 'ngx-masonry';
import { LayoutAlignDirective } from '@angular/flex-layout';
/*test commit*/
@Component({
  selector: 'app-create-new-request',
  templateUrl: './create-new-request.component.html',
  styleUrls: ['./create-new-request.component.css']
})
export class CreateNewRequestComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;

  resultData: any;
  str: String = '';
  ISTCookieValue: string = '';
  email: string = '';
  panelOpenState = true;

  constructor(private createNewRequestSVC: CreateNewRequestService,
    private cookieService: CookieService) { }
  collapsed: boolean = false;

  ngOnInit(): void {
    // var searchElement = document.getElementById('qSearchInfo');    
    // searchElement.style.display = "none";
    console.log('Create NEW Request Module.........');
    this.LoadMenu();
  }

  LoadMenu() {
    try {
      this.ISTCookieValue = this.cookieService.get('ISTCookie2');
      this.email = this.ISTCookieValue.substring(this.ISTCookieValue.indexOf("username"), this.ISTCookieValue.indexOf("&")).replace("username=", '');
      this.createNewRequestSVC.GetMenu(this.email).subscribe(data => {
        this.resultData = data;
      });
    }
    catch (e) {
      console.log(e);
    }
  }

  collapse(isCollapse: boolean) {
    this.collapsed = isCollapse;
    this.masonry.layout();
  }

  panelevent(isCollapse: boolean) {
    this.masonry.layout();
  }
}

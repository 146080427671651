import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {SharedService} from '../../Shared/shared.service'
@Injectable({
    providedIn: 'root'
  })
  export class headerService {
  
    constructor(private _http: HttpClient,private sharedService:SharedService) {}
    // GetUserByEmail(userModel):Observable<any>{            
    //     return this._http.post<userModel>(this.API_URL + '/api/ISR/request/PostISTUsersByEmail/', userModel);
    //   };
    GetChecklistReport(data):Observable<any>{            
        return this._http.post<any>(this.sharedService.API_URL + '/api/checklist/PostCreateChecklistReport/', data);
      };
  }
  
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
import {SharedService} from '../Shared/shared.service'

@Injectable({
  providedIn: 'root'
})
export class RequestDetailService {

  constructor(private _http: HttpClient,private sharedService:SharedService) {}
  
  GetISRRequestDetail(requestNo):Observable<any>{
    return this._http.get<any[]>(this.sharedService.API_URL + '/api/ISR/request/GetRequestDetail/' + requestNo);
  }

  GetStatusColors():Observable<any>{
    return this._http.get<any[]>(this.sharedService.API_URL + '/api/GetStatusColors');
  };

  GetChangeInfo(requestNo):Observable<any>{
    return this._http.get<any[]>(this.sharedService.API_URL + '/api/CL/GetChangeInfo/' + requestNo);
  }

  GetChangeLogFiles(changeId):Observable<any>{
    return this._http.get<any[]>(this.sharedService.API_URL + 'api/CL/GetChangeLogFiles/' + changeId);
  }

  GetRelatedChangeLog(requestNo):Observable<any>{
    return this._http.get<any[]>(this.sharedService.API_URL + '/api/CL/GetRelatedChanges/' + requestNo);
  }

  GetRelatedChangeInfo(requestNo, changeId):Observable<any>{
    return this._http.get<any[]>(this.sharedService.API_URL + '/api/CL/GetRelatedChangeInfo/' + requestNo + '/' + changeId);
  }

  GetIpsFirewall(requestNo, isEditRequest):Observable<any>{
    return this._http.get<any[]>(this.sharedService.API_URL + '/api/ips/rfc/detail/' + requestNo + '/' + isEditRequest);
  }
  CheckForSupplier(formData):Observable<any>{
    return this._http.post<any[]>(this.sharedService.API_URL + '/api/CheckForSupplier/', formData);
  }
  GetStatusBarData(formData):Observable<any>{
    return this._http.post<any[]>(this.sharedService.API_URL + '/api/ISR/request/GetStatusBarData/', formData);
  }
}

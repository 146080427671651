import { LoginService } from './../../login/login.service';
import { NgModule } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../Shared/shared.service';
import{userModel} from '../../model/user.model';
import {GlobalParamsService} from '../../global/globalParams.service';
import {MenuService} from '../../global/menu.service';
import {UserInfoService} from '../../global/userInfo.service'
import{Router,ActivatedRoute} from '@angular/router';
import { JsonpClientBackend } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import {RequestOverviewComponent} from '../../request-overview/request-overview.component'
import {FormGroup, FormControl, UntypedFormBuilder } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import {headerService} from './header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [userModel]
})

export class HeaderComponent implements OnInit {  

  data = this.loginService.data;
/*data=[{title:'How do I get an account for this site?',
description:'If you need an account, you click on REQUEST PASSWORD in the login panel. If you are from a validated company you will receive a password at the given email address within minutes, otherwise an email will be sent to ENN Project Support. If you are a third party and think you should have access, please contact the customer who you are working for.'},
{title:'I forgot my password, how do I get a new one?',description:'Click on REQUEST PASSWORD in the login panel. If your email address is found in the database, a new password will be generated and sent to the stored email address.'},
{title:'I received a new password, but I did not request one?',description:'Someone (accidently) entered your email address to get a new password. You do not have to use it, the password you already have will still work.'},
{title:"I am very sure I entered the right password, but the page stays on the login screen.",description:"Remove the cookies for this or all sites."},
{title:"What browser should I use?",description:"We advise to use the latest version of Microsoft Edge or Chrome."},
{title:"What happens if I click on Log off at the top of the screen?",description:"This will remove the cookie with your log on data from your computer preventing others who might use your computer from creating a request in your name. When the log off is not used, this websites cookie will remove itself after 240 minutes."},
{title:"What happens if I click on Restart at the top of the screen?",description:"This will remove all entered data from this session. You will be redirected to the first screen and you can start a new request."},
{title:"Some text does not appear to fit right.",description:"This may be for example text within buttons, or within labels. Also some text to be shown within a tooltip might be shown through the text. Please use the latest version of Microsoft Edge or Chrome and maybe clear the local browsers cache."},
{title:"Do I need to hurry, while filling in this webform?",description:"No, you have plenty of time (24 hours) to complete your request. For IP Infrastructure, Transmission Cabling, Power and Firewall requests your input is stored each time you click on a NEXT button. For other request types only the basic request data will be stored. You can continue with a stored request at any time you wish. An exclamation mark on the NEW REQUEST button indicates an unfinished request."},
{title:"Why do I stay on the login page after login?",description:"This happens when you login with a one-time user account and did not use the link from the email."},
{title:"The website says the certificate is not ok.",description:"This happens when you do not use the right url. You should use https://isr.enn.ericsson.net."},
]*/

  //objRequestOverviewComponent:RequestOverviewComponent;
  
  userFullName:String='';
  devMode=false;
  devEnv:string='';  
  yellowBackGround='linear-gradient(180deg, yellow 0, yellow 100%)';
  whiteBackGround='linear-gradient(180deg, #fff 0, #f4f4f4 100%)';
  requestNoDisp='';
  isCLReq:Boolean=false;
  keyword:String='';    
  APP_URL:String='';
  requestNumber:String='';
  checklistformData: FormData;
  routerUrl:String='';
  showChecklist:Boolean=false;
  showQuickSearch:Boolean=false;
  islogin:Boolean=false;
  constructor(private sharedService:SharedService,
    private globalParamsService:GlobalParamsService, 
    private userInfoService:UserInfoService, 
    private objUserModel:userModel, 
    private router:Router,
    private cookieService: CookieService,
    private formBuilder: UntypedFormBuilder,
     private objheaderService:headerService,
     private route: ActivatedRoute, 
     private menuService:MenuService,
     private loginService:LoginService
     ) {
    //this.objRequestOverviewComponent=new RequestOverviewComponent(null,this.router,null,null);    
   }
   displayStyle = "none";
   
   openPopup() {
     this.displayStyle = "block";
   }
   closePopup() {
     this.displayStyle = "none";
   }
  ngOnInit() {  
    this.menuService.showQuickSearch.subscribe(qsearch=>this.showQuickSearch=qsearch);
    this.menuService.showQuickSearch.subscribe(qsearch=>this.showQuickSearch=qsearch);
    this.menuService.islogin.subscribe(log=>this.islogin=log);

    this.globalParamsService.requestNumber.subscribe(reqNo=>this.requestNumber=reqNo);
    if(this.requestNumber=='' || this.requestNumber==undefined){
      this.route.params.subscribe(params => {      
        this.requestNumber=params['requestNo'];
      });
    }
    if(this.requestNumber!='' && this.requestNumber!=undefined){
      this.showChecklist=true;
    }else{
      this.showChecklist=false;
    }
    this.menuService.isChecklistPresent.subscribe(chk=>this.showChecklist=chk);
    /*if(window.location.href.indexOf('?')!=-1) {
    var queryString= window.location.href.split("?")[1];
    var userEmail=queryString.split("&")[0].split("=")[1];
    this.objUserModel.email=userEmail;   */ 
    if(window.localStorage.getItem('isAuthorized') == 'true' &&
    this.cookieService.get('ISTCookie2')!='' && 
    this.cookieService.get('ISTCookie2') != null ){
   this.objUserModel.email =  this.cookieService.get('userEmail');
     this.sharedService.GetUserByEmail(this.objUserModel).subscribe(data=>{
       this.objUserModel=data.table[0];
       this.userFullName=this.objUserModel.fullName;  
     }); 
    }
    /*}else{
      this.userFullName="";
    }*/

    this.requestNoDisp = this.cookieService.get('ReqNoDisp');
    var clReq = this.cookieService.get('CLReq');
    if(clReq == 'yes') {
       this.isCLReq = true;
    }
    else this.isCLReq = false;

    this.globalParamsService.developmentEnv.subscribe(devEnv=>this.devEnv=devEnv);   
    this.loadHeadColor();   
  }

  checklistReport(){  
    if(this.requestNumber==''){
     // alert('Please select Request.');
     this.sharedService.showMessageBox("Please select Request.");
      return;
    } 
   
   this.checklistformData=new FormData();
    this.checklistformData.append('requestNo',JSON.stringify(this.requestNumber));        
    this.checklistformData.append('userId',JSON.stringify(this.objUserModel.shortName));  
    var url='';
    this.objheaderService.GetChecklistReport(this.checklistformData).subscribe(data=>{
      if(data.length>0){
        if(data!="nofile"){ 
           url = this.sharedService.API_URL + "GetFile.ashx?file=" + data;          
           window.open(url); 
           //alert("File exported successfully.")
           this.sharedService.showMessageBox("File exported successfully.");
        }else{ 
          //alert("No checklist available.")
          this.sharedService.showMessageBox("No checklist available.");
        }
      }else{
        //alert("No checklist available.")
        this.sharedService.showMessageBox("No checklist available.");
      }      
    });     
  }

  loadHeadColor()
  {
    var eleHead = document.getElementById('TopMenu');
      if(this.devEnv=="localhost") {        
        eleHead.style.backgroundColor = "orange";
      //window.location.href="http://localhost:51682/Default.aspx?rq=logout";    
    }
    else if(this.devEnv=="test") {
      eleHead.style.backgroundColor = "yellow";     
    }
    else if(this.devEnv=="prod") {
      eleHead.style.backgroundColor =  "rgb(245, 245, 245)";      
    }
  }
  
  quickSearch(){  
    var search=this.Encode_keyword(this.keyword);
    this.globalParamsService.searchKeyword.next(search);
    this.keyword='';   
  this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/ro']);
    //this.objRequestOverviewComponent.getQuickSearchResult(search);
  }
  onEnterPress(event: KeyboardEvent) {
    if(event.charCode==13){
      this.quickSearch();
    }
  }
   Encode_keyword(val:String) {
    var value = val;    
    if (typeof value === 'undefined') { } else {
       
        value = value.replace(/\n/g, '&gt;');        
    }
    return value;
  }
logout(){   
  this.sharedService.logout(); 
  //window.localStorage.clear();
  //window.sessionStorage.clear();
  //window.location.href = this.sharedService.ISR_URL + "Default.aspx?rq=logout";
 /* if(this.devEnv=="localhost") {
    window.location.href="http://localhost:51682/Default.aspx?rq=logout";    
  }
  else if(this.devEnv=="test") {
      window.location.href="https://isrtest.enn.ericsson.net?rq=logout";      
  }
  else if(this.devEnv=="prod") {
    window.location.href="https://isr.enn.ericsson.net?rq=logout";    
  }*/
  
}

showChangeLog(){    
  if(this.cookieService.get('CLReq') == "yes") {
    var url = '';  
    if(this.devEnv=="localhost") {
        url="https://rhtest.enn.ericsson.net/changelog/#/change/" + this.requestNoDisp;
      }
      else if(this.devEnv=="test") {
        url="https://rhtest.enn.ericsson.net/changelog/#/change/" + this.requestNoDisp; 
      }
      else if(this.devEnv=="prod") {
        url="https://rh.enn.ericsson.net/changelog/#/change/" + this.requestNoDisp;    
      }
      this.cookieService.set('CLReq', 'no');
      this.cookieService.set('ReqNoDisp', '');
      window.open(url, '_blank');
  }
  
}

onNavigate(){
  //window.location.href =  this.sharedService.ISR_URL+ "Default.aspx";
  this.router.navigate(['/home']);  
}

}

import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
// import 'rxjs/add/operators/map';
// import 'rxjs/add/operators/catch';
import {SharedService} from '../Shared/shared.service';
import {RequestModel, TemplateModel} from '../model/request.model';
import {sampleReq} from '../model/request.model';
import { map } from 'rxjs/operators';

/*interface FileMetadata {
  created: string;
  modified: string;
  size: number;
}*/

@Injectable({
  providedIn: 'root'
})
export class SendRequestViewService {

  constructor(private _http: HttpClient,private sharedService:SharedService) {}
  GetTemplate(req:RequestModel):Observable<any>{      
    return this._http.post<any[]>(this.sharedService.API_URL + '/api/ISR/sendRequest/PostTemplate/' , req);
  };
   //formData: FormData = new FormData();  
 

   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    })}
  //AddRequest(req:RequestModel,formData):Observable<any>{     
    AddRequest(templatefile,formData):Observable<any>{     
    //const formData: FormData = new FormData();  
    const HttpUploadOptions = {
        headers: new HttpHeaders({ "Accept": "application/json" })
      }
    //formData.append('templatefile',req.templateFile);
    return this._http.post<any[]>(this.sharedService.API_URL + '/api/ISR/request/PostRequestData/', formData,HttpUploadOptions);
  };

  /*getFileModifiedDate(url: string): Observable<string | null> {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this._http.head(url, { headers, observe: 'response' }).pipe(
      map(response => {
        // Extract the 'Last-Modified' header value
        return response.headers.get('Last-Modified') || null;
      })
    );
  }*/

  /*getFileMetadata(apiUrl): Observable<FileMetadata> {
    return this._http.get<FileMetadata>(apiUrl);
  }*/

    GetUrlData(templatePath: TemplateModel):Observable<any>{     
      return this._http.post<any[]>(this.sharedService.API_URL + '/api/ISR/request/GetUrlData/', templatePath); 
      //return this._http.get<any[]>(this.sharedService.API_URL + '/api/ISR/request/GetUrlData/'+ 'pp');
    };
 
}

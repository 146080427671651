
<!--<form novalidate [formGroup]="sendRequestForm">-->
    
    <div class="row isr" >
            <div class="col-lg-6">
            <div class="card card-primary">
                <div class="card-header">
                    <div class="card-title">Request Long Description</div>                    
                </div>
            </div>
            <div class="card card-primary">
                            <div class="card-block"> 
                                <div class="row">
                                    <div class="col-lg-3">
                                        <label>Long Description:</label>
                                    </div>  
                                    <div class="col-lg-9">
                                        <textarea id="longDesc"  rows="15" cols="85"></textarea>
                                    </div>                             
                                </div>   
                            </div>
                        </div>                       
                        <div class="card card-primary">
                            <div class="card-block"> 
                                <div class="row">
                                    <div class="col-lg-6">
                                        <button type="submit" class="button-design" (click)="GoToPreviousView()">Previous</button>
                                    </div>
                                    <div class="col-lg-6" style="text-align: right;" >
                                        <button type="submit" class="button-design" (click)="GoToNextView()" >Next</button>
                                    </div>
                                </div>                              
                            </div>                       
                            </div>
            </div>    
    </div>
   
   <!-- </form> -->
<div class="row rh-main">
    <div class="col-lg-12">
        <div class="panel panel-default">
            <div class="panel-heading">Request <label>{{requestData.requestNumberFull}}:
                    {{requestData.requestDescription}}</label></div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-lg-4">
                        <label class="ebLabel">
                            Requested by:
                        </label>
                        <label class="requestLabel"> {{requestData.requestorName}}</label>
                    </div>
                    <div class="col-lg-5">
                        <label class="ebLabel">
                            Request status: <!--<label class="labelWidth"></label>-->
                        </label>
                        <!--Vembu -start ISR-2103 - RequestOverview: enhancement on status-->
                        <label class="StatusLabel" style="margin-left: 2px;border-style: solid;"
                            [ngStyle]="{'color':statusFontColor,'border-color':statusBgColor, 'backgroundColor':statusBgColor}">{{requestData.overallStatus}}</label>
                           
                            <label *ngIf="requestData.workflowActive !=''"  class="StatusLabel" style=" margin-left: 2px; background-color: white; border-style: solid; color: black;"
                                [ngStyle]="{'border-color':statusBgColor}" title="{{requestData.wfa_hover}}" >{{requestData.workflowActive}}</label>
                                <!--End ISR-2103-->

                        <!--<label *ngIf="requestData.workflowActive !=''" class="StatusLabel"
                            style=" margin-left: 2px; background-color: white; border-style: solid; color: black;"
                            [ngStyle]="{'border-color':statusBgColor}">{{requestData.workflowActive}}</label>-->
                        <!--End ISR-2103-->
                    </div>
                    <div class="col-lg-3" *ngIf="requestData.fullName !=''">
                        <label class="ebLabel">
                            Handling Engineer: <label class="labelWidth"></label>
                        </label>
                        <label class="requestLabel"> {{requestData.fullName}}</label>
                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <label class="labelOn"></label><label class="ebLabel">
                            on:
                        </label>
                        <label class="requestLabel"> {{requestData.requestDate | customDateFormatPipe }}</label>
                    </div>
                    <div class="col-lg-6">
                        <label class="ebLabel">
                            Requested delivery:
                        </label>
                        <label class="requestLabel">{{requestData.requestedDelivery | customDateFormatPipe }}</label>


                    </div>
                    <div class="col-lg-2">
                        <button id="btnRequest" type="submit" name="button" (click)="gotoISROverview()"
                            class="ebBtn ebBtn_color_darkGreen" style="margin-top:3px;min-width:100px;">Return to
                            Overview
                            <i class="ebIcon ebIcon_quickSearch"></i>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-9"></div>
                    <div class="col-lg-1" *ngIf="this.showButton_OpenChangeInRH==true"></div>
                    <div class="col-lg-2" *ngIf="this.showButton_OpenChangeInRH==true">
                        <button type="submit" name="button" (click)="OpenInRH()" class="ebBtn ebBtn_color_darkGreen"
                            style="margin-top:3px;min-width:100px;">Open in RH
                            <i class="ebIcon ebIcon_upArrow"></i>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="statusImage">
                            <div *ngFor="let config of fixedStatusImageConfig">
                                <div *ngIf="config.text!='Task'" id="divPointer"
                                    [ngStyle]="config.opaq==true?{'color': config.opacityColor}:{'color': config.color}">
                                    <label [ngClass]="config.class"
                                        [ngStyle]="config.opaq==true?{'opacity': '15%','font-weight': 'normal'}:{'opacity': '100%'}">{{config.text}}</label>
                                    <!-- <label [ngClass]="config.class" [ngStyle]="config.opaq==true?{'font-weight': 'normal'}:{'font-weight': 'bold'}">{{config.text}}</label> -->
                                </div>
                                <div *ngIf="config.text=='Task'">
                                    <div *ngFor="let config of taskStatusImageConfig">
                                        <!-- <div id="divPointer" (mouseover)="changeText=true" (mouseout)="changeText=false" *ngIf="config.status !=512" [ngStyle]="config.status==256||config.status==128||config.status==4096?{'color': config.color}:(config.status==64?{'color':'#A8A828'}:{'color': config.opacityColor})"> -->
                                        <div id="divPointer" [tooltip]="HtmlContent" [options]="tooltipOptions"
                                            contentType="template" *ngIf="config.status !=512"
                                            [ngStyle]="config.status==256||config.status==128||config.status==4096?{'color': config.color}:(config.status==64||config.status==4||config.status==16||config.status==32?{'color':'#A8A828'}:{'color': config.opacityColor})">
                                            <label style="width: 100%;" [ngClass]="config.class"
                                                [ngStyle]="config.status==256||config.status==128||config.status==4096?{'opacity': '100%'}:(config.status==64||config.status==4||config.status==16||config.status==32?{'opacity': '100%','font-weight': 'normal'}:{'opacity': '15%','font-weight': 'normal'})">{{config.text}}</label>
                                            <!-- <label [ngClass]="config.class" [ngStyle]="config.status==256||config.status==128||config.status==4096?{'font-weight': 'bold'}:{'opacity': 'normal'}">{{config.text}}</label> -->
                                            <label style="margin-left:50%;"
                                                *ngIf="config.taskSupplierStatus && config.taskSupplierStatus!=512"
                                                contentType="template" [tooltip]="squareImageTooltip"
                                                class="statusImageSquare"
                                                [ngStyle]="{'backgroundColor':config.backgroundColor_sup}"></label>

                                        </div>
                                        <ng-template #squareImageTooltip>
                                            <div class="ebLabel"
                                                style="text-align: left;background-color: #ffff;margin: -3px;padding: 2px;">
                                                Supplier: {{config.supplierName}} <br>
                                                Status: <label class="StatusLabel"
                                                    [ngStyle]="{'color':config.fontColor_sup, 'backgroundColor':config.backgroundColor_sup}">{{config.strTaskSupplierStatus}}</label>
                                            </div>
                                        </ng-template>
                                        <ng-template #HtmlContent>
                                            <div class="ebLabel"
                                                style="text-align: left;background-color: #ffff;margin: -3px;padding: 2px;">
                                                {{config.taskName}}<br>Status:
                                                <label class="StatusLabel"
                                                    [ngStyle]="{'color':config.fontColor, 'backgroundColor':config.backgroundColor}">{{config.strStatus}}</label><br><br>
                                                <span
                                                    *ngIf="config.taskSupplierStatus && config.taskSupplierStatus!=512">
                                                    Including supplier status:<br>
                                                    {{config.supplierName}}: <label class="StatusLabel"
                                                        [ngStyle]="{'color':config.fontColor_sup, 'backgroundColor':config.backgroundColor_sup}">{{config.strTaskSupplierStatus}}</label><br>

                                                </span>
                                                <span *ngIf="config.taskDescription">
                                                    <hr>
                                                    Task description: {{config.taskDescription}}
                                                </span>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-lg-12">     
                        <div class="statusImage">                                                
                            <div *ngFor="let config of taskStatusImageConfig">
                                <div id="divPointer" *ngIf="config.status !=512" [ngStyle]="config.status==256||config.status==128||config.status==4096?{'color': config.color}:{'color': config.opacityColor}">
                                    <label [ngClass]="config.class" [ngStyle]="config.status==256||config.status==128||config.status==4096?{'opacity': '100%'}:{'opacity': '15%'}">{{config.text}}</label>
                                </div>                               
                            </div>                          
                        </div>
                    </div>
                </div> -->


            </div>
        </div>
    </div>
</div>

<div *ngIf="isStatusInfoShow" class="row rh-request">
    <div class="col-lg-12">
        <div class="panel panel-default">
            <div class="panel-heading">Supplier Status</div>
            <div class="panel-body">
                <div class="row rh-row">
                    <!-- Anjan - RH-2121 -  Information is not visible in ISR Supplier Status panel: -->
                    <!-- <div class="col-lg-7"> -->
                    <div class="col-lg-12">
                        <app-status-change (statusVal)="getStatus($event)"
                            [requestNo]="requestNumberFull"></app-status-change>

                    </div>
                    <!-- Anjan - RH-2121 -  Information is not visible in ISR Supplier Status panel: -->
                    <!-- <div class="col-lg-5"
                        *ngIf="supplierRemarks !='' && supplierRemarks !=null && supplierRemarks !=undefined">
                        <label class="ebLabel">Supplier Remarks:</label>
                        <textarea [ngModel]="supplierRemarks" rows="4"
                            class="ebTextArea  ebTextArea_labeled_top ebTextArea_width_full requestViewMode"
                            readonly></textarea>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <form novalidate [formGroup]="requestDetailForm"> -->
<div class="row rh-request">
    <div class="col-lg-7">
        <div class="panel panel-default">
            <div class="panel-heading">Request Info</div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-lg-3"><label class="ebLabel">Type of Request</label></div>
                    <div class="col-lg-9 inputdiv">
                        <input [ngModel]="requestData.typeOfRequest" type="text"
                            class="ebInput ebInput_labeled_top requestViewMode" style="width:262px;" readonly />
                        <input [ngModel]="requestData.parentType" type="text"
                            class="ebInput ebInput_labeled_top requestViewMode" style="width:250px;" readonly />
                    </div>
                </div>
                <div class="row" *ngIf="requestData.location !='N.a.'">
                    <div class="col-lg-3"><label class="ebLabel">Location</label></div>
                    <div class="col-lg-9 inputdiv">
                        <input [ngModel]="requestData.location" style="width:512px;" type="text"
                            class="ebInput ebInput_labeled_top requestViewMode" readonly />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3"><label class="ebLabel">Approver</label></div>
                    <div class="col-lg-3 inputdiv"><input [ngModel]="requestData.managerToApprove" style="width:230px;"
                            id="managerToApprove" type="text" class="ebInput  ebInput_labeled_top requestViewMode"
                            readonly /></div>
                    <!-- <div class="col-lg-4" ng-show="vm.showHasApproved" style="width:30%;margin-left:35px;margin-right:-35px;"> -->
                    <div class="col-lg-3 inputdiv" *ngIf="showHasApproved" style="width:30%;margin-left:58px;">
                        <label class="ebLabel">Has approved </label>
                        <input [ngModel]="requestData.hasApproved" id="hasApproved" style="width:35px;" type="text"
                            class="ebInput  ebInput_labeled_top requestDatetxt requestViewMode" readonly />
                    </div>

                    <!-- <div class="col-lg-2" ng-show="vm.showHasApproved" style="width:20%;">    -->
                    <div class="col-lg-2 inputdiv" *ngIf="showHasApproved">
                        <label class="ebLabel">On</label>
                        <span *ngIf="requestData.hasApprovedOn != ''">
                            <input [ngModel]="requestData.hasApprovedOn | date:'yyyy-MM-dd'" style="font-weight: normal"
                                id="hasApprovedOn" type="text"
                                class="ebInput  ebInput_labeled_top requestDatetxt requestViewMode" readonly />
                        </span>
                        <span *ngIf="requestData.hasApprovedOn == ''">
                            <input [ngModel]="requestData.hasApprovedOn | date:'yyyy-MM-dd'" style="font-weight: normal"
                                id="hasApprovedOn" type="text"
                                class="ebInput  ebInput_labeled_top requestDatetxt requestViewMode" readonly />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-5">
        <div class="panel panel-default">
            <div class="panel-heading">Request Long Description</div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-lg-12">
                        <textarea [ngModel]="requestData.longDescription" rows="4"
                            class="ebTextArea  ebTextArea_labeled_top ebTextArea_width_full requestViewMode"
                            readonly></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row rh-request rh-request-1">
    <div class="col-lg-7">
        <div class="panel panel-default">
            <div class="panel-heading">Request Additional Info</div>
            <div class="panel-body">
                <div class="row" style="padding: 5px 0;" *ngIf="IsCLRequest == false">
                    <div class="col-lg-3"><label class="ebLabel">Request file</label></div>
                    <div class="col-lg-5 inputdiv">
                        <span *ngIf="reqFileName != ''">
                            <a (click)="showRequestFile()" class="reqFileLink">
                                {{reqFileName}}
                            </a>
                        </span>
                    </div>
                    <div class="col-lg-4 inputdiv">
                        <span *ngIf="isFirewallRules == true">
                            <a (click)="showFirewallRule()" class="reqFileLink">
                                Firewall rules by wizzard
                            </a>
                        </span>
                    </div>
                </div>
                <div class="row" *ngIf="IsCLRequest == false">
                    <div class="col-lg-3"><label class="ebLabel">Additional Info</label></div>
                    <div class="col-lg-9 inputdiv">
                        <textarea [ngModel]="requestData.addInfo" rows="5"
                            class="ebTextArea  ebTextArea_labeled_top ebTextArea_width_full requestViewMode"
                            readonly></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-5">
        <div class="panel panel-default">
            <div class="panel-heading">Request Remarks</div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-lg-12">
                        <textarea [ngModel]="requestData.requestRemarks" rows="6"
                            class="ebTextArea  ebTextArea_labeled_top ebTextArea_width_full requestViewMode"
                            readonly></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row rh-request">
    <div class="col-lg-4">
        <div class="panel panel-default">
            <div class="panel-heading">Related Project</div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-lg-5"><label class="ebLabel">Rel. Project Id</label></div>
                    <div class="col-lg-7"><input [ngModel]="requestData.projectId" type="text"
                            class="ebInput  ebInput_labeled_top ebInput_width_full requestViewMode" readonly />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5"><label class="ebLabel">Rel. Project Name</label></div>
                    <div class="col-lg-7"><input [ngModel]="requestData.projectName" type="text"
                            class="ebInput  ebInput_labeled_top ebInput_width_full requestViewMode" readonly />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5"><label class="ebLabel">Budget in Rel. Project Scope?</label></div>
                    <div class="col-lg-7"><input [ngModel]="requestData.ericssonProject" type="text"
                            class="ebInput  ebInput_labeled_top ebInput_width_full requestViewMode" readonly />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-4">
        <div class="panel panel-default">
            <div class="panel-heading">Budget</div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-lg-6"><label class="ebLabel">Customer Budget Owner</label></div>
                    <div class="col-lg-6"><input [ngModel]="requestData.custBudgetOwner" type="text"
                            class="ebInput  ebInput_labeled_top ebInput_width_full requestViewMode" readonly />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6"><label class="ebLabel">Customer Budget Code</label></div>
                    <div class="col-lg-6"><input [ngModel]="requestData.custBudgetCode" type="text"
                            class="ebInput  ebInput_labeled_top ebInput_width_full requestViewMode" readonly />
                    </div>
                </div>
                <!-- <div class="row">
                                 <div class="col-lg-6"><label class="ebLabel">Cats Code</label></div>
                                 <div class="col-lg-6"><input formControlName="catsCode"  [ngModel]="requestData.catsCode" type="text" class="ebInput  ebInput_labeled_top ebInput_width_full requestViewMode" readonly /></div>                       
                         </div> -->
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="panel panel-default">
            <div class="panel-heading">Operational Support of the systems</div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-lg-4"><label class="ebLabel">Name</label></div>
                    <div class="col-lg-8"><input [ngModel]="requestData.operationalSupSysName" type="text"
                            class="ebInput  ebInput_labeled_top ebInput_width_full requestViewMode" readonly />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4"><label class="ebLabel">Telephone</label></div>
                    <div class="col-lg-8"><input [ngModel]="requestData.operationalSupSysTelephone" type="text"
                            class="ebInput  ebInput_labeled_top ebInput_width_full requestViewMode" readonly />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4"><label class="ebLabel">Email</label></div>
                    <div class="col-lg-8"><input [ngModel]="requestData.operationalSupSysEmail" type="text"
                            class="ebInput  ebInput_labeled_top ebInput_width_full requestViewMode" readonly />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row rh-request">
    <div class="col-lg-12">
        <div class="panel panel-default">
            <div class="panel-heading">Log</div>
            <div class="panel-body">
                <div>
                    <ag-grid-angular class="ag-theme-balham isr-log-grid" [rowData]="requestLogData"
                        [columnDefs]="columnDefsLog" [modules]="modules" (gridReady)="onGridReadyLog($event)"
                        [animateRows]="true" [gridOptions]="gridOptionsLog">
                    </ag-grid-angular>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- </form> -->

<!-- ------------------------------------------------------------------------------------ -->

<div *ngIf="changeRequestShowPerm" id="chReqInfo">
    <div class="row rh-request" [style.display]="showRelatedChangeDiv ? 'block' : 'none'">
        <div class="col-lg-12">
            <div class="panel panel-default">
                <div class="panel-heading">
                    Related Changes
                </div>

                <div class="panel-body">
                    <div class="row">
                        <div class="col-lg-12">

                            <ag-grid-angular class="ag-theme-balham relatedChanges-grid" [rowData]="relatedChangeData"
                                [columnDefs]="columnDefs" [modules]="modules" (gridReady)="onGridReady($event)"
                                [animateRows]="true" [gridOptions]="gridOptions"
                                (selectionChanged)="onSelectionChanged()" (rowDataChanged)="onRowDataChanged()">
                            </ag-grid-angular>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <form novalidate [formGroup]="changeDetailForm">
        <div *ngIf="IsCLRequest" class="row rh-request">
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading chNoTitle">Change {{ selectedChange }} &nbsp;
                        <span style="padding-left: 10px;"><a (click)="copyChangeLink(selectedChange)"
                                style="cursor: pointer;">
                                <img src="./assets/Images/copy-link-icon.png" style="width: 15px;">
                            </a></span>
                    </div>
                    <div class="panel-body">
                        <div class="row rh-row">
                            <div class="col-lg-7">
                                <div id="statusBox">

                                    <div class="col-lg-7">
                                        <label class="ebLabel">Change Status</label>
                                        <input type="text" id="txtStatus" [ngModel]="changeLogStatusDesc"
                                            [ngStyle]="{'color':chStatusFontColor, 'backgroundColor':chStatusBgColor}"
                                            class="StatusLabel" readonly>

                                    </div>

                                </div>

                            </div>
                        </div>
                        <div class="row rh-row" style="margin-top: 10px;">
                            <div class="col-lg-7">
                                <div class="panel panel-default">
                                    <div class="panel-heading">Change Info</div>

                                    <div class="panel-body">
                                        <!--<div class="row">                        
                                            <div class="col-lg-2"><label class="ebLabel">Category</label></div>
                                            <div class="col-lg-5">
                                                <input [ngModel]="changeData.categoryName" formControlName="categoryName" type="text" class="ebInput ebInput_labeled_top requestViewMode" style="width:270px;" readonly />
                                            </div>
                                            <div class="col-lg-1"><label class="ebLabel">Version</label></div>
                                            <div class="col-lg-4">
                                                <input [ngModel]="changeData.version" formControlName="version" type="text" class="ebInput ebInput_labeled_top requestDatetxt requestViewMode" readonly />
                                            </div>                        
                                    </div> -->
                                        <!--
                                    <div class="row">
                                        <div class="col-lg-2"><label class="ebLabel">Prepared By</label></div>
                                        <div class="col-lg-5">
                                            <input [ngModel]="changeData.preparedBy" formControlName="preparedBy" type="text" class="ebInput ebInput_labeled_top requestViewMode" style="width:270px;" readonly />
                                        </div>
                                        <div class="col-lg-1"><label class="ebLabel">On</label></div>
                                        <div class="col-lg-4">
                                            <input type="text" [ngModel]="changeData.preparedOn" formControlName="preparedOn" class="ebInput ebInput_labeled_top requestDatetxt requestViewMode" readonly />
                                        </div>                       
                                    </div> 
                                    
                                    <div class="row">
                                        <div class="col-lg-2"><label class="ebLabel">Checked By</label></div>
                                        <div class="col-lg-5">
                                            <input [ngModel]="changeData.checkedBy" formControlName="checkedBy" type="text" class="ebInput ebInput_labeled_top requestViewMode" style="width:270px;" readonly />
                                        </div>
                                        <div class="col-lg-1"><label class="ebLabel">On</label></div>
                                        <div class="col-lg-4">
                                            <input type="text" [ngModel]="changeData.checkedOn" formControlName="checkedOn" class="ebInput ebInput_labeled_top requestDatetxt requestViewMode" readonly />
                                        </div>                       
                                    </div>
                                    -->
                                        <div class="row">
                                            <div class="col-lg-2"><label class="ebLabel">Executed By</label></div>
                                            <div class="col-lg-5">
                                                <input [ngModel]="changeData.executedBy" type="text"
                                                    class="ebInput ebInput_labeled_top requestViewMode"
                                                    style="width:270px;" readonly />
                                            </div>
                                            <div class="col-lg-1"><label class="ebLabel">On</label></div>
                                            <div class="col-lg-4">
                                                <input type="text" [ngModel]="changeData.executionOn"
                                                    class="ebInput ebInput_labeled_top requestDatetxt requestViewMode"
                                                    readonly />
                                                &nbsp;&nbsp;&nbsp;<input [ngModel]="changeData.executionTime"
                                                    type="text" class="ebInput ebInput_labeled_top requestViewMode"
                                                    style="width:60px;" readonly />
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-lg-2"><label class="ebLabel">Node(s)</label></div>
                                            <div class="col-lg-5">
                                                <input [ngModel]="changeData.nodes" type="text"
                                                    class="ebInput ebInput_labeled_top requestViewMode"
                                                    style="width:270px;" readonly />
                                            </div>
                                            <div class="col-lg-1"><label class="ebLabel">Version</label></div>
                                            <div class="col-lg-4">
                                                <input [ngModel]="changeData.version" type="text"
                                                    class="ebInput ebInput_labeled_top requestDatetxt requestViewMode"
                                                    readonly />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5" style="max-width: 41%;">
                                <div class="panel panel-default">
                                    <div class="panel-heading">Change Result</div>
                                    <div class="panel-body">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <textarea [ngModel]="changeData.changeResult" rows="6"
                                                    class="ebTextArea  ebTextArea_labeled_top ebTextArea_width_full requestViewMode"
                                                    readonly></textarea>
                                            </div>
                                        </div>
                                        <div class="row" style="height: 2px;">
                                            <div class="col-lg-12">&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>
        <!--
<div *ngIf="IsCLRequest" class="row rh-request rh-request-1">
    
    
    
</div>
-->

    </form>

    <div class="row rh-request" *ngIf="showFilesDiv">
        <div class="col-lg-12">
            <div class="panel panel-default">
                <div class="panel-heading">
                    Attachments of all related changes
                </div>

                <div class="panel-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <table class="table table-striped table-bordered">

                                <tbody>
                                    <tr *ngFor="let files of changeLogFileList">
                                        <td>
                                            <a (click)="getChangeFile(files)" class="fileLink">
                                                {{files.fileName}}
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>
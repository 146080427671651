import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { RequestDetailService } from '../request-details/request-details.service';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { GridRequestFileComponent } from '../grid-request-file/grid-request-file.component';
import { NgxSpinnerService } from "ngx-spinner";
import { AgGridAngular } from '@ag-grid-community/angular';
import { ActivatedRoute } from '@angular/router';
import { customDateFormatPipe } from '../customDateFormatPipe';
import { RequestOverviewService } from '../request-overview/request-overview.service';
import { SharedService } from '../Shared/shared.service';
import { CookieService } from 'ngx-cookie-service';
import { GlobalParamsService } from '../global/globalParams.service'
//import { analyzeAndValidateNgModules } from '@angular/compiler';
import { DatePipe } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import * as moment from 'moment';
import { userModel } from '../model/user.model';
import { headerService } from '../core/header/header.service';
import { MenuService } from '../global/menu.service';

import { FirewallrulesComponent } from '../request-details/firewallrules.component';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.css'],
  providers: [userModel]
})
export class RequestDetailsComponent implements OnInit {

  public gridOptions: AgGridAngular;
  modules = AllCommunityModules;
  fixedStatusImageConfig: any = [];
  taskStatusImageConfig: any = [];

  columnDefs: any = [];
  private rowSelection;
  private gridApi;
  private gridColumnApi;

  public gridOptionsLog: AgGridAngular
  columnDefsLog: any = [];
  private gridApiLog;
  private gridColumnApiLog;

  requestDetailForm: UntypedFormGroup;
  requestLogData: any = [];

  requestData: any = [];
  datePipe = new DatePipe("en-US");
  requestNo = '';
  requestNumber = '';
  requestNumberFull = '';
  showDate = new Date();
  statusFontColor = '#2f75b5';
  statusBgColor = '#fff';
  statusColors: any = [];
  reqFileName = "";
  reqType: string = '';
  isStatusInfoShow: Boolean = false;
  ISTCookieValue: string;
  loggedUserName: string;
  IsCLRequest: Boolean = false;
  changeData: any = [];
  changeDetailForm: UntypedFormGroup;
  changeLogFileList: any[];
  relatedChangeData: any = [];
  showFilesDiv: Boolean = false;
  showRelatedChangeDiv: Boolean = false;
  selectedChange: any;
  statusVal: String = '';
  o_supplierRemarks: String = '';
  changeThread: any;
  chRequestStatus: String = '';

  changeLogStatus: any;
  changeLogStatusDesc: String = '';
  chStatusFontColor = '#2f75b5';
  chStatusBgColor = '#fff';
  isContinue = false;
  showHasApproved = false;

  viewChangeReqPerm: any;
  editChangeReqPerm: any;
  userPermission: any;
  showButton_OpenChangeInRH: any;
  changeRequestShowPerm: Boolean = true;
  showCompletedStatus: any;
  completedColor: any;
  completedOpaqueColor: any;

  isFirewallRules = false;
  firewallRulesList: any[];
  formData: FormData;
  supplierRemarks: any;
  isChecklistPresent = false;
  tooltipOptions;
  constructor(private requestDetailSvc: RequestDetailService, private router: Router, private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, private requestOverviewSvc: RequestOverviewService, private sharedService: SharedService, private cookieService: CookieService, private globalParamsService: GlobalParamsService, private objUserModel: userModel, public dialog: MatDialog, private menuService: MenuService) {
  }

  ngOnInit() {
    this.tooltipOptions = {
      'max-width': '400px',
      'placement': 'bottom'
    }
    this.requestDetailSvc.GetStatusColors().subscribe(data => {
      this.statusColors = data.table;
      this.isContinue = true;
    });

    this.requestDetailForm = this.formBuilder.group({
      typeOfRequest: new UntypedFormControl(),
      parentType: new UntypedFormControl(),
      addInfo: new UntypedFormControl(),
      longDesc: new UntypedFormControl(),
      location: new UntypedFormControl(),
      managerToApprove: new UntypedFormControl(),
      hasApproved: new UntypedFormControl(),
      hasApprovedOn: new UntypedFormControl(),
      requestRemarks: new UntypedFormControl(),
      projectId: new UntypedFormControl(),
      projectName: new UntypedFormControl(),
      ericssonProject: new UntypedFormControl(),
      custBudgetOwner: new UntypedFormControl(),
      custBudgetCode: new UntypedFormControl(),
      catsCode: new UntypedFormControl(),
      operationalSupSysName: new UntypedFormControl(),
      operationalSupSysTelephone: new UntypedFormControl(),
      operationalSupSysEmail: new UntypedFormControl()
    });

    this.changeDetailForm = this.formBuilder.group({
      requestorName: new UntypedFormControl(),
      version: new UntypedFormControl(),
      requestDescription: new UntypedFormControl(),
      longDescription: new UntypedFormControl(),
      preparedBy: new UntypedFormControl(),
      preparedOn: new UntypedFormControl(),
      executedBy: new UntypedFormControl(),
      executionOn: new UntypedFormControl(),
      executionTime: new UntypedFormControl(),
      checkedBy: new UntypedFormControl(),
      checkedOn: new UntypedFormControl(),
      //categoryName:  new FormControl(),
      changeLogStatusDescControl: new UntypedFormControl(),
      nodes: new UntypedFormControl(),
      changeResult: new UntypedFormControl()
    });

    this.route.params.subscribe(params => {
      console.log(params['requestNo']);
      this.requestNo = params['requestNo'];
      if (this.requestNo.indexOf('.') != -1) {
        this.requestNumber = this.requestNo.substring(5, this.requestNo.length);
        this.reqType = this.requestNo.substring(0, 4);
        this.requestNumberFull = this.requestNo;

        this.globalParamsService._requestNumber.next(this.requestNumberFull);

        if (this.reqType == "9801") {
          this.IsCLRequest = true;
        }
        else this.IsCLRequest = false;
      }
      else {
        this.requestNumber = this.requestNo;
      }

      this.ISTCookieValue = this.cookieService.get('ISTCookie2');

      this.loggedUserName = this.ISTCookieValue.substring(this.ISTCookieValue.indexOf("username"), this.ISTCookieValue.indexOf("&")).replace("username=", '');

      if (this.loggedUserName != '') {
        this.objUserModel.email = this.loggedUserName;
        this.sharedService.GetUserByEmail(this.objUserModel).subscribe(data => {
          this.objUserModel = data.table[0];
          //this.userFullName=this.objUserModel.fullName;          
        });
      } else {
        //this.userFullName="";
      }

      this.sharedService.getUserPermission(this.objUserModel).subscribe(data => {
        var _userPerm = data;
        this.userPermission = JSON.stringify(_userPerm);

        this.viewChangeReqPerm = this.chkUserPerm(33);
        this.editChangeReqPerm = this.chkUserPerm(34);
        this.showButton_OpenChangeInRH = this.chkUserPerm(42);

        if (!this.viewChangeReqPerm && !this.viewChangeReqPerm) {
          this.changeRequestShowPerm = false;
        }

        setTimeout(function () {
          var cells = document.querySelectorAll('#chReqInfo');
          for (var i = 0; i < cells.length; i++) {
            cells[i].setAttribute('display', 'none');
          }
        }, 200);
      });

      this.requestDetailSvc.GetISRRequestDetail(this.requestNumber).subscribe(data => {
        if (data == "") {
          //alert("No Request Present.");
        }
        else {
          this.requestData = data;
          var statVal = this.requestData.overallStatus;
          this.reqType = this.requestData.requestNumberFull.substring(0, 4);
          this.requestNumberFull = this.requestData.requestNumberFull;
          this.isChecklistPresent = this.requestData.isChecklistPresent;

          this.menuService._isChecklistPresent.next(this.isChecklistPresent);

          this.requestDetailSvc.GetIpsFirewall(this.requestNumber, false).subscribe(firewalldata => {
            console.log(firewalldata);
            this.firewallRulesList = firewalldata.lstFirewallRfcList;
            if (firewalldata == "") {
              //alert("No Request Present.");
            }
            if (firewalldata.lstFirewallRfcList.length > 0) {
              if (firewalldata.lstFirewallRfcList[0].remarks == "Request by file") {
                this.isFirewallRules = false;
              }
              else if (this.checkFirewallRulesList(firewalldata.lstFirewallRfcList) == false) {
                this.isFirewallRules = false;
              }
              else {
                this.isFirewallRules = true;
              }

            }
            else this.isFirewallRules = false;

          });

          if (this.requestData.overallStatusId == 128 || this.requestData.overallStatusId == 256) {
            this.showCompletedStatus = this.requestData.overallStatus;
            if (this.requestData.overallStatusId == 128) {
              this.completedColor = '#99EDC3';
              this.completedOpaqueColor = '#DFF0D8';
            } else {
              this.completedColor = '#64fa64';
              this.completedOpaqueColor = '#B2FDB2';
            }
          } else {
            this.showCompletedStatus = "Completed";
            this.completedColor = '#64fa64';
            this.completedOpaqueColor = '#B2FDB2';
          }

          this.fixedStatusImageConfig = [
            { id: 1, text: 'Creation', color: '#F1ca94', class: 'StatusImageLabel', opacityColor: '#F8E5CA', opaq: true },
            { id: 2, text: 'Approve Requestor', color: '#F3ac4e', class: 'StatusImageLabelDoubleLine', opacityColor: '#F9D6A7', opaq: true },
            { id: 3, text: 'Request Approval', color: '#F57700', class: 'StatusImageLabelDoubleLine', opacityColor: '#FABB80', opaq: true },
            { id: 4, text: 'Task', color: '#8ad0f3', class: 'StatusImageLabel', opacityColor: '#E2F3FC', opaq: true },
            //{id:5,text: this.showCompletedStatus,color:'#3F9F09',class:'StatusImageLabel',opacityColor:'#9FCF84', opaq:true}]
            { id: 5, text: this.showCompletedStatus, color: this.completedColor, class: 'StatusImageLabel', opacityColor: this.completedOpaqueColor, opaq: true }]


          if (this.requestData.overallStatusId > 0) {
            this.fixedStatusImageConfig[0].opaq = false;
          }
          if (this.requestData.overallStatusId > 1) {
            this.fixedStatusImageConfig[1].opaq = false;
          }
          if (this.requestData.overallStatusId > 2) {
            this.fixedStatusImageConfig[2].opaq = false;
          }
          if (this.requestData.overallStatusId >= 128) {
            this.fixedStatusImageConfig[3].opaq = false;
            this.fixedStatusImageConfig[4].opaq = false;
          }
          if (this.requestNumberFull != undefined && this.requestNumberFull != '' && this.requestNumberFull != null) {
            this.formData = new FormData();
            this.formData.append('formData', JSON.stringify(this.requestNumberFull));
            this.requestDetailSvc.GetStatusBarData(this.formData).subscribe(data => {
              if (data.table.length > 0) {
                this.taskStatusImageConfig = data.table;
              }
            })
          }
          // if(this.reqType=='0902'){
          //   this.taskStatusImageConfig=[

          //     {text:'IPI-SW',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false, status:this.requestData.statusIpSwp},
          //     {text:'IPI-IP',color:'#39B7CD',class:'StatusImageLabel',opacityColor:'#C4EAF0', opaq:false, status:this.requestData.statusIP},
          //     {text:'TR-POS',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false, status:this.requestData.statusTRPositions},
          //     {text:'TR-CAB',color:'#39B7CD',class:'StatusImageLabel',opacityColor:'#C4EAF0', opaq:false, status:this.requestData.statusTR},
          //     {text:'TR-SUP',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false, status:this.requestData.statusSup},
          //     // {text:'TR-SUPP',color:'#39B7CD',class:'StatusImageLabel',opacityColor:'#C4EAF0', opaq:false, status:this.requestData.statusTrs},
          //     {text:'PW-PLAN',color:'#39B7CD',class:'StatusImageLabel',opacityColor:'#C4EAF0', opaq:false, status:this.requestData.statusPw},
          //     {text:'PW-BUILD',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false, status:this.requestData.statusPwSup},
          //     {text:'PW-DEL',color:'#39B7CD',class:'StatusImageLabel',opacityColor:'#C4EAF0', opaq:false, status:this.requestData.statusAct},
          //     {text:'FW',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false,status:this.requestData.statusFw},
          //     {text:'DNS',color:'#39B7CD',class:'StatusImageLabel',opacityColor:'#C4EAF0', opaq:false,status:this.requestData.statusFwDns},
          //     {text:'LBR',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false,status:this.requestData.statusFwLbr},
          //     {text:'ANY-SYS',color:'#39B7CD',class:'StatusImageLabel',opacityColor:'#C4EAF0', opaq:false, status:this.requestData.statusAny},
          //     {text:'ANY-DEL',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false, status:this.requestData.statusAnySys},
          //     {text:'ANY-CC',color:'#39B7CD',class:'StatusImageLabel',opacityColor:'#C4EAF0', opaq:false, status:this.requestData.statusAnyCcf}
          //   ]
          // }else   if(this.reqType=='0101' || this.reqType=='0201' || this.reqType=='0302' ){
          //   this.taskStatusImageConfig=[
          //     {text:'IPI-SW',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false, status:this.requestData.statusIpSwp},
          //     {text:'IPI-IP',color:'#39B7CD',class:'StatusImageLabel',opacityColor:'#C4EAF0', opaq:false, status:this.requestData.statusIP},
          //     {text:'TR-POS',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false, status:this.requestData.statusTRPositions},
          //     {text:'TR-CAB',color:'#39B7CD',class:'StatusImageLabel',opacityColor:'#C4EAF0', opaq:false, status:this.requestData.statusTR},
          //     {text:'TR-SUP',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false, status:this.requestData.statusSup},
          //     // {text:'TR-SUPP',color:'#F57700',class:'StatusImageLabel',opacityColor:'#FABB80', opaq:false, status:this.requestData.statusTrs},
          //     {text:'PW-PLAN',color:'#39B7CD',class:'StatusImageLabel',opacityColor:'#C4EAF0', opaq:false, status:this.requestData.statusPw},
          //     {text:'PW-BUILD',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false, status:this.requestData.statusPwSup},
          //     {text:'PW-DEL',color:'#39B7CD',class:'StatusImageLabel',opacityColor:'#C4EAF0', opaq:false, status:this.requestData.statusAct},                
          //     {text:'ANY-SYS',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false, status:this.requestData.statusAny},
          //     {text:'ANY-DEL',color:'#39B7CD',class:'StatusImageLabel',opacityColor:'#C4EAF0', opaq:false, status:this.requestData.statusAnySys},
          //     {text:'ANY-CC',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false, status:this.requestData.statusAnyCcf}
          //   ]
          // } 

          // else if(this.reqType=='2005' ){
          //   this.taskStatusImageConfig=[              
          //     {text:'TR-SUP',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false, status:this.requestData.statusSup},
          //     {text:'TR-SUPP',color:'#39B7CD',class:'StatusImageLabel',opacityColor:'#C4EAF0', opaq:false, status:this.requestData.statusTrs}               
          //   ]
          // }   
          // else if(this.reqType=='0401' ){
          //   this.taskStatusImageConfig=[              
          //     {text:'FWR',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false, status:this.requestData.statusFw}                
          //   ]
          // }  
          // else if(this.reqType=='0501' ){
          //   this.taskStatusImageConfig=[              
          //     {text:'FWR',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false, status:this.requestData.statusFwDns}                
          //   ]
          // }  
          // else if(this.reqType=='1501' ){
          //   this.taskStatusImageConfig=[              
          //     {text:'FWR',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false, status:this.requestData.statusFwLbr}                
          //   ]
          // }
          // else if(this.reqType=='1427' ){
          //   this.taskStatusImageConfig=[              
          //     {text:'FWPC-INT',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false, status:this.requestData.taskStatus1},
          //     {text:'FWPC-BLD',color:'#39B7CD',class:'StatusImageLabel',opacityColor:'#C4EAF0', opaq:false, status:this.requestData.taskStatus2}               
          //   ]
          // }
          // else{
          //   this.taskStatusImageConfig=[              
          //     {text:'Task',color:'#0BB5FF',class:'StatusImageLabel',opacityColor:'#CEF0FF', opaq:false, status:this.requestData.overallStatusId}                
          //   ]
          // }

          this.requestLogData = this.requestData.lstRequestLogs.filter(function (d) {
            return (d.logDescription.toLowerCase().startsWith("status set to") || d.logDescription.toLowerCase().startsWith("email sent to"))
          })
          if (this.requestData.managerToApprove == null || this.requestData.managerToApprove == '') {
            this.requestData.hasApprovedOn = '';
          }
          if (this.requestData.hasApprovedOn == "0001-01-01T00:00:00" || this.requestData.hasApprovedOn == "1900-01-01T00:00:00") {
            this.requestData.hasApprovedOn = '';
          }
          if (this.requestData.hasApproved == 'Not Yet' && this.requestData.managerToApprove == 'N.a.') this.requestData.hasApprovedOn = 'N.a.';

          if (this.requestData.managerToApprove != null) {
            if (this.requestData.managerToApprove.indexOf('N.a.') != -1) {
              this.showHasApproved = false;
            } else {
              this.showHasApproved = true;
            }
          }
          console.log(this.requestLogData);

          if (this.isContinue) {
            this.statusFontColor = this.statusColors.filter(function (stat) {
              return stat.description == statVal;
            })[0].fontColor;

            this.statusBgColor = this.statusColors.filter(function (stat) {
              return stat.description == statVal;
            })[0].backgroundColor;
          }
          //RH-2024 Removal of Cognizant team from distribution list for 1425.
          //  if(this.reqType == "1425") {
          //   this.isStatusInfoShow = true;
          // }
          //RH-2014 end

          //RH-1941
          if (this.requestData.requestNumberFull != undefined && this.requestData.requestNumberFull != '' && this.requestData.requestNumberFull != null) {
            this.formData = new FormData();
            this.formData.append('formData', JSON.stringify(this.requestData.requestNumberFull));
            this.requestDetailSvc.CheckForSupplier(this.formData).subscribe(data => {
              if (data.table.length > 0) {
                if ((data.table[0].taskSupplier != null || data.table[0].taskSupplier != undefined)) {
                  this.isStatusInfoShow = true;
                  this.supplierRemarks = data.table[0].supplierRemarks;
                }
              }
            })
          }

          if (this.reqType == "9801") {
            this.IsCLRequest = true;
            //this.GetChangeLog(this.requestNumber);

          }
          else this.IsCLRequest = false;

          if (this.reqType != "9801") {
            this.requestOverviewSvc.GetISRRequestFile(this.requestData.requestNumberFull.substring(0, 4), this.requestNumber).subscribe(fdata => {
              console.log(fdata);
              if (fdata.filePath == "") {
                //alert("No File Present.");
                this.reqFileName = "";
              } else {
                this.reqFileName = fdata.fileName;
              }
            });
          }

        }
      });

    });

    if (this.reqType == "9801") {
      //this.IsCLRequest = true;
      this.GetChangeLog(this.requestNumber);
    }

    this.initGridLog();
    this.initGrid();

  }

  getStatus(message: string) {
    this.statusVal = message;
  }

  GetChangeLog(reqNo) {
    this.showRelatedChangeDiv = false;
    this.requestDetailSvc.GetChangeInfo(reqNo).subscribe(cdata => {
      //console.log(cdata);
      if (cdata.changeId == 0) {
        // alert("No Change Information found.");
        this.sharedService.showMessageBox("No Change Information found.");
      }
      else {
        this.changeData = cdata;
        console.log(this.changeData);
        this.changeLogStatusDesc = this.changeData.changeStatusDesc;
        var chStatusVal = this.changeData.changeStatusDesc;

        if (this.isContinue) {
          this.chStatusFontColor = this.statusColors.filter(function (stat) {
            return stat.description == chStatusVal;
          })[0].fontColor;
          this.chStatusBgColor = this.statusColors.filter(function (stat) {
            return stat.description == chStatusVal;
          })[0].backgroundColor;

          if (this.changeData.requestedDeliveryD == '') {
            this.requestData.requestedDelivery = this.changeData.requestedDeliveryD;
          }
        }

        this.GetRelatedChangeLog(reqNo);

        this.GetChangeLogFiles(this.changeData.thread);
        this.selectedChange = this.changeData.changeId;
        this.cookieService.set('CLChangeNo', this.changeData.changeId);

      }

    });

  }

  GetRelatedChangeLog(reqNo) {
    this.requestDetailSvc.GetRelatedChangeLog(reqNo).subscribe(cdata => {
      console.log(cdata);
      if (cdata.table.length == 0) {
        //alert("No Related Change Log found.");
        this.showRelatedChangeDiv = false;
      }
      else {
        this.showRelatedChangeDiv = true;
        this.relatedChangeData = cdata.table;
        console.log(this.relatedChangeData);

      }

    });

  }

  GetRelatedChangeInfo(chid) {
    this.selectedChange = chid;
    this.cookieService.set('CLChangeNo', chid);
    this.requestDetailSvc.GetRelatedChangeInfo(this.requestNumber, chid).subscribe(cdata => {
      console.log(cdata);
      if (cdata.changeId == 0) {
        //alert("No Related Change Log found.");
        //this.showRelatedChangeDiv = false;
      }
      else {
        this.changeData = cdata;


        this.reqType = this.changeData.requestType;
        //this.requestNumberFull = this.changeData.requestNumberFull;
        //this.chRequestDesc = this.changeData.requestDescription;
        //this.chRequestor = this.changeData.requestorName;
        //this.chReques tDate = this.changeData.requestDate;
        if (this.changeData.requestedDeliveryD == '') {
          this.requestData.requestedDelivery = this.changeData.requestedDeliveryD;
        }

        this.changeThread = this.changeData.thread;

        this.changeLogStatus = this.changeData.changeStatus;
        this.changeLogStatusDesc = this.changeData.changeStatusDesc;
        var chStatusVal = this.changeData.changeStatusDesc;

        this.chStatusFontColor = this.statusColors.filter(function (stat) {
          return stat.description == chStatusVal;
        })[0].fontColor;
        this.chStatusBgColor = this.statusColors.filter(function (stat) {
          return stat.description == chStatusVal;
        })[0].backgroundColor;

      }

    });

  }

  GetChangeLogFiles(changeId) {
    this.requestDetailSvc.GetChangeLogFiles(changeId).subscribe(fdata => {
      //console.log(fdata);
      console.log(fdata.files.length);
      if (fdata.files.length == 0) {
        //alert("No Change Information found.");
        this.showFilesDiv = false;
      }
      else {
        this.changeLogFileList = fdata.files;
        console.log(this.changeLogFileList);
        this.showFilesDiv = true;
      }
    });
  }

  showRequestFile() {
    //alert(this.params.data);
    this.requestOverviewSvc.GetISRRequestFile(this.requestData.requestNumberFull.substring(0, 4), this.requestNumber).subscribe(data => {
      if (data.filePath == "") {
        //alert("No File Present.");
      } else {
        var url = this.sharedService.API_URL + "GetFile.ashx?file=" + data.filePath;
        window.open(url);
      }
    });
  }

  onGridReadyLog(param) {
    this.gridApiLog = param.api;
    this.gridColumnApiLog = param.columnApi;
    try {
      // (param.api as any).context.beanWrappers.tooltipManager.beanInstance.MOUSEOVER_SHOW_TOOLTIP_TIMEOUT = 0;
    } catch (e) {
      console.error(e);
    }
  }



  onGridReady(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;

    try {
      // (param.api as any).context.beanWrappers.tooltipManager.beanInstance.MOUSEOVER_SHOW_TOOLTIP_TIMEOUT = 0;
    } catch (e) {
      console.error(e);
    }
  }

  initGridLog() {
    this.gridOptionsLog = <AgGridAngular>{
      context: {
        componentParent: this
      },
      defaultColDef: {
        sortable: true,
        filter: true
      },
    };
    this.columnDefsLog = [
      {
        headerName: 'Date', field: 'logDate', width: 180,
        cellFormatter: function (data) {
          //return moment(data.value, 'YYYY-MM-DD');
          moment(data.value).format('yyyy-MM-dd');
          // if(data.value!=undefined){
          // return this.datePipe.transform(data.value, 'yyyy-MM-dd');
          // }
        }
      },
      { headerName: 'User', field: 'logUser', width: 220, resizable: true, tooltipField: 'logUser' },
      { headerName: 'Dept', field: 'logDepartment', width: 100 },
      { headerName: 'Task', field: 'logTask', width: 100, resizable: true },
      { headerName: 'Description', field: 'logDescription', width: 590, tooltipField: 'logDescription' }
    ];
  }

  initGrid() {
    this.gridOptions = <AgGridAngular>{
      context: {
        componentParent: this
      },
      defaultColDef: {
        sortable: true,
        filter: true
      },
    };

    this.columnDefs = [
      { headerName: 'Change ID', field: 'changeId', width: 100 },
      { headerName: 'Status', field: 'changeStatusDesc', width: 180, resizable: true, tooltipField: 'changeStatusDesc' },
      { headerName: 'Executed By', field: 'executedBy', width: 200, resizable: true, tooltipField: 'executedBy' },
      { headerName: 'Executed On', width: 250, field: 'executionOnDate', tooltipField: 'executionOnDate' }

    ];

    this.rowSelection = 'single';

    //this.gridOptions.api.forEachNode(node=> node.rowIndex ? 0 : node.setSelected(true));

  }
  //valueGetter: this.executedDateTimeInfo
  executedDateTimeInfo(params) {
    return params.data.executionOn + " " + params.data.executionTime;
  }

  onSelectionChanged() {
    var selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length === 1) {
      this.GetRelatedChangeInfo(selectedRows[0].changeId);
    }

  }

  onRowDataChanged() {
    //console.log(this.selectedChange);
    this.gridOptions.api.forEachNode(node => {
      //console.log(node);
      //console.log(node.data.changeId);
      if (node.data.changeId == this.selectedChange) {
        node.setSelected(true);
      }
    });
  }

  gotoISROverview() {
    this.cookieService.set('CLChangeNo', '');
    this.router.navigate(['/ro']);

    // if(document.baseURI.indexOf("localhost")!=-1) 
    //   window.location.href = "http://localhost:4200/#/ro";      
    // else if(document.baseURI.indexOf("isrtest")!=-1) 
    //   window.location.href = "https://isrtest.enn.ericsson.net/v2/#/ro";
    // else if(document.baseURI.indexOf("isr")!=-1) 
    //   window.location.href = "https://isr.enn.ericsson.net/v2/#/ro";

  }

  getChangeFile(fileData) {
    //console.log(fileData);  
    var url = this.sharedService.API_URL + "GetFile.ashx?file=" + fileData.fileVPath;
    window.open(url);
  }

  OpenInRH() {
    if (document.baseURI.indexOf("localhost") != -1) {
      //window.location.href = "http://localhost:4200/#/ro";      
      window.open("http://localhost:4200/#/ro", '_blank')
    }
    else if (document.baseURI.indexOf("isrtest") != -1) {
      //window.location.href="https://rhtest.enn.ericsson.net/rh/main/request/" + this.requestData.requestNumberFull;
      window.open("https://rhtest.enn.ericsson.net/rh/main/request/" + this.requestData.requestNumberFull, '_blank')
    }
    else if (document.baseURI.indexOf("isr") != -1) {
      //window.location.href="https://rh.enn.ericsson.net/rh/main/request/" + this.requestData.requestNumberFull;
      window.open("https://rh.enn.ericsson.net/rh/main/request/" + this.requestData.requestNumberFull, '_blank')
    }

  }

  copyChangeLink(changeId) {
    console.log(changeId);
    var changeLink = this.sharedService.ISR_URL + "change/" + changeId;
    navigator.clipboard.writeText(changeLink);

  }

  chkUserPerm(cid) {
    //var userPermission = JSON.stringify(_userPerm);
    //console.log(this.userPermission);
    var userPerm = JSON.parse(this.userPermission);
    //console.log(userPerm);
    if (userPerm.length > 0) {
      var k = 0;
      for (var i = 0; i <= userPerm.length - 1; i++) {
        if (userPerm[i].codePermissionID == cid) {
          k++;
        }
      }
      if (k > 0) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  showFirewallRule() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      action: 'Show',
      firewallruleList: this.firewallRulesList,
      reqNo: this.requestNumber,
      requestNumberFull: this.requestNumberFull
    };

    const dialogRef = this.dialog.open(FirewallrulesComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        console.log("Dialog result:", result);

        //this.GetRelatedChangeInfo(result.chId);

      }

      //this.reloadComponent();

    });

  }

  checkFirewallRulesList(firewallruleList) {
    for (let item of firewallruleList) {
      var max_number = item.lstSourceList.length;
      if (item.lstDestinationList.length > max_number) {
        max_number = item.lstDestinationList.length;
      }
      if (item.lstProtocolList.length > max_number) {
        max_number = item.lstProtocolList.length;
      }

      if (max_number == 1) {
        if (item.lstSourceList[0].name == "none" && item.lstSourceList[0].ip == null && item.lstDestinationList[0].name == "none"
          && item.lstDestinationList[0].ip == null && item.lstProtocolList[0].name == "none") {
          return false;
        }
        else {
          return true;
        }
      }
      else {
        return true;
      }

    }

  }

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { userModel, validateOtp } from '../model/user.model';
import {LoginOTPService}from 'src/app/login-otp/login.service';
import { SharedService } from '../Shared/shared.service';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { GlobalParamsService } from '../global/globalParams.service';
import { AuthService } from '../auth/auth-service.service';
import { ProgressBarService } from '../utility/ProgressBar.service';
import { UserInfoService } from '../global/userInfo.service';

@Component({
  selector: 'app-login-otp',
  templateUrl: './login-otp.component.html',
  styleUrls: ['./login-otp.component.css'],
  providers: [validateOtp]
})
export class LoginOTPComponent  implements OnInit {
  form!: FormGroup;
  loading = false;
  submitted = false;
  email:string = '';
  wrongPwd:number =0;

  //For getting token
  //formData: FormData = new FormData();
  isDataAvailable:boolean=false;
  devEnv:string=''; 
  //showLoadingIndicator = false;

  loggedUserName: string;
  ISTCookieValue: string;
  ISRv1Version:string;
  isFromLogin:boolean;

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private validateOtp:validateOtp,
      private loginOTPservice:LoginOTPService,
      private sharedService:SharedService,
      public datepipe: DatePipe,
      private cookieService : CookieService,
      //for getting token
      private globalParamsService:GlobalParamsService,
      private authSvc:AuthService,
      private progressBarService: ProgressBarService, 
      private objUserModel:userModel, 
      private objUserInfoService:UserInfoService
     // private accountService: AccountService,
     // private alertService: AlertService
  ) { }
  ngOnInit(): void {
    this.globalParamsService.isEricssonLogin.subscribe(isEric=>this.isFromLogin=isEric); 
    if(!this.isFromLogin)
    {
      this.router.navigate(['/login'])
    }
    else{
      this.globalParamsService._isEricssonLogin.next(true);
    }

    this.form = this.formBuilder.group({
      username: ['', Validators.required]
     // password: ['', Validators.required]
  });
  this.email = this.route.snapshot.paramMap.get('email');
  }

  get f() { return this.form.controls; }

  onCancel(){
    this.sharedService.logout();
  }

  onSubmit() {   
      this.submitted = true;

      // reset alerts on submit
      //this.alertService.clear();

      // stop here if form is invalid
      if (this.form.invalid) {
          return;
      }

      //this.cookieService.set('ISTCookie3',JSON.stringify({test: 'tesval', test1: 'test1val'}));
      //this.cookieService.set('ISTCookie3','Expires=ttt;type=hh');
    //  document.cookie  = 'ISTCookie3=' + 'username=vembu@ericsson.com&Authorized=False';
 // document.cookie = "myCookie=" + JSON.stringify({foo: 'bar', baz: 'poo'});

      this.loading = true;
      //console.log(currentDateTime);
      
      //var str = this.f.username.value;
      //yet remove domain and email
      let domain = this.email.split("@");
      this.validateOtp.domain = domain[1] ;
      this.validateOtp.otp = this.f.username.value;
      this.validateOtp.email = this.email;
      this.validateOtp.type = 'nonEric';
      this.loginOTPservice.ValidateOTP(this.validateOtp).subscribe(async data=>{
        //this.objUserModel=data.table[0];
        //var resp = data;
        var resp = data.table[0].resp;
        if(resp == 1)
        { 
          await this.loginOTPservice.getToken(this.email);
          await this.loginOTPservice.setCookieValue(data);          
          this.loginOTPservice.saveWebLog(this.validateOtp).subscribe(data=>{           
            if(data == true)
            { 
              window.localStorage.setItem('isAuthorized','true');              
              window.location.href =document.baseURI + '/#/home';
            }
          });   
        }
        else if(resp==2)
        {
          'OTP got expired.'
          this.sharedService.showMessageBox("Please generate new OTP.");
          this.router.navigate(["/login"]);
        }
        else if(resp==3)
        {
          this.wrongPwd = this.wrongPwd+1;
          this.sharedService.showMessageBox("Please enter valid OTP."); 
          if(this.wrongPwd >= 3)
          {
            this.sharedService.showMessageBox("Too many attempts, please generate new OTP");
            this.router.navigate(["/login"]);
          }         
        }
        else if(resp==0)
        {
          this.sharedService.showMessageBox("You are not authorized to login to the application");          
          this.router.navigate(["/login"]);
        }    
        //console.log(resp);
        this.loading = false;
      });  

    /*  this.accountService.login(this.f.username.value, this.f.password.value)
          .pipe(first())
          .subscribe({
              next: () => {
                  // get return url from query parameters or default to home page
                  const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                  this.router.navigateByUrl(returnUrl);
              },
              error: error => {
                 // this.alertService.error(error);
                  this.loading = false;
              }
          });*/
  } 

  focusElement(event) {
   var btn_sendOTP = document.getElementById('bt_login');
    btn_sendOTP.focus();  
    }  

}

import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  // Custom format for displaying the date
  override format(date: Date, displayFormat: Object): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`; // Format as yyyy-mm-dd
  }

  // Custom parsing of a date string (e.g., 'yyyy-mm-dd')
  override parse(value: any): Date | null {
    if (typeof value === 'string') {
      const parts = value.split('-');
      if (parts.length === 3) {
        const year = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Month is 0-based
        const day = parseInt(parts[2], 10);
        return new Date(year, month, day);
      }
    }
    return null; // Return null if parsing fails
  }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import{FaqComponent} from '../faq/faq.component';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    FaqComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule
  ],
  exports:[
    FooterComponent,
    HeaderComponent,
    FaqComponent
  ]
})
export class CoreModule { }

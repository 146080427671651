import { Component, inject, Inject, Injector, OnInit } from '@angular/core';
//import { Buttons, Button } from "../common";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject } from "rxjs";
import {Injectable} from '@angular/core';
import { SharedService } from '../Shared/shared.service';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-messge-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent implements OnInit {
  title = "";
  message = "";

  constructor(private sharedSvc:SharedService){}
  
 /* title = '';
  message = '';
  buttons: Buttons = Buttons.Ok;

  constructor( @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.title = data.title;
    this.message = data.message
    this.buttons = data.buttons;
  }

  dialogResultSubject = new Subject<Button>();
  dialogResult$ = this.dialogResultSubject.asObservable();

  public get Buttons(): typeof Buttons {
    return Buttons;
  }

  public get Button(): typeof Button {
    return Button;
  }

  click(button: Button) {
    this.dialogResultSubject.next(button);
  }*/

  ngOnInit(): void {
  this.message = this.sharedSvc.messageBoxMsg;
  console.log(this.sharedSvc.messageBoxMsg);
  }

}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {SharedService} from '../Shared/shared.service'

@Injectable({
  providedIn: 'root'
})
export class RequestApprovalService {

  constructor(private _http: HttpClient,private sharedService:SharedService) { }
  
  CheckForApprovedRequestor(formData):Observable<any>{
    return this._http.post<any[]>(this.sharedService.API_URL + '/api/ISR/createNewRequest/CheckForApprovedRequestor/', formData);
  }    
  GetApprovalStatus(formData):Observable<any>{
    return this._http.post<any[]>(this.sharedService.API_URL + '/api/ISR/createNewRequest/GetApprovalStatus/', formData);
  }    
  GetRequestApprovalData(formData):Observable<any>{
    return this._http.post<any[]>(this.sharedService.API_URL + '/api/ISR/createNewRequest/GetRequestApprovalData/', formData);
  }  
  GetTemplateFileForRequestApproval(formData):Observable<any>{
    return this._http.post<any[]>(this.sharedService.API_URL + '/api/ISR/createNewRequest/GetTemplateFileForRequestApproval/', formData);
  }
  UpdateSRTApprover(formData):Observable<any>{
    return this._http.post<any[]>(this.sharedService.API_URL + '/api/ISR/createNewRequest/UpdateSRTApprover/', formData);
  }

}

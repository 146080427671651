import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,UntypedFormControl, FormControl } from '@angular/forms';
import { UserInfoService } from '../global/userInfo.service';
import { RequestApprovalService } from './request-approval.service';
import{Router,NavigationEnd, ActivatedRoute} from '@angular/router';
import {SharedService} from '../Shared/shared.service';
@Component({
  selector: 'app-request-approval',
  templateUrl: './request-approval.component.html',
  styleUrls: ['./request-approval.component.css']
})
export class RequestApprovalComponent implements OnInit {
  requestApprovalForm: UntypedFormGroup;
  userEmail:any;
  formData: FormData;  
  requestNumber='';
  numRequestType;
  requestData:any;
  approvalStatus='';
  emailBasedRequest:number;
  approvalMessage='';
  showApprovalMessage:Boolean=false;
  ericssonProject:string='';
  constructor(private objUserInfoService:UserInfoService, private objRequestApprovalService:RequestApprovalService,private router:Router,private route: ActivatedRoute,private formBuilder: UntypedFormBuilder,private sharedService:SharedService) { }
  
  ngOnInit(): void {
    this.requestApprovalForm=this.formBuilder.group({ 
      requestNumber:  new FormControl(),      
      statusDesc:   new FormControl(),      
      requestedOn:  new FormControl(),
      requestedDelivery:  new FormControl(),
      requestType:  new FormControl(),
      requestSubType: new FormControl(),
      requestFile: new FormControl(),
      requestDescription: new FormControl(),
      additionalInfo: new FormControl(),
      requestApprovalStatus: new FormControl(),
      approvalStatusSetOn: new FormControl(),
      approvalStatusSetBy: new FormControl(),
      rejectionReason: new FormControl(),
      requestApprovalStatusMessage: new FormControl(),
      requestorName: new FormControl(),
      requestorEmail: new FormControl(),
      requestorPhone: new FormControl(),
      ericssonProject: new FormControl(),
      projectID: new FormControl(),
      projectnameRequest: new FormControl()        
    });

    //Retrieve Logged In user email
    this.userEmail=this.objUserInfoService.userEmail;

    //Retrieve requestNumber from route
    this.route.params.subscribe(params => {
      console.log(params['requestNumber']);
      this.requestNumber=params['requestNumber'];
    })    

    this.numRequestType=this.requestNumber.split('.')[0];    
    if(this.numRequestType=="0401" || this.numRequestType=="0501" || this.numRequestType=="1501"){
      this.emailBasedRequest=0;
      this.formData=new FormData();
    this.formData.append('formData',JSON.stringify(this.userEmail));
      this.objRequestApprovalService.CheckForApprovedRequestor(this.formData).subscribe(data=>{
        if(data.table.length==0){
          alert('You are not authorized to approve requests.\nIf this is wrong, please contact ENN Project support.');
          this.router.navigate(['/createNewRequest']);
        }else{        
          this.getRequest();
        }
      })
    }else{
      this.emailBasedRequest=1;
      this.getRequest();    
    }
  } 

  getRequest(){
    this.formData=new FormData();            
    this.formData.append('requestNumber',JSON.stringify(this.requestNumber));
      this.objRequestApprovalService.GetRequestApprovalData(this.formData).subscribe(data=>{
        if(data.table.length>0){                 
          this.requestData=data.table[0];
          this.requestApprovalForm.patchValue(this.requestData);    
          this.ericssonProject=this.requestData.ericssonProject;     
          if(Object.prototype.toString.call(new Date(this.form.approvalStatusSetOn.value)) === "[object Date]"){
            if(new Date(this.form.approvalStatusSetOn.value).getFullYear()<2000){
              this.approvalStatus="0";
            }else{
              this.approvalStatus="1";
            }            
          }else{
            this.approvalStatus="0";
          }
        }
      })
  }
  getTemplateFile(){
    this.formData=new FormData();            
    this.formData.append('requestNumber',JSON.stringify(this.requestNumber));
      this.objRequestApprovalService.GetTemplateFileForRequestApproval(this.formData).subscribe(data=>{
        if(data.filePath==""){          
          alert('File not present!');
          return
        }else{          
          var url = this.sharedService.API_URL + "GetFile.ashx?file=" + data.filePath;
          window.open(url);
        }      
      })

  }

  get form() { return this.requestApprovalForm.controls; }

  ApproveRejectRequest(approvedStatus){
    var rejectionReason='';
    if(this.numRequestType=="0401" || this.numRequestType=="0501" || this.numRequestType=="1501"){
      // if(approvedStatus=='0'){     
      //   if(this.form.rejectionReason.value=='') {
      //      alert('Please specify Rejection Reason!');
      //      return;
      //   }
      // }
        rejectionReason=this.form.rejectionReason.value;       
    }else{
      if(approvedStatus=='0'){     
        if(this.form.rejectionReason.value=='') {
           alert('Please specify Rejection Reason!');
           return;
        }
        rejectionReason=this.form.rejectionReason.value;
       }else{
         rejectionReason='';
       }
    }
   
   
    this.formData=new FormData();            
    this.formData.append('requestNumber',JSON.stringify(this.requestNumber));
    this.formData.append('approvedStatus',JSON.stringify(approvedStatus));
    this.formData.append('approver',JSON.stringify(this.userEmail));
    this.formData.append('rejectionReason',JSON.stringify(rejectionReason));
    this.formData.append('sendTo',JSON.stringify(this.requestData.sendTo));
    this.formData.append('parentTypeName',JSON.stringify(this.requestData.requestType));
    this.formData.append('shortDesc',JSON.stringify(this.requestData.requestDescription));
    this.formData.append('requestSubType',JSON.stringify(this.requestData.requestSubType));
    this.formData.append('requestCreatedOn',JSON.stringify(this.requestData.requestCreatedOn));
    this.formData.append('fWDept',JSON.stringify(this.requestData.fwDept));
    this.formData.append('requestorEmail',JSON.stringify(this.requestData.requestorsEmail));

      this.objRequestApprovalService.UpdateSRTApprover(this.formData).subscribe(data=>{
        if(data==1){    
          if(approvedStatus=='1')  {
            //this.form.requestApprovalStatusMessage.setValue('Request approved successfully!');
            this.approvalMessage='Request approved successfully!';            
            alert('Request approved successfully!');         
          }    
          else{
            //this.form.requestApprovalStatusMessage.setValue('Request rejected!');
            this.approvalMessage='Request rejected!';
            alert('Request rejected!');            
          }       
          this.showApprovalMessage=true;
          this.ngOnInit();            
        }  
        else{
          alert('Error!');
          return;
        }
      })
  }

 

}

<div class="mainCard">
    <div class="subCard">
<div class="card">
    <h4 class="card-header">Login</h4>
    <div class="card-body">
        <form [formGroup]="form">
            <div class="mb-3">
                <label class="form-label-main">An email containing the one-time password is now send by ENN-Tooling@Ericsson.com to {{email}}. Delivery may take a minute. Check your emails and enter the one-time password in the field below.</label>
               
                <table>

                <tr><td style="padding-right:10px;">
                <label class="form-label">One-Time Password</label> </td> 
                <td  style="width:275px;"><input type="text" maxlength="8" formControlName="username" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.username.errors }" (keydown.enter)="focusElement($event)"/>
                </td></tr> 

                <tr><td></td>
                <td><div *ngIf="submitted && f.username.errors" style="color: red;">OTP is required</div>
                    <!--<div class="invalid-feedback">
                        <div>OTP is required</div>
                    </div> -->
                <!--<div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">OTP is required</div>
                </div>-->
            </td></tr>

            </table>
            </div>                                    
            <div style="float: right;padding-right: 2px;">
                <button class="button-login" (click)="onCancel()" autofocus>                    
                        Cancel
                </button>
                <button [disabled]="loading" [disabled]="f.username.errors"
                (click)="onSubmit()" class="button-login" id="bt_login">
                <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
                    Login
                </button>               
            </div>          
        </form>
    </div>
</div>
</div>
</div>

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { SharedService } from '../Shared/shared.service';
@Injectable({
  providedIn: 'root'
})
export class AuthTokenInterceptorService implements HttpInterceptor {

  constructor(private sharedService:SharedService) { }
  intercept(req, next) {  
    let request;
    if(window.localStorage.getItem('token')!=null){
      request=req.clone({
        setHeaders:{
          Authorization: 'Bearer ' + window.localStorage.getItem('token')
        }
      }) 
      return next.handle(request).pipe(
        
        catchError((error) => {
          //console.log(error);
          if (
            (error instanceof HttpErrorResponse &&
            !req.url.includes('auth/signin')) &&
            ((error.status === 401) ||
            (error.error.exceptionType == "Microsoft.IdentityModel.Tokens.SecurityTokenExpiredException"))
          ) {
            this.sharedService.logout();
           // this.router.navigate(['/login']);
            //return 'test' + error;//this.handle401Error(req, next);
          }
  
          return throwError(() => error);
        })
      );  
    }
    else{
      return next.handle(req);        
    }     
  }
}


import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { startWith, tap, delay } from 'rxjs/operators';
import { ProgressBarService } from '../utility/ProgressBar.service';
 
@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  
     constructor(private progressBarService: ProgressBarService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
        this.progressBarService.show();
 
        return next
            .handle(req)
            .pipe(
                tap((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        this.progressBarService.hide();
                    }
                }, (error) => {
                    this.progressBarService.hide();
                })
            );
    }
}
import { Component, Input, OnInit } from '@angular/core';
import { HeaderComponent } from '../core/header/header.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  @Input() data:any;
  IsTrue = false;
  constructor() { }
  handleClick(){
    this.IsTrue = !this.IsTrue;
  }
  displayStyle = "none";
  ngOnInit() {
  }
  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }

}

import { LoginModel } from './../model/login.model';
import { userModel } from './../model/user.model';
import { Component, OnInit } from '@angular/core';
import { SharedService } from '../Shared/shared.service';
import { RequestModel } from '../model/request.model';
import { RequestDefinitionsModel } from '../model/requestDefinitions.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-long-description',
  templateUrl: './long-description.component.html',
  styleUrls: ['./long-description.component.css'],
  providers: [RequestModel,RequestDefinitionsModel,userModel,LoginModel]
})
export class LongDescriptionComponent implements OnInit {

  constructor(private sharedService:SharedService,
    private reqModel:RequestModel,
    private userModel:userModel,
    private reqDefinitionModel : RequestDefinitionsModel,
    private loginModel : LoginModel,
    private router: Router 
  ) { }

  ngOnInit(): void {
    if(window.history.state.reqData != undefined){
    this.userModel=window.history.state.userModel;
    this.reqModel= window.history.state.reqData;
    this.reqDefinitionModel=window.history.state.reqDefinitions;
    this.loginModel = window.history.state.LoginModel;
    }
    else
    {
      this.router.navigate(['/createNewRequest']);
    }
  }
  GoToNextView(){
    /*this.submitted = true;       
    if (this.projectRefAndBudgetForm.invalid) {            
      return;
  }
  else{*/
    
    this.reqModel.longDescription = (document.getElementById("longDesc")  as HTMLInputElement).value ;    

    this.sharedService.gotoNextView('sendRequestView',this.reqModel,this.reqDefinitionModel,this.userModel,this.loginModel);    
  //}
  }
  GoToPreviousView(){
    this.sharedService.gotoNextView('reqView',this.reqModel,this.reqDefinitionModel,this.userModel,this.loginModel);  
  }
}

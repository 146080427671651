/*import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-tooltip',
  template: `<div class="custom-tooltip" [innerHTML]="content"></div>`,
  styles: [`
    .custom-tooltip {
      background: rgba(0, 0, 0, 0.8);
      color: white;
      padding: 8px;
      border-radius: 4px;
      position: absolute;
      z-index: 1000;
    }
  `]
  //templateUrl: './custom-tooltip.component.html',
  //styleUrls: ['./custom-tooltip.component.css']
})
export class CustomTooltipComponent implements OnInit {

  @Input() content: string = '';
  constructor() { }

  ngOnInit(): void {
  }

}*/
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';

/**
 * Vanilla-Angular HTML tooltip vs 'string-only' tooltips
 * Min. tested version is 9.1.11
 *
 * Pass in the shown/triggering element thru normal ng-content, e.g.
 *
 * <content-tooltip>
 * 	<div>your content</div>
 * </content-tooltip>
 *
 * Pass in the tooltip HTML content thru the 'template' input, e.g.
 * <content-tooltip [template]="YourTooltipTemplate">
 * ...
 * </content-tooltip>
 *
 * <ng-template #YourTooltipTemplate>
 * ...
 * </ng-template>
 *
 * Only allows placement 'above' currently due to
 * time constraints
 */
@Component({
  selector: 'custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.css']
})
export class CustomTooltipComponent<T = any> implements OnInit {
  @ViewChild('popupEl', { static: true }) popupEl!: ElementRef;

  @Input() template!: TemplateRef<T>;

  popupOffsetWidth = '';
  popupOffsetHeight = '';
  popupVisible = false;

  hideTooltip() {
    this.popupVisible = false;
  }


  ngOnInit(): void {
    if (!this.template) {
      throw ReferenceError('Property <template> must be provided');
    }
  }

  recalculate() {
    //this.calcOffsetWidth();
    //this.calcOffsetHeight();
    this.calcOffsets();
    this.popupVisible = true;
  }

  /*private calcOffsetWidth() {
    const width = this.popupEl.nativeElement.offsetWidth;
    this.popupOffsetWidth = `calc(50% - ${width / 2}px)`;
  }

  private calcOffsetHeight() {
    const adjustment = 8; // tail height
    const height = this.popupEl.nativeElement.offsetHeight;
    this.popupOffsetHeight = `${-(adjustment + height)}px`;
  }*/

    private calcOffsets() {      
      this.popupOffsetWidth = '20px'; 
      this.popupOffsetHeight = '2px'; 

      /*const hostElem = this.popupEl.nativeElement.parentElement; // Reference to the container
      const hostRect = hostElem.getBoundingClientRect();
      const tooltipRect = this.popupEl.nativeElement.getBoundingClientRect();

      const width = this.popupEl.nativeElement.offsetWidth;
      const height = this.popupEl.nativeElement.offsetHeight;
      
      // Position the tooltip to the right of the host element
      //this.popupOffsetWidth = `${hostRect.right + 10}px`; // 10px space from the right
      this.popupOffsetWidth = `${width}px`;  //`${hostRect.right - 500}px`; // 10px space from the right
      this.popupOffsetHeight =  `${height}px`;//`${hostRect.top + (hostRect.height / 2) - (tooltipRect.height / 2)}px`; // Center vertically*/

     /* const hostElem = this.popupEl.nativeElement.parentElement; // Reference to the container
    const hostRect = hostElem.getBoundingClientRect();
    const tooltipRect = this.popupEl.nativeElement.getBoundingClientRect();
    
    // Calculate potential position
    let tooltipLeft = hostRect.right + 10; // 10px space from the right
    let tooltipTop = hostRect.top + (hostRect.height / 2) - (tooltipRect.height / 2); // Center vertically

    // Check if tooltip exceeds viewport width
    if (tooltipLeft + tooltipRect.width > window.innerWidth) {
      tooltipLeft = hostRect.left - tooltipRect.width - 10; // Position to the left if out of bounds
    }

    // Check if tooltip exceeds viewport height (optional)
    if (tooltipTop < 0) {
      tooltipTop = 0; // Adjust to the top if it goes out of bounds
    }

    this.popupOffsetWidth = `${tooltipLeft}px`;
    this.popupOffsetHeight = `${tooltipTop}px`;*/
    }

    private calcOffsetWidth() {
      const hostElem = this.popupEl.nativeElement.parentElement; // Reference to the container
      const width = this.popupEl.nativeElement.offsetWidth;
      const hostRect = hostElem.getBoundingClientRect();
      this.popupOffsetWidth = `${hostRect.right + 10}px`; // Position to the right of the host element
    }
  
    private calcOffsetHeight() {
      const adjustment = 8; // Tail height adjustment
      const height = this.popupEl.nativeElement.offsetHeight;
      this.popupOffsetHeight = `${-(adjustment + height)}px`;
    }

}


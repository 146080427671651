<div class="mainPanel">
  <!--<div class="contentPanel">-->
  <p class="profileTitle">Information Requestor</p>
  <!--</div>-->
  <div class="example-container">
    <table>
      <tr>
        <td>
          <mat-label class="mat-lab">Name:</mat-label>
        </td>
        <td style="padding-right: 17px;"> 
          <mat-form-field appearance="outline">
            <input matInput [formControl]="ctrl_name" required>
            <!-- <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>-->
          </mat-form-field>
        </td>
        <td>
          <mat-label class="mat-lab">Department:</mat-label>
        </td>
        <td>
          <mat-form-field appearance="outline">
            <input matInput [formControl]="ctrl_department" required>
            <!--  <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>-->
          </mat-form-field>
        </td>
      </tr>

      <tr>
        <td>
          <mat-label class="mat-lab">Email:</mat-label>
        </td>
        <td>
          <mat-label class="mat-email">{{pf_email}}</mat-label>
          <!--<mat-form-field appearance="outline"{{pf_email}} enn.ran.configuration.management@ericsson.com>   
            <input matInput [formControl]="ctrl_email" [(ngModel)]="pf_email" required>
            <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>-->
        </td>
        <td>
          <mat-label class="mat-lab">Mobile nr:</mat-label>
        </td>
        <td>
          <div class="mat-info">
            <mat-form-field appearance="outline" style="width: 165px; margin-right: 2px;">
              <input matInput [formControl]="ctrl_mobileNo" required>
              <!-- <mat-error *ngIf="ctrl_email.invalid">{{getErrorMessage()}}</mat-error>
          data-html="true" 
          -->
            </mat-form-field>
            <div contenttype="template" matTooltip="          
          Allowed phone formats are:
          +AA B CCCCCCCC
          +AA-B-CCCCCCCC
          +AAB-CCCCCCCC
          +AABCCCCCCCC
          06 CCCCCCCC
          06-CCCCCCCC" matTooltipPosition="after">
              <img src="assets/Images/info.png" />
            </div>

          </div>
          <mat-error>{{validateMobileNumber()}}</mat-error>
          <!--<mat-icon>info</mat-icon>-->

        </td>
      </tr>

    </table>

  </div>
  <div class="button-container">

    <!--<button  class="button-pattern-btm" (click)="btnClick();">BACK TO MENU</button>-->
    <button type="button" class="button-profile" (click)="btnCancel();">Cancel</button>

    <button type="button" class="button-profile" (click)="btnProfileSave();">Save</button>
  </div>
</div>
<div class="errorMsg">
  <mat-error *ngIf="isError" [innerHtml]="errMessage"></mat-error>
</div>

<!--<mat-error [innerHtml] =getErrorMessage()> </mat-error>-->
<!--<mat-error *ngIf="isError">{{getErrorMessage()}}</mat-error>-->
<!--<div class="mainPanel">
    <div class="contentPanel">
    <p class="profileTitle">Information Requestor</p>
    </div>
    <div class="example-container">
        <mat-form-field appearance="fill">
          <mat-label>Enter your email</mat-label>
          <input matInput placeholder="pat@example.com" [formControl]="email" required>
          <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
      <form class="example-form">
        <mat-form-field class="example-full-width">
          <mat-label>Email</mat-label>
          <input type="email" matInput [formControl]="emailFormControl" placeholder="Ex. pat@example.com">
          <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </form>      
      </div>-->
import { Console } from 'console';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { RequestViewService } from '../request-view/request-view.service';
import { SharedService } from '../Shared/shared.service';
import { RequestModel, serviceModel } from '../model/request.model';
import { RequestDefinitionsModel } from '../model/requestDefinitions.model';
import { userModel } from '../model/user.model';
import { LoginModel } from '../model/login.model';
import { StatusList, StatusNames, TypeOfRequests } from '../model/enum.model';
import { GlobalParamsService} from '../global/globalParams.service';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {AsyncPipe} from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-request-view',
  templateUrl: './request-view.component.html',
  styleUrls: ['./request-view.component.css'],
  providers: [RequestModel, RequestDefinitionsModel, userModel, LoginModel,serviceModel,DatePipe,]
})

export class RequestViewComponent implements OnInit { 
  requestViewForm: UntypedFormGroup;
  listTypeOfRequest: any = [];
  listSubTypeOfRequest: any = [];
  //requestDefinitions:any=[];  
  requestedDeliveryDate: string = '';
 // requestedDeliveryDate1: string = '';
  requestedDeliveryDateNote: string = '';
  submitted = false;
  emailData: FormData = new FormData();
  selectedItmId: string = '';
  selectedItmName: string = '';
  selectedSubItmId: string = '';
  selectedSubItmName: string = '';
  showSubTypeOfRequest: Boolean = true;
  btNext: string = 'Next';
  comapny: string = '';
  userName: String = '';
  devMode: Boolean = false;
  reqParentType: String = '';
  reqParentTypeDesc: String = '';
  reqSubType: String ='';
  reqSubTypeDesc: String = '';
  ISTCookieValue: string;
  userFullName:String;
  isParentRequest: boolean = false;
  requestData:any;
  DoReqDelDate:boolean;
  //items: string[] = ['Item 1', 'Item 2', 'Item 3'];
  //items:any; 
  //newItem: string = '';  
  //filteredOptions: Observable<any>;
 // myControl: any;
  myControl = new FormControl<string | serviceModel>('');
  options: serviceModel[];// = [{name: 'Mary'}, {name: 'Shelley'}, {name: 'Igor'}];
  items: serviceModel[] = [];
  item: serviceModel;
  filteredOptions: Observable<serviceModel[]>;
  enableRelatedServices:boolean = false;
  minDate:Date= new Date();

  constructor(private RequestViewSvc: RequestViewService, private SpinnerService: NgxSpinnerService, private sharedService: SharedService, private formBuilder: UntypedFormBuilder, private reqModel: RequestModel, public reqDefinitionModel: RequestDefinitionsModel, 
    private userModel: userModel, private loginModel: LoginModel,
  private globalParamsService:GlobalParamsService, private datePipe: DatePipe,
  private cookieService: CookieService,private location: Location,private servcie:serviceModel, private router: Router, ) { }

  ngOnInit() {     
    this.items= []; 
    if (window.history.state.reqData == undefined) {
      this.requestData = this.location.getState();  
      this.reqParentType = this.requestData.reqParentType;      
    if(this.reqParentType != undefined){     
    this.reqParentTypeDesc = this.requestData.reqParentTypeDesc;
    this.reqSubType = this.requestData.reqSubType;
    this.reqSubTypeDesc = this.requestData.reqSubTypeDesc;
    this.isParentRequest = this.requestData.isParentRequest;
      this.requestViewForm = this.formBuilder.group({
        //  this.requestViewForm=new FormGroup({
        //requestType: [null, Validators.required],
        //requestSubType: [null, Validators.required],
        shortDesc: ['', Validators.required],
        requestedDeliveryDateControl: ['2024-01-02', Validators.required],
        rbOneTimes: ['Yes'],
        oneTimeName: [''],
        oneTimeEmailAddress: [''],
        oneTimeRequestDescription: ['']
        //newItem: ['']         
      });    
            

     /* this.globalParamsService.requestParentType.subscribe(val=>this.reqParentType=val);                         
    this.globalParamsService.requestParentTypeDesc.subscribe(val=>this.reqParentTypeDesc=val);  
    this.globalParamsService.requestSubType.subscribe(val=>this.reqSubType=val);  
    this.globalParamsService.requestSubTypeDesc.subscribe(val=>this.reqSubTypeDesc=val);
    this.globalParamsService.isParentRequest.subscribe(val=>this.isParentRequest=val);
   */    
    }
    else
    {
      this.router.navigate(['/createNewRequest']);
    }
    } else {      
      this.reqModel = window.history.state.reqData;      
      this.reqDefinitionModel = window.history.state.reqDefinitions;
      this.userModel = window.history.state.userModel;
     // this.loginModel = window.history.state.loginModel;
     // this.bindSubTypeOfRequest(this.reqModel.requestType);
      this.requestViewForm = this.formBuilder.group({
       // requestType: [this.reqModel.requestType, Validators.required],
       // requestSubType: [this.reqModel.requestSubType, Validators.required],
        shortDesc: [this.reqModel.shortDesc, Validators.required],
        requestedDeliveryDateControl: [this.reqModel.requestedDeliveryDate],
        rbOneTimes: ['Yes'],
        oneTimeName: [''],
        oneTimeEmailAddress: [''],
        oneTimeRequestDescription: ['']
      });
    this.reqParentType = this.reqModel.requestType;
    this.reqParentTypeDesc = this.reqModel.requestTypeText;
    this.reqSubType = this.reqModel.requestSubType;
    this.reqSubTypeDesc = this.reqModel.requestSubTypeText;
    this.isParentRequest = this.reqModel.isParentRequest;
    this.items = this.reqModel.serviceList;
    }
    //==Get User Info===

    //this.userModel.email='komal.gawande@ericsson.com';      
    
    this.ISTCookieValue = this.cookieService.get('ISTCookie2');
    this.userModel.email = this.cookieService.get('userEmail');
    //this.devMode =  this.ISTCookieValue.substring(this.ISTCookieValue.indexOf("devmode"), this.ISTCookieValue.indexOf("&")).replace("devmode=", '') == 'true' ? true : false;
     this.sharedService.GetUserByEmail(this.userModel).subscribe(data=>{
         this.userModel=data.table[0];         
         /*this.userName=this.userModel.email;
         this.devMode=this.userModel.devMode;  
         this.userFullName = this.userModel.fullName;   */ 
       });  

    //===============================================================
    //==Get Login Info===
    // vembu - comment
   /* this.sharedService.GetLoginData(this.userModel).subscribe(data => {
      let loginData = data.table[0];

      if (loginData == undefined) {
        this.loginModel.authorized = false;
        this.loginModel.ValidTo = null;
        this.loginModel.IsOneTimeUser = false;
      } else {
        this.loginModel.authorized = true;
        this.loginModel.ValidTo = loginData.validTo;
        //to do...
        this.loginModel.IsOneTimeUser = true;
      }
    });
    //===============================================================

    this.bindTypeOfRequest();
*/


//this.requestedDeliveryDate1 = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
//this.requestViewForm.value.requestedDeliveryDateControl.set(this.datePipe.transform(new Date(), 'dd-MM-yyyy'))
//const date = '2024-11-05'; // Example date in yyyy-mm-dd format
//this.requestViewForm.get('requestedDeliveryDateControl')?.setValue(date);

this.requestViewForm.get('requestedDeliveryDateControl')?.valueChanges.subscribe(value => {
  this.validateDate(value);
});

this.bindSubTypeOfRequest_new();
this.bindService();

  }

  get validated() { return this.requestViewForm.controls; }


  bindTypeOfRequest() {
    this.SpinnerService.show();
    //this.SharedComponent.showSpinner;
    //this.RequestViewSvc.GetTypeOfRequest().subscribe(data=>{
    this.RequestViewSvc.GetTypeOfRequest().then(data => {
      this.listTypeOfRequest = data.table;
    })
    //  {}
    //  this.listTypeOfRequest=data.table;   
    this.SpinnerService.hide();
    //this.SharedComponent.hideSpinner;      
    //  });
  }

  bindSubTypeOfRequest(event) {
    /*this.SpinnerService.show();
    //this.SharedComponent.showSpinner;
    if (event.target != undefined) {
      let selectedOptions = event.target['options'];
      let selectedIndex = selectedOptions.selectedIndex;
      this.reqModel.requestTypeText = selectedOptions[selectedIndex].text;
      this.selectedItmId = selectedOptions[selectedIndex].value;
      //this.reqModel.requestSubTypeText=this.selectedItmName;
    } else {
      this.selectedItmId = event;
    }
    if (this.reqModel.requestTypeText == TypeOfRequests.OrderingInfrastructureMaterials) {
      this.showSubTypeOfRequest = false;
    } else {
      this.showSubTypeOfRequest = true;
    }
    this.RequestViewSvc.GetSubTypeOfRequest(this.selectedItmId).subscribe(data => {
      this.listSubTypeOfRequest = data.table;
      if (this.listSubTypeOfRequest.length > 0) {
        this.GetRequestDefinitions(this.requestViewForm.value.requestType);
      }
      this.SpinnerService.hide();
      //this.SharedComponent.hideSpinner;
    });*/
  }

  bindSubTypeOfRequest_new() {       
    if (this.reqParentTypeDesc == TypeOfRequests.OrderingInfrastructureMaterials) {
      this.showSubTypeOfRequest = false;
    } 
    else if(this.isParentRequest)
    {
        this.showSubTypeOfRequest = false;
    }
    else {
      this.showSubTypeOfRequest = true;
    }
    /*this.RequestViewSvc.GetSubTypeOfRequest(this.selectedItmId).subscribe(data => {
      this.listSubTypeOfRequest = data.table;
      if (this.listSubTypeOfRequest.length > 0) {
        this.GetRequestDefinitions(this.requestViewForm.value.requestType);
      }
      this.SpinnerService.hide();
      //this.SharedComponent.hideSpinner;
    });*/
    this.GetRequestDefinitions();
  }

  GetRequestDefinitions() {
    /*if (event.target != undefined) {
      let selectedOptions = event.target['options'];
      let selectedIndex = selectedOptions.selectedIndex;
      this.reqModel.requestSubTypeText = selectedOptions[selectedIndex].text;
      this.selectedSubItmId = selectedOptions[selectedIndex].value;
    } else {
      this.selectedSubItmId = event;
    }

    this.SpinnerService.show();*/
    this.requestedDeliveryDate = '';
    //this.SharedComponent.showSpinner;
    //this.sharedService.GetRequestDefinitions(this.selectedSubItmId).subscribe(data => {
      this.sharedService.GetRequestDefinitions(this.reqSubType).subscribe(data => {
      this.reqDefinitionModel = data.table[0];

      this.DoReqDelDate = this.reqDefinitionModel.doReqDelDate;
      this.enableRelatedServices = this.reqDefinitionModel.enableRelatedServices;
      console.log(this.enableRelatedServices, "test", this.reqDefinitionModel.enableRelatedServices);

      if (this.reqDefinitionModel.ttLmax != undefined) {
        if (window.history.state.reqData == undefined) {
          var maxday = this.reqDefinitionModel.ttLmax
          if(this.reqParentType == '0007'){
            maxday = maxday-1;
          }
          var day = this.sharedService.addBusinessDays(maxday);
          this.requestedDeliveryDate = (day.getMonth() + 1) + "-" + day.getDate() + "-" + day.getFullYear();
          
        } else {
          this.requestedDeliveryDate = this.reqModel.requestedDeliveryDate.toString();
        }
        if (this.reqDefinitionModel.deliveryDateNote != undefined && this.reqDefinitionModel.deliveryDateNote !='') {
          var delnote =  this.reqDefinitionModel.deliveryDateNote.replace(/\{0\}/g, this.reqDefinitionModel.ttLmax.toString());
          delnote = delnote.replace(/<br\s*[\/]?>/gi, "\n");         
          this.requestedDeliveryDateNote = delnote;
          // yet to cross check below code
          if(this.reqDefinitionModel.ttLmin != undefined)
          {
            this.minDate = this.sharedService.addBusinessDays(this.reqDefinitionModel.ttLmin);
            //this.requestedDeliveryDateNote = (this.requestedDeliveryDateNote + '<br><br>If delivery within ' + this.reqDefinitionModel.ttLmin + ' working days is required you must initiate the urgent procedure.').replace(/<br\s*[\/]?>/gi, "\n");
          }
        }       
        this.requestViewForm.controls['requestedDeliveryDateControl'].setValue(this.datePipe.transform(this.requestedDeliveryDate, 'yyyy-MM-dd'));
      }
     /* this.requestViewForm.value.rbOneTimes = 'Yes';
      if (this.reqDefinitionModel.oneTimeAllowed == true) {
        this.btNext = "Send";
      }
      else {
        this.btNext = "Next";
      }
      this.SpinnerService.hide();*/

      this.btNext = "Next";
    
      //this.SharedComponent.hideSpinner;*/
    });

  }

  validateDate(date: Date | null): void {
    if (date) {
      if (this.reqDefinitionModel.ttLmax != undefined) {
        let deldate:Date = new Date(this.requestedDeliveryDate)
        //logic for ttlmin
        //var ttLminday = this.sharedService.addBusinessDays(this.reqDefinitionModel.ttLmin);        
        var slaMSG= "<html>You have selected a delivery date less than agreed SLA.<br><br>If you continue, this request will be handled as an urgent request.<br>(Next to filing this request, you have to follow the agreed urgent request procedures. Limitations to the amount of urgent requests may apply.)</html>"
        if(date < deldate)
        {
          this.sharedService.showMessageBoxHeader("Urgent set",slaMSG);
        }
      }
      // Example validation: check if the selected date is in the future
      /*const today = new Date();
      if (date < today) {
        alert('The selected date cannot be in the past.');
        this.myForm.get('requestedDeliveryDateControl')?.setErrors({ pastDate: true });
      } else {
        // You can show a success message if needed or clear errors
        this.myForm.get('requestedDeliveryDateControl')?.setErrors(null);
      }*/
    }
  }

  addItem() {
    console.log(this.myControl.value);
    const serviceName = typeof this.myControl.value === 'string' ? this.myControl.value :  this.myControl.value?.service;
    const serviceID = typeof this.myControl.value === 'string' ? 0 :  this.myControl.value?.id;

    if(serviceName != null && serviceName.trim() !=''){
    const matchingItems = this.items.filter(item => item.service === serviceName);
    const isPresent = matchingItems.length > 0;     
    
    if (isPresent)
    {
      this.sharedService.showMessageBox("Selected service already added in the list");
    }
    else{
    if(serviceID ==0)
    {
      this.addService(serviceName);
    }
    else
    {
      this.loadService(serviceID,serviceName);
    }
  }
}
else{
  this.sharedService.showMessageBox("Please select valid service");
}
        
  }

  selectSLADate() {
    this.requestViewForm.controls['requestedDeliveryDateControl'].setValue(this.datePipe.transform(this.requestedDeliveryDate, 'yyyy-MM-dd'));
  }

  loadService(serviceID,serviceName)
  {
    this.item = new serviceModel();
    this.item.id = serviceID;
    this.item.service = serviceName
    
    this.items.push(this.item);      
    
    this.myControl.reset();
  }

  removeItem(index: number) {
    this.items.splice(index, 1);
  }

  bindService() {   
    this.RequestViewSvc.GetService().then(data => {
      this.options = data;
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          const name = typeof value === 'string' ? value : value?.service;
          return name ? this._filter(name as string) : this.options.slice();
        }),
      );
    })  
  }

  addService(serviceName:string) {      
    this.item = new serviceModel(); this.item.id = 0;this.item.service = serviceName;  
    this.RequestViewSvc.AddService(this.item).subscribe(data => {
      var serviceID = data;   
      this.loadService(serviceID,serviceName); 
      this.bindService();      
    })  
  }

  displayFn(service: serviceModel): string {
    return service && service.service ? service.service : '';
  }

  private _filter(name: string): serviceModel[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.service.toLowerCase().includes(filterValue));
  }

  ChangeOneTime() {
    if (this.requestViewForm.value.rbOneTimes == 'Yes') {
      this.reqDefinitionModel.oneTimeAllowed = true;
      this.btNext = "Send";
    } else {
      this.reqDefinitionModel.oneTimeAllowed = false;
      this.btNext = "Next";
    }
  }
  backToMenu()
  {
    this.router.navigate(['/createNewRequest']);
  }
  GoToProjectRefandBudget() {
    this.submitted = true;
    if (this.requestViewForm.invalid) {
      return;
    }
    else {
      this.ISTCookieValue = this.cookieService.get('ISTCookie2');
      var auth = false;
      var authval = this.ISTCookieValue.substring(this.ISTCookieValue.indexOf("Authorized"));
      console.log('test auth1',authval.substring(authval.indexOf("Authorized"),authval.indexOf("&")).replace("Authorized=", ''));

      this.reqModel.serviceIdList = [];

      for (let val of this.items)
      {
        this.reqModel.serviceIdList.push(val.id);
      }

      this.reqModel.serviceList = this.items;
      this.reqModel.tor =this.reqSubType.substring(0, 2); //this.requestViewForm.value.requestSubType.substring(0, 2);
      this.reqModel.subtor = this.reqSubType.substring(2, 4);//this.requestViewForm.value.requestSubType.substring(2, 4);
      this.reqModel.requestType =this.reqParentType; //this.requestViewForm.value.requestType;
      this.reqModel.requestSubType = this.reqSubType; //this.requestViewForm.value.requestSubType;
      this.reqModel.requestTypeText = this.reqParentTypeDesc;
      this.reqModel.requestSubTypeText = this.reqSubTypeDesc;
      this.reqModel.shortDesc = this.requestViewForm.value.shortDesc;
      this.reqModel.requestedDeliveryDate = new Date(this.requestViewForm.value.requestedDeliveryDateControl);

      //to do.. make a requestDefinitionClass
      this.reqModel.requestAcceptance = this.reqDefinitionModel.requestAcceptance;
      this.reqModel.requestTTL = this.reqDefinitionModel.ttLmax;
      this.reqModel.hasApprovers = this.reqDefinitionModel.needApproval;
      this.reqModel.authorized = JSON.parse((authval.substring(authval.indexOf("Authorized"),authval.indexOf("&")).replace("Authorized=", '')).toLowerCase());
      this.reqModel.isParentRequest = this.isParentRequest;
      this.reqModel.askBudgetCode = this.reqDefinitionModel.askBudgetCode;
  
     if(this.reqDefinitionModel.hasLongDesc){
      this.sharedService.gotoNextView('longDescription',this.reqModel,this.reqDefinitionModel,this.userModel,this.loginModel);    
     }
     else if (this.reqDefinitionModel.hasProjectBudget) { 
    this.sharedService.gotoNextView('projRefAndBudgetView', this.reqModel, this.reqDefinitionModel, this.userModel, this.loginModel);
     }
     else
     {
      this.sharedService.gotoNextView('sendRequestView',this.reqModel,this.reqDefinitionModel,this.userModel,this.loginModel);    
     }
    }
  }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {SharedService} from '../Shared/shared.service'
@Injectable({
  providedIn: 'root'
})
export class CreateRequestInfoService {

  constructor(private _http: HttpClient,private sharedService:SharedService) { }
  GetRequestDefinition(requestType:string):Observable<any>{
   // return this._http.get<any[]>(this.sharedService.API_URL + 'api/settings/GetRequestDefinitions/'+ requestType);
   return this._http.get<any[]>(this.sharedService.API_URL + '/api/ISR/createNewRequest/GetRequestInfo/'+ requestType);
  };
}

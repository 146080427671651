<form novalidate [formGroup]="requestApprovalForm">
    <div style="width: 80%;">
<div class="row isr"  >
    <div class="col-lg-12" >
        <div class="card card-primary">
            <div class="card-header">
                <div class="card-title">Request Info</div>
            </div>
            <div class="card-block">                
                <div class="row">       
                    <div class="col-lg-4">
                        <div class="row">
                            <div class="col-lg-4 noRightpadding"  >
                                <label>Request number:</label>
                            </div>
                            <div class="col-lg-8">
                                <label class="labelNormal" >{{requestApprovalForm.value.requestNumber}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="row">
                            <div class="col-lg-5 noRightpadding">
                                <label>Requested by:</label>
                            </div>
                            <div class="col-lg-7">
                                <label class="labelNormal">{{requestApprovalForm.value.requestorName}}</label>
                            </div>
                        </div>
                    </div>        
                    <div class="col-lg-4">
                        <div class="row">
                            <div class="col-lg-2 noRightpadding">
                                <label>Status:</label>
                            </div>
                            <div class="col-lg-7">
                                <label class="labelNormal">{{requestApprovalForm.value.statusDesc}}</label>
                            </div>
                        </div>
                    </div>    
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="row">
                            <div class="col-lg-4 noRightpadding">
                                <label>On:</label>
                            </div>
                            <div class="col-lg-8">
                                <label class="labelNormal">{{requestApprovalForm.value.requestedOn}}</label>
                            </div>
                        </div>
                    </div>        
                    <div class="col-lg-4">
                        <div class="row">
                            <div class="col-lg-5 noRightpadding">
                                <label>Requested delivery:</label>
                            </div>
                            <div class="col-lg-7">
                                <label class="labelNormal">{{requestApprovalForm.value.requestedDelivery}}</label>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    </div>   
    </div>
    <div class="row isr"  >
        <div class="col-lg-12" >
            <div class="card card-primary">
                <div class="card-header">
                    <div class="card-title">Requestor's Info</div>
                </div>
                <div class="card-block">                
                    <div class="row">       
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-2 noRightpadding"  >
                                    <label>Requestor:</label>
                                </div>
                                <div class="col-lg-8">
                                    <label class="labelNormal">{{requestApprovalForm.value.requestorName}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-3 noRightpadding">
                                    <label>Phone:</label>
                                </div>
                                <div class="col-lg-8">
                                    <label class="labelNormal">{{requestApprovalForm.value.requestorPhone}}</label>
                                </div>
                            </div>
                        </div>        
                       
                    </div>
                 
                </div>
            </div>
        </div>   
        </div>
        <div class="row isr" *ngIf="emailBasedRequest==0" >
            <div class="col-lg-12" >
                <div class="card card-primary">
                    <div class="card-header">
                        <div class="card-title">Project Info</div>
                    </div>
                    <div class="card-block">                
                        <div class="row">       
                            <div class="col-lg-4">
                                <div class="row">
                                    <div class="col-lg-4 noRightpadding"  >
                                        <label>Ericsson Project:</label>
                                    </div>
                                    <div class="col-lg-8">
                                        <label class="labelNormal">{{requestApprovalForm.value.ericssonProject}}</label>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="ericssonProject=='Yes'">
                            <div  class="col-lg-4">
                                <div class="row">
                                    <div class="col-lg-4 noRightpadding"  >
                                        <label>Project Id:</label>
                                    </div>
                                    <div class="col-lg-8">
                                        <label class="labelNormal">{{requestApprovalForm.value.projectID}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="row">
                                    <div class="col-lg-4 noRightpadding">
                                        <label>Project Name:</label>
                                    </div>
                                    <div class="col-lg-8">
                                        <label class="labelNormal">{{requestApprovalForm.value.projectnameRequest}}</label>
                                    </div>
                                </div>
                            </div>        
                        </ng-container>
                        </div>
                     
                    </div>
                </div>
            </div>   
            </div>

    <div class="row isr"  >
        <div class="col-lg-12" >
            <div class="card card-primary">
                <div class="card-header">
                    <div class="card-title">Request Type</div>
                </div>
                <div class="card-block">                
                    <div class="row">       
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-2 noRightpadding"  >
                                    <label>Request type:</label>
                                </div>
                                <div class="col-lg-8">
                                    <label class="labelNormal">{{requestApprovalForm.value.requestType}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-3 noRightpadding">
                                    <label>Request sub type:</label>
                                </div>
                                <div class="col-lg-8">
                                    <label class="labelNormal">{{requestApprovalForm.value.requestSubType}}</label>
                                </div>
                            </div>
                        </div>        
                       
                    </div>
                 
                </div>
            </div>
        </div>   
        </div>

        <div class="row isr"  >
            <div class="col-lg-12" >
                <div class="card card-primary">
                    <div class="card-header">
                        <div class="card-title">Request File</div>
                    </div>
                    <div class="card-block">                
                        <div class="row">       
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-2 noRightpadding"  >
                                        <label>Request file:</label>
                                    </div>
                                    <div class="col-lg-8">
                                        <a href="javascript:void(0)" (click)="getTemplateFile()">{{requestApprovalForm.value.requestFile}}</a>                                        
                                    </div>
                                </div>
                            </div>     
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-3 noRightpadding"  >
                                        <label>Request description:</label>
                                    </div>
                                    <div class="col-lg-8">
                                        <label class="labelNormal">{{requestApprovalForm.value.requestDescription}}</label>
                                    </div>
                                </div>
                            </div>                       
                        </div>                     
                    </div>
                </div>
            </div>   
            </div>
            <div class="row isr"  >
                <div class="col-lg-12" >
                    <div class="card card-primary">
                        <div class="card-header">
                            <div class="card-title">Additional Info</div>
                        </div>
                        <div class="card-block">                
                            <div class="row">       
                                <div class="col-lg-9">
                                    <div class="row">
                                        <div class="col-lg-2 noRightpadding"  >
                                            <label>Additional Info:</label>
                                        </div>
                                        <div class="col-lg-9 ">
                                            <textarea rows="5" class="ebTextArea  ebTextArea_labeled_top ebTextArea_width_full requestViewMode" readonly>{{requestApprovalForm.value.additionalInfo}}</textarea>
                                        </div>
                                    </div>
                                </div>                           
                            </div>                     
                        </div>
                    </div>
                </div>   
                </div>

                <div class="row isr">
                    <div class="col-lg-12">
                        <div class="card card-primary">
                            <div class="card-header">
                                <div class="card-title">Approval Status</div>
                            </div>
                            <div class="card-block">                
                                <div class="row">       
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-4 noRightpadding"  >
                                                <div class="row">
                                                    <div class="col-lg-5 noRightpadding">
                                                        <label>Request Approval status:</label>
                                                    </div>
                                                    <div class="col-lg-7 noRightpadding">
                                                        <label class="labelNormal">{{requestApprovalForm.value.requestApprovalStatus}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 noRightpadding"  >
                                                <div class="row">
                                                    <div class="col-lg-2 noRightpadding">
                                                        <label>Set on:</label>
                                                    </div>
                                                    <div class="col-lg-10 noRightpadding">
                                                        <label class="labelNormal">{{requestApprovalForm.value.approvalStatusSetOn}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 noRightpadding"  >
                                                <div class="row">
                                                    <div class="col-lg-2 noRightpadding">
                                                        <label>by:</label>
                                                    </div>
                                                    <div class="col-lg-10 noRightpadding">
                                                        <label class="labelNormal">{{requestApprovalForm.value.approvalStatusSetBy}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                           
                                </div>                     
                            </div>
                        </div>
                    </div>   
                    </div>

                    <div class="row isr"  >
                        <div class="col-lg-12">
                            <div class="card card-primary">
                                <div class="card-header">
                                    <div class="card-title">Reason</div>
                                </div>
                                <div class="card-block">                
                                    <div class="row">       
                                        <div class="col-lg-9">
                                            <div class="row">
                                                <div class="col-lg-2 noRightpadding"  >
                                                    <label>Reason:</label>
                                                </div>
                                                <div class="col-lg-9 ">
                                                    <textarea rows="5" formControlName="rejectionReason" class="ebTextArea  ebTextArea_labeled_top ebTextArea_width_full requestViewMode">{{requestApprovalForm.value.rejectionReason}}</textarea>
                                                    <label *ngIf="emailBasedRequest==1">In case you want to reject this request, write the reason before you click on Reject.</label>
                                                </div>
                                            </div>
                                        </div>                           
                                    </div>                     
                                </div>
                            </div>
                        </div>   
                        </div>

                        <div class="row isr"  >
                            <div class="col-lg-12" >
                                <div class="card card-primary">
                                    <!-- <div class="card-header">
                                        <div class="card-title">Reason</div>
                                    </div> -->
                                    <div class="card-block">                
                                        <div class="row">       
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-8 noRightpadding"  >
                                                        <label *ngIf="!showApprovalMessage" style="color: blue;" >{{requestApprovalForm.value.requestApprovalStatusMessage}}</label>
                                                        <label *ngIf="showApprovalMessage" style="color: blue;" >{{approvalMessage}}</label>
                                                    </div>
                                                    <div class="col-lg-4 " *ngIf="this.approvalStatus=='0'" >
                                                        <div class="row" style="text-align: right;">
                                                            <div class="col-lg-4 ">
                                                                <button id="btnRequest" type="submit" name="button" (click)="ApproveRejectRequest('1')" class="ebBtn ebBtn_color_darkGreen" style="height:40px;width: 100px;">Approve                                                                 
                                                                </button> 
                                                            </div>
                                                            <div class="col-lg-3 ">
                                                                <button id="btnRequest" type="submit" name="button" (click)="ApproveRejectRequest('0')" class="ebBtn ebBtn_color_darkGreen" style="height:40px;width: 100px;">Reject                                                                    
                                                                </button> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                           
                                        </div>                     
                                    </div>
                                </div>
                            </div>   
                            </div>
    



</div>
</form>

<!-- ------------------------------------------------------------------------------------ -->





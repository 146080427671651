import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {SharedService} from '../Shared/shared.service'
import { userModel } from '../model/user.model';
@Injectable({
  providedIn: 'root'
})
export class ProfileInfoService {

  constructor(private _http: HttpClient,private sharedService:SharedService) { }
  UpdateProfile(userModel:userModel):Observable<any>{
    return this._http.post<any>(this.sharedService.API_URL + '/api/ISR/profile/UpdateProfile/' , userModel );
   // return this._http.get<any[]>(this.sharedService.API_URL + 'api/settings/GetRequestDefinitions/'+ requestType);
  // return this._http.get<any[]>(this.sharedService.API_URL + '/api/ISR/profile/UpdateProfile/'+ userModel);
  };
}

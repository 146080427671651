import { Injectable } from "@angular/core";
@Injectable()
export class userModel {
	id: number;
	userID: String;
	fullName: String;
	shortName: String;
	email: String;
	task_TR: Boolean;
	task_IPI: Boolean;
	task_PWR: Boolean;
	task_IPS: Boolean;
	task_PS: Boolean;
	task_BOIP: Boolean;
	app_Viewer: Boolean;
	app_manager: Boolean;
	app_Admin: Boolean;
	app_Tester: Boolean;
	app_SQLfull: Boolean;
	app_SQLreadonly: Boolean;
	app_TechnicalManager: Boolean;
	sU_FWchanges: Boolean;
	address: String;
	zipCode: String;
	city: String;
	phone: String;
	fax: String;
	company: String;
	devMode: boolean;
	depAcc: Boolean;
	tM: Boolean;
	task_ANY: Boolean;
	department: String;
}

@Injectable()
export class emailDefinitionModel {
	id: number;
	Toaddress: String;
	Fromaddress: String;
	Type: String;
	iPAddress: String;
}
// ISR-2105 - Security: Add 'If you didn't request it: click here to deny' feature
@Injectable()
export class revokeOtpModel {
	Fromaddress: String;
	OTPSend: string;
	iPRequest: String;
	iPRevoke: String;
}

@Injectable()
export class validateOtp {
	domain: String;
	otp: String;
	email: String;
	type: String
}

<div id="TopMenu">        
            <div class="ebSystemBar-logo">
                <img src="./assets/Images/ECON_76px.png" class="logo">                
            </div>
            
            <div class="ebSystemBar-topMenuName">
                <img src="./assets/Images/title.PNG" class="title">
            </div>             
        <!-- </div> -->
    </div> 
    <div *ngIf="islogin" class="navbarContainer ebSystemBarMenu">
        <span style="padding-left: 15px;">          
            <a title="Go back to the home page."  (click)="onNavigate()">            
                <img src="./assets/Images/home.png" class="homeIcon" >
            </a>
        </span>       
        <span style="padding-left: 10px;">
            <a title="Show the FAQ" (click)="openPopup()">
                <img src="./assets/Images/FAQ.png" class="FAQIcon"  >   
            </a>
        </span>        
        <span *ngIf="showChecklist==true" style="padding-left: 10px;">
            <a title="Reporting">
                <img mat-button [matMenuTriggerFor]="checklist" src="./assets/Images/Report.jpg" class="FAQIcon">   
            </a>
        </span>
 
        <mat-menu  #checklist="matMenu">
         <button (click)="checklistReport()" mat-menu-item>Checklist Report</button>          
       </mat-menu>     
    </div> 
        
        <div *ngIf="showQuickSearch" id="qSearchInfo" class="qSearch">
            <span id="qsearch1" title="Quick Search">
                <input type="text" [(ngModel)]="keyword"  id="txtKeyword" (keypress)="onEnterPress($event)"  class="ebInput ebInput_width_full requestSearchtxt1" 
                placeholder="Quick Search" />                       
           </span>
           <span id="qsearchb">
               <button id="btnSearchRequest" title="QuickSearch"  (click)="quickSearch();" 
                class="ebBtn ebBtn_color_darkGreen" style="min-width:20px;">  
                    <img src="./assets/Images/quickSearchNew.png" class="qSearchIcon"  >
               </button>
           </span>           
        </div>
    <!-- <div class="toolSeparetor"></div> -->
    <div *ngIf="islogin" class="logoutdiv">
        <span class="settingsMenu">
            <a href="#">{{userFullName}}</a>
            <div class="settingsMenu-content">
                <div class="eaLogoutButton">
                <button type="button" (click)="logout()" class="logoutButton" >
                        <input type="image" text="Logout"  src="./assets/Images/logout_black_16px.svg" class="logoutIcon">Logout
                     </button>                   
                </div> 
            </div>
        </span>           
    </div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
<div class="modal-dialog" role="document">
	<div class="modal-content">
	<div class="modal-header">
		<h3 class="modal-title" style="background-color:#def7d4; padding:5px;">Frequently Asked Questions</h3>
	</div>
	<div class="modal-body">
        <div class="Accordion-main scroll-ele">
                <div *ngFor="let list of data">
                
              <app-faq [data]="list"></app-faq> 
            </div>  
        </div>
<div class="modal-footer">
	<button type="button" style="background-color:#def7d4" (click)="closePopup()">
	Close
	</button>
</div>
</div>
</div> 







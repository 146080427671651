import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
import {SharedService} from '../Shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class SetApproverService {
  constructor(private _http: HttpClient,private sharedService:SharedService) {}
  GetManagers(company):Observable<any>{
    return this._http.get<any[]>(this.sharedService.API_URL + '/api/ISR/request/GetManagers/' + company);
  };
 
}

import { Component, Inject, OnInit } from '@angular/core';
import {UntypedFormGroup, FormControl, UntypedFormBuilder } from '@angular/forms';
import{RequestDetailService} from '../request-details/request-details.service';
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import { GridRequestFileComponent } from '../grid-request-file/grid-request-file.component';
import { NgxSpinnerService } from "ngx-spinner";
import { AgGridAngular } from '@ag-grid-community/angular';
import { ActivatedRoute } from '@angular/router';
import {customDateFormatPipe} from '../customDateFormatPipe';
import{RequestOverviewService} from '../request-overview/request-overview.service';
import {SharedService} from '../Shared/shared.service';
import { CookieService } from 'ngx-cookie-service';
import {GlobalParamsService} from '../global/globalParams.service'
//import { analyzeAndValidateNgModules } from '@angular/compiler';
import { DatePipe } from '@angular/common';
import{Router} from '@angular/router';
import * as moment from 'moment';
import{userModel} from '../model/user.model';

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-firewallrule',
  templateUrl: './firewallrules.component.html',
  styleUrls: ['./firewallrules.component.css'],
  providers: [userModel]
})
export class FirewallrulesComponent implements OnInit {

  public gridOptions: AgGridAngular; 
  fixedStatusImageConfig:any=[];
  taskStatusImageConfig:any=[];
  
  columnDefs:any=[];
  private rowSelection;
  private gridApi;
  private gridColumnApi;

  public gridOptionsLog: AgGridAngular
  columnDefsLog:any=[];
  private gridApiLog;
  private gridColumnApiLog;
  
  requestDetailForm: UntypedFormGroup;
  requestLogData:any=[];
  
  requestData:any=[];
  datePipe = new DatePipe("en-US");
  requestNo='';
  requestNumber='';
  requestNumberFull='';
  
  ISTCookieValue: string;
  loggedUserName: string;
  

  isFirewallRules= false;

  pgAction:string;
  pgTitle:string;  
  firewallruleList:any=[];

  fwSourceList:any=[];
  fwDestList:any=[];
  fwProtocolList:any=[];

  fwrulesDisp:any;

  constructor(private requestDetailSvc:RequestDetailService,private router:Router,  private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, private requestOverviewSvc:RequestOverviewService, private sharedService:SharedService, private cookieService: CookieService, private globalParamsService: GlobalParamsService, private objUserModel:userModel,
    private dialogRef: MatDialogRef<FirewallrulesComponent>,
    @Inject(MAT_DIALOG_DATA) data) {     
      console.log(data);
      this.pgAction = data.action;
      if(data.action == "Show") {        
        this.pgTitle = "Firewall Rules by wizzard";
        this.firewallruleList = data.firewallruleList;
        this.requestNumber = data.reqNo;
        this.requestNumberFull = data.requestNumberFull;
        //this.fwSourceList = this.firewallruleList.lstSourceList;
        //this.fwDestList = this.firewallruleList.lstDestinationList;
        //this.fwProtocolList = this.firewallruleList.lstProtocolList;
      }     
   }
  
  ngOnInit() {  

    this.ISTCookieValue = this.cookieService.get('ISTCookie2');
    console.log(this.ISTCookieValue);
    
    this.loggedUserName = this.ISTCookieValue.substring(this.ISTCookieValue.indexOf("username"),this.ISTCookieValue.indexOf("&")).replace("username=",'');
    
    console.log(this.loggedUserName); 
    console.log(this.firewallruleList);
    //console.log(this.fwSourceList);
    this.dispFirewallRules();

  }

  dispFirewallRules(){
    var max_number;
    var j;
    this.fwrulesDisp = "<table class='table table-bordered firewallruletable' width='100%' style=\"font-size: 1.2rem;\">" +
                        "<tbody>" +
                        "<tr>" +
                        "<th rowspan=2 style=\"background-color: #f3f3f3;\"></th>" +
                        "<th colspan=3 style=\"text-align: center; background-color: #f3f3f3;\">Source</th>" +
                        "<th colspan=2 style=\"text-align: center; background-color: #f3f3f3;\">Destination</th>" +
                        "<th colspan=3 style=\"text-align: center; background-color: #f3f3f3;\">Protocol</th>" +
                        "<th rowspan=2 style=\"background-color: #f3f3f3;\"></th>" +
                        "</tr>" +
                        "<tr>" +
                        "<th style='background-color: #f3f3f3;'>Name</th>" +
                        "<th style='background-color: #f3f3f3;'>IP</th>" +
                        "<th style='background-color: #f3f3f3;'>User Name</th>" +
                        "<th style='background-color: #f3f3f3;'>Name</th>" +
                        "<th style='background-color: #f3f3f3;'>IP</th>" +
                        "<th style='background-color: #f3f3f3;'>Name</th>" +
                        "<th style='background-color: #f3f3f3;'>Type</th>" +
                        "<th style='background-color: #f3f3f3;'>Port</th>" +
                        "</tr>";

    for (let item of this.firewallruleList) {
      max_number = item.lstSourceList.length;
      if(item.lstDestinationList.length > max_number) {
        max_number = item.lstDestinationList.length;
      }
      if(item.lstProtocolList.length > max_number) {
        max_number = item.lstProtocolList.length;
      }

      this.fwrulesDisp += "<tr><td rowspan='" + (max_number+1) + "'>" + item.srNo + "</td>";
      j = item.srNo; 
      for(var i=0; i< max_number; i++) {
          this.fwrulesDisp += "<tr>";
          if(item.lstSourceList[i] == null || item.lstSourceList[i] == undefined) {
            this.fwrulesDisp += "<td></td> <td></td> <td></td>";             
          }
          else {
            this.fwrulesDisp += "<td>" + item.lstSourceList[i].name + "</td>";
            this.fwrulesDisp += "<td>" + item.lstSourceList[i].ip + "</td>";
            this.fwrulesDisp += "<td>" + item.lstSourceList[i].username + "</td>";
          }
          if(item.lstDestinationList[i] == null || item.lstDestinationList[i] == undefined) {
            this.fwrulesDisp += "<td></td> <td></td>";             
          }
          else {
            this.fwrulesDisp += "<td>" + item.lstDestinationList[i].name + "</td>";
            this.fwrulesDisp += "<td>" + item.lstDestinationList[i].ip + "</td>";            
          }
          if(item.lstProtocolList[i] == null || item.lstProtocolList[i] == undefined) {
            this.fwrulesDisp += "<td></td> <td></td> <td></td>";             
          }
          else {
            this.fwrulesDisp += "<td>" + item.lstProtocolList[i].name + "</td>";
            this.fwrulesDisp += "<td>" + item.lstProtocolList[i].type + "</td>";
            this.fwrulesDisp += "<td>" + item.lstProtocolList[i].port + "</td>";
          }

          if(j == item.srNo) {
            this.fwrulesDisp += "<td rowspan='" + (max_number+1) + "'>" + item.remarks + "</td>";
            j = j+1;
          }          

          this.fwrulesDisp += "</tr>";
      }
      //this.fwrulesDisp += "<td rowspan='" + (max_number+1) + "'>" + item.remarks + "</td>";
      this.fwrulesDisp += "</tr>";

    }

    this.fwrulesDisp += "</tbody>" +
                        "</table>";

  }

  close() {
    this.dialogRef.close();
  }


}

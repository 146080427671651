<div id="statusBox">
    <form novalidate [formGroup]="statusForm">
        <div class="col-lg-12">
            <!-- Anjan - ISR-2114 - ISR Request overview: Supplier status improvements -->
            <!-- <label class="ebLabel">Status{{supplierName}}</label>
            <input type="text" id="txtStatus" [ngModel]="reqStatus" formControlName="reqStatusControl"
                [ngStyle]="{'color':statusFontColor, 'backgroundColor':statusBgColor}" class="StatusLabel" readonly>

            <input type="button" (click)="changeStatus(reqStatus)" value="Change" class="ebBtn"> -->
            <!-- Anjan - RH-2121 -  Information is not visible in ISR Supplier Status panel: -->
            <table>
                <tr>
                    <!-- <th></th>
                    <th></th>
                    <th></th>
                    <th>Supplier Remarks</th> -->
                </tr>
                <tr *ngFor="let item of allTaskData">
                    <td><label class="ebLabel">Status {{item.supplierShortName}}</label></td>
                    <td><input type="text" id="txtStatus" value={{item.statusVal}}
                            [ngStyle]="{'color':getStatusFontColor(item.statusVal), 'backgroundColor':getStatusBgColor(item.statusVal)}"
                            class="StatusLabel" readonly></td>
                    <td><input type="button" (click)="changeStatus(item.taskId, item.supplierID)" value="Change"
                            class="ebBtn"></td>
                    <td><label class="requestLabel">Reference Name:</label>{{item.referenceID}}</td>
                    <!-- <td> <label class="requestLabel">Supplier Remarks:</label> </td>
                    <td><input value={{item.supplierRemarks}} style="width:512px;background-color: rgba(0, 0, 0, 0.05);"
                            type="text" class="ebInput ebInput_labeled_top requestViewMode" readonly /></td> -->
                </tr>
            </table>

        </div>
    </form>
</div>

<!-- The Modal -->
<div class="modal" id="statusModal" [style.display]="showModal ? 'block' : 'none'">
    <div class="modal-dialog">
        <div class="modal-content">
            <form novalidate [formGroup]="changeStatusForm">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Change Status</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="hide()">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">

                    <div class="row">
                        <div class="col-lg-3">
                            <label class="ebLabel">
                                Status:
                            </label>
                        </div>
                        <div class="col-lg-6">
                            <!-- Rama, Issue No: ISR-2114, Description: ISR Request overview: Supplier status improvements -->
                            <!-- <select [(ngModel)]="taskStatusCode" formControlName="taskStatusCodeControl"  class="ebSelect form-control"> -->
                            <select formControlName="taskStatusCodeControl" [(ngModel)]="taskStatusCode"
                                class="ebSelect form-control">
                                <option [ngValue]="null">--Any--</option>
                                <option *ngFor="let stat of listAllStatus" [value]="stat.code">
                                    {{stat.description}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 5px;">
                        <div class="col-lg-3">
                            <label class="ebLabel">
                                Comments:
                            </label>
                        </div>
                        <div class="col-lg-6">
                            <!-- [ngModel]="statusComment"  -->
                            <!-- Rama, Issue No: ISR-2114, Description: ISR Request overview: Supplier status improvements -->
                            <textarea formControlName="statusCommentControl" rows="5" [ngModel]="statusComment"
                                class="ebTextArea  ebTextArea_labeled_top ebTextArea_width_full requestViewMode"></textarea>
                        </div>
                    </div>

                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <span>
                        <button (click)="updateStatus(changeStatusForm)" class="ebBtn ebBtn_color_green">Save</button>
                    </span>
                    <button type="button" class="ebBtn" data-dismiss="modal" (click)="hide()">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>
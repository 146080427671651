import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { ChangeInfoService } from '../changeInfo/changeInfo.service';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { GridRequestFileComponent } from '../grid-request-file/grid-request-file.component';
import { NgxSpinnerService } from "ngx-spinner";
import { AgGridAngular } from '@ag-grid-community/angular';
import { ActivatedRoute } from '@angular/router';
import { customDateFormatPipe } from '../customDateFormatPipe';

import { GlobalParamsService } from '../global/globalParams.service';
import { Router } from '@angular/router';

import { SharedService } from '../Shared/shared.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-changeInfo',
  templateUrl: './changeInfo.component.html',
  styleUrls: ['./changeInfo.component.css']
})
export class ChangeInfoComponent implements OnInit {

  public gridOptions: AgGridAngular
  modules = AllCommunityModules;
  columnDefs: any = [];
  private gridApi;
  private gridColumnApi;

  changeDetailForm: UntypedFormGroup;

  requestData: any = [];
  changeData: any = [];

  changeLogFileList: any[];
  relatedChangeData: any = [];

  requestNo = '';
  changeId = '';
  requestNumber = '';
  requestNumberFull = '';
  showDate = new Date();
  statusFontColor = '#2f75b5';
  statusBgColor = '#fff';
  statusColors: any = [];

  reqType: string = '';
  isStatusInfoShow: Boolean = false;
  ISTCookieValue: string;
  loggedUserName: string;
  showFilesDiv: Boolean = false;
  showRelatedChangeDiv: Boolean = false;
  statusVal: String = '';

  chRequestDesc: String = '';
  chRequestor: String = '';
  chRequestDate: any;
  chReqDelivery: any;
  chRequestStatus: String = '';

  changeLogStatus: any;
  changeLogStatusDesc: String = '';
  chStatusFontColor = '#2f75b5';
  chStatusBgColor = '#fff';

  selectedChange: any;
  changeThread: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  toolFrm: String = '';
  isContinue = false;

  constructor(private changeInfoSvc: ChangeInfoService, private SpinnerService: NgxSpinnerService, private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, private sharedService: SharedService, private cookieService: CookieService, private globalParamsService: GlobalParamsService, private router: Router) {
  }

  ngOnInit() {
    //@ViewChild('agGrid') agGrid: AgGridAngular;   
    this.changeInfoSvc.GetStatusColors().subscribe(data => {
      this.statusColors = data.table;
      this.isContinue = true;
    });

    this.changeDetailForm = this.formBuilder.group({
      requestorName: new UntypedFormControl(),
      version: new UntypedFormControl(),
      requestDescription: new UntypedFormControl(),
      longDescription: new UntypedFormControl(),
      preparedBy: new UntypedFormControl(),
      preparedOn: new UntypedFormControl(),
      executedBy: new UntypedFormControl(),
      executionOn: new UntypedFormControl(),
      executionTime: new UntypedFormControl(),
      checkedBy: new UntypedFormControl(),
      checkedOn: new UntypedFormControl(),
      categoryName: new UntypedFormControl(),
      changeLogStatusDescControl: new UntypedFormControl(),
      nodes: new UntypedFormControl(),
      changeResult: new UntypedFormControl(),
      typeOfRequest: new UntypedFormControl(),
      parentType: new UntypedFormControl()
    });

    this.route.params.subscribe(params => {
      console.log(params);
      console.log(params['changeId']);
      this.changeId = params['changeId'];
      if (params['frmTool'] != undefined) {
        this.toolFrm = params['frmTool'];
      }

      //this.cookieService.set('CLReqNo', this.requestNo );

      this.ISTCookieValue = this.cookieService.get('ISTCookie2');
      console.log(this.ISTCookieValue);
      this.loggedUserName = this.ISTCookieValue.substring(this.ISTCookieValue.indexOf("username"), this.ISTCookieValue.indexOf("&")).replace("username=", '');

      //console.log(this.loggedUserName);    


      var selectedChNo = this.changeId;
      //console.log(this.cookieService.get('CLChangeNo'));
      console.log(selectedChNo);

      this.GetChangeNoInfo(this.changeId);

      //this.GetRelatedChangeLog(this.requestNumber);

    });

    //this.requestOverviewForm.get('requestListType').setValue(1);
    //this.bindRequestCategory();
    this.initGrid();
  }

  getStatus(message: string) {
    this.statusVal = message;
  }

  GetChangeNoInfo(chid: string, isRel = 0) {
    this.showRelatedChangeDiv = false;
    this.changeInfoSvc.GetChangeNoInfo(chid).subscribe(cdata => {
      //console.log(cdata);
      if (cdata.changeId == 0) {
        // alert("No Change Information found.");
        this.sharedService.showMessageBox("No Change Information found.");
      }
      else {
        this.changeData = cdata;
        console.log(this.changeData);
        var statVal = this.changeData.requestStatusDesc;
        this.chRequestStatus = this.changeData.requestStatusDesc;
        this.reqType = this.changeData.requestType;
        this.requestNumberFull = this.changeData.requestNumberFull;
        this.chRequestDesc = this.changeData.requestDescription;
        this.chRequestor = this.changeData.requestorName;
        this.chRequestDate = this.changeData.requestDate;
        this.chReqDelivery = this.changeData.requestedDeliveryD;
        this.changeThread = this.changeData.thread;
        this.changeLogStatus = this.changeData.changeStatus;
        this.changeLogStatusDesc = this.changeData.changeStatusDesc;
        var chStatusVal = this.changeData.changeStatusDesc;

        console.log(statVal);
        console.log(this.statusColors);
        if (this.isContinue) {
          this.statusFontColor = this.statusColors.filter(function (stat) {
            return stat.description == statVal;
          })[0].fontColor;

          this.statusBgColor = this.statusColors.filter(function (stat) {
            return stat.description == statVal;
          })[0].backgroundColor;
        }

        if (this.isContinue) {
          this.chStatusFontColor = this.statusColors.filter(function (stat) {
            return stat.description == chStatusVal;
          })[0].fontColor;
          this.chStatusBgColor = this.statusColors.filter(function (stat) {
            return stat.description == chStatusVal;
          })[0].backgroundColor;
        }

        this.requestNumber = this.requestNumberFull.substring(5, this.requestNumberFull.length);
        if (isRel == 0) {
          this.GetRelatedChangeLog(this.requestNumber);
        }

        this.GetChangeLogFiles(this.changeData.thread);
        this.selectedChange = this.changeData.changeId;
        //this.cookieService.set('CLChangeNo', this.changeData.changeId);

      }

    });

  }

  GetRelatedChangeLog(reqNo: string) {
    this.changeInfoSvc.GetRelatedChangeLog(reqNo).subscribe(cdata => {
      console.log(cdata);
      if (cdata.table.length == 0) {
        //alert("No Related Change Log found.");
        this.showRelatedChangeDiv = false;
      }
      else {
        this.showRelatedChangeDiv = true;
        this.relatedChangeData = cdata.table;
        console.log(this.relatedChangeData);

      }

    });

  }


  GetChangeLogFiles(changeId: any) {
    this.changeInfoSvc.GetChangeLogFiles(changeId).subscribe(fdata => {
      //console.log(fdata);
      console.log(fdata.files.length);
      if (fdata.files.length == 0) {
        //alert("No Change Information found.");
        this.showFilesDiv = false;
      }
      else {
        this.changeLogFileList = fdata.files;
        console.log(this.changeLogFileList);
        this.showFilesDiv = true;

      }

    });

  }


  getChangeFile(fileData: { fileVPath: string; }) {
    console.log(fileData);

    var url = this.sharedService.API_URL + "GetFile.ashx?file=" + fileData.fileVPath;
    window.open(url);

  }

  onGridReady(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;

    try {
      (param.api as any).context.beanWrappers.tooltipManager.beanInstance.MOUSEOVER_SHOW_TOOLTIP_TIMEOUT = 0;
    } catch (e) {
      console.error(e);
    }
  }

  initGrid() {
    this.gridOptions = <AgGridAngular>{
      context: {
        componentParent: this
      },
      defaultColDef: {
        sortable: true,
        filter: true
      },
    };

    this.columnDefs = [
      { headerName: 'Change ID', field: 'changeId', width: 100 },
      { headerName: 'Status', field: 'changeStatusDesc', width: 180, resizable: true, tooltipField: 'changeStatusDesc' },
      { headerName: 'Executed By', field: 'executedBy', width: 200, resizable: true, tooltipField: 'executedBy' },
      { headerName: 'Executed On', width: 250, field: 'executionOnDate', tooltipField: 'executionOnDate' }

    ];


    //this.gridOptions.api.forEachNode(node=> node.rowIndex ? 0 : node.setSelected(true));

  }
  //valueGetter: this.executedDateTimeInfo
  executedDateTimeInfo(params) {
    return params.data.executionOn + " " + params.data.executionTime;
  }

  onSelectionChanged() {
    var selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length === 1) {
      this.GetChangeNoInfo(selectedRows[0].changeId, 1);
    }

  }

  onRowDataChanged() {
    //console.log(this.selectedChange);
    this.gridOptions.api.forEachNode(node => {
      //console.log(node);
      //console.log(node.data.changeId);
      if (node.data.changeId == this.selectedChange) {
        node.setSelected(true);
      }
    });
  }

  gotoRequestPage() {
    //this.cookieService.set('CLChangeNo', '');
    this.cookieService.set('ReqNoDisp', this.requestNumberFull);
    this.cookieService.set('CLReq', 'yes');

    this.globalParamsService.selectedRequestCategory.next("9801");

    this.router.navigate(['/request/' + this.requestNumberFull]);

  }



}

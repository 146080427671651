<div class="mainContainer" *ngIf="isLogin" >
    <div class="verticalContainer">
<div class="horizontalContainer">

    <div class="card">   
        <div class="card-header">Login</div>
        <div class="card-element">
            <!--<form [formGroup]="form" (ngSubmit)="onSubmit()">-->
                <form [formGroup]="form">
                <div style="display: flex;flex-direction: column;">      
                    <label class="form-label-top">You can login by using 1 of following methods:</label>    
                    <div class="icon-text1" >
                        <div class="icon-text">
                            <mat-icon class="list_bullet">lens</mat-icon>
                            <span class="form-label-tp">If your company uses Microsoft accounts, click Auto-Login.</span>
                         </div>
                    <!--<div style="display: flex;">
                    <mat-icon class="list_bullet">lens</mat-icon>
                    <label class="form-label-tp">If your company use Microsoft accounts, click Auto-Login</label>
                </div>-->
                    <div style="float: right;">  
                    <button [disabled]="loading" class="button-eric-login" 
                    title="Microsoft User's Login" id="auto-login"
                    (click)="ericssonLoginClick()" autofocus> Auto-Login</button>
                    </div>
    
                    </div>
                    <div class="icon-text">
                        <mat-icon class="list_bullet">lens</mat-icon>
                        <span class="form-label-tp">Enter your business email address to receive a one-time password.</span>
                    </div>
                        <!--<mat-icon class="list_bullet">lens</mat-icon>                 
                        <label class="form-label-main">Enter your business email address to use ISR. A one-time password will be send to this email address for authentication.</label>
                        -->
                    <div class="email-content">
                    <label class="form-label">Email Address</label>
                    <input type="text" id="id_email" formControlName="username" (keydown.enter)="focusElement($event)"
                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors"  class="invalid-feedback">
                        <!--<div *ngIf="f.username.errors.required">Username is required</div>-->
                         <div>Enter valid Email address</div>
                    </div>  
                </div>              
                </div>
                <!-- <div class="mb-3">
                    <label class="form-label">Password</label>
                   <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>-->
                <div  style="margin-top: 6px; float: right;">         
                <button [disabled]="loading" [disabled]="f.username.errors"            
                title="Generate one-time password and send it to the specified email address"
                class="button-eric-login"  id="send-otp" 
                 (click)="onSubmit()">
                <span *ngIf="loading"  class="spinner-border spinner-border-sm me-1"></span>
                    Send OTP
                </button>
                    <!--<button [disabled]="loading" [disabled]="f.username.errors" class="btn btn-primary" style="font-size: 12px; border-radius: 10px; width:120px">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
                        Login
                    </button>-->
                    <!--<button [disabled]="loading" class="btn btn-primary">
                        <a routerLink="../register" class="btn btn-link">Register</a>-->
                </div>
               
            </form>
           
        </div>    
    </div>     
    <div>
        <a title="Show the FAQ" (click)="openPopup()">
        <img src="./assets/Images/FAQLogin.png" class="btFAQ">
        </a>
    </div>  
    
    <div class="cardWarning">
        <div class="card-body">    
           
          <p class="form-label-tp">This system is for authorized users only. Use of this computer
            system and/or network without authority, or in excess of your authority,
            is strictly prohibited. Monitoring of transmissions or transactional
            information may be conducted to ensure the proper functioning and security
            of electronic communication resources. Anyone using this system expressly
            consents to such monitoring and is advised that if such monitoring reveals
            possible criminal activity or policy violation, system personnel may
            provide the evidence of such monitoring to law enforcement or to other
            senior officials for disciplinary action.
        </p>
    </div>
        </div>  
    
    

</div>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h3 class="modal-title" style="background-color:#def7d4; padding:5px;">Frequently Asked Questions</h3>
        </div>
        <div class="modal-body">
            <div class="Accordion-main scroll-ele">
                    <div *ngFor="let list of faq_data">
                    
                  <app-faq [data]="list"></app-faq> 
                </div>  
            </div>
    <div class="modal-footer">
        <button type="button" style="background-color:#def7d4" (click)="closePopup()">
        Close
        </button>
    </div>
    </div>
        </div> 

</div>



  

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SharedService} from '../Shared/shared.service'
import { promise } from 'protractor';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsrComponentService {

  constructor(private _http: HttpClient,private sharedService:SharedService) { }
  CheckForUnfinished(email):Observable<any>{
    return this._http.post<any[]>(this.sharedService.API_URL + '/api/ISR/createNewRequest/CheckForUnfinished',email);
  }
}

import { Component, OnInit } from '@angular/core';
import{RequestOverviewService} from '../request-overview/request-overview.service';
import {SharedService} from '../Shared/shared.service';
@Component({
  selector: 'app-grid-request-file',
  templateUrl: './grid-request-file.component.html',
  styleUrls: ['./grid-request-file.component.css']
})
export class GridRequestFileComponent implements OnInit {
  params: any; 
  showFileLink:Boolean=true;

  constructor(private requestOverviewSvc:RequestOverviewService,private sharedService:SharedService) { }
  agInit(params: any): void {
    this.params = params; }

  ngOnInit() {
  }
  getRequestFile(){
    //alert(this.params.data);
    this.requestOverviewSvc.GetISRRequestFile(this.params.data.requestNumber.substring(0,4),this.params.data.requestNumber.substring(5,this.params.data.requestNumber.length)).subscribe(data=>{
      if(data.filePath==""){
        //alert("No File Present.");
        this.showFileLink = true;

      }else{
        this.showFileLink = true;
        var url = this.sharedService.API_URL + "GetFile.ashx?file=" + data.filePath;
        window.open(url);
      }      
    });
  }
}

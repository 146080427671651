import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { SharedService } from '../Shared/shared.service'
import { promise } from 'protractor';
import { emailDefinitionModel, revokeOtpModel } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private _http: HttpClient, private sharedService: SharedService) { }

  SendSmtpEmailDefinitionsMail(emailDefinitionModel: emailDefinitionModel): Observable<any> {
    return this._http.post(this.sharedService.API_URL + 'api/SendSmtpEmailDefinitionsMail', emailDefinitionModel)
  };

  // ISR-2105 - Security: Add 'If you didn't request it: click here to deny' feature
  RevokeOTPEmailDefinitionsMail(revokeOtpModel: revokeOtpModel): Observable<any> {
    return this._http.post(this.sharedService.API_URL + 'api/RevokeOTPEmailDefinitionsMail', revokeOtpModel)
  }


  data = [{
    title: 'How do I get an account for this site?',
    description: "When your company is enabled to use this site, you don't need to request for an account. Just use auto-login, or request an one-time password on your companies email address.If you are a third party (providing work for an enabled company), please contact the enabled company."
  },
  //{title:'I forgot my password, how do I get a new one?',description:'Click on REQUEST PASSWORD in the login panel. If your email address is found in the database, a new password will be generated and sent to the stored email address.'},
  { title: "I received an email containing an OTP, but didn't request one?", description: "Within this email it's stated who to report this to. Never give the OTP to anyone, we'll never be asking about it." },
  //{title:"I am very sure I entered the right password, but the page stays on the login screen.",description:"Remove the cookies for this or all sites."},
  { title: "What browser should I use?", description: "Our site is developed and tested for latest versions of Microsoft Edge and Google Chrome. Although it might work well with other browsers, these will give the best results." },
  { title: "What happens if I click on Log off at the top of the screen?", description: "This will remove the cookie with your log on data from your computer preventing others who might use your computer from creating a request in your name. When the log off is not used, this websites cookie will remove itself after 240 minutes." },
  { title: "What happens if I click on Restart at the top of the screen?", description: "This will remove all entered data from this session. You will be redirected to the first screen and you can start a new request." },
  //{title:"Some text does not appear to fit right.",description:"This may be for example text within buttons, or within labels. Also some text to be shown within a tooltip might be shown through the text. Please use the latest version of Microsoft Edge or Chrome and maybe clear the local browsers cache."},
  { title: "Do I need to hurry, while filling in this webform?", description: "No, you have plenty of time (24 hours) to complete your request. For IP Infrastructure, Transmission Cabling, Power and Firewall requests your input is stored each time you click on a NEXT button. For other request types only the basic request data will be stored. You can continue with a stored request at any time you wish. An exclamation mark on the NEW REQUEST button indicates an unfinished request." },
  { title: "Why do I stay on the login page after login?", description: "This happens when you login with a one-time user account and did not use the link from the email." },
  { title: "The website says the certificate is not ok.", description: "This happens when you do not use the right url. You should use https://isr.enn.ericsson.net." },
  ]

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { StatusChangeService } from './status-change.service';
import { RequestDetailService } from '../request-details/request-details.service';
import { SharedService } from '../Shared/shared.service';
import { NgxSpinnerService } from "ngx-spinner";
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-status-change',
  templateUrl: './status-change.component.html',
  styleUrls: ['./status-change.component.css']
})
export class StatusChangeComponent implements OnInit {
  @Input('requestNo') requestNo: string;
  //@Input('requestType') requestType:string;

  @Output() statusVal = new EventEmitter<string>();

  reqStatus: string = 'N.a.';
  supplier: string = '';
  supplierName: string = '';
  changeStatusForm: UntypedFormGroup;
  statusForm: UntypedFormGroup;

  taskData: any = [];
  allTaskData: any = [];
  statusFontColor = '#000';
  statusBgColor = '#e7e6e6';
  statusColors: any = [];
  requestType: string = '';
  requestNoPart: string = '';

  showModal: boolean;
  listAllStatus: any = [];
  taskStatusCode = 512;
  statusComment: string = '';
  formData: FormData = new FormData();
  taskId = 0;
  supplierID = 0;
  oldStat: any;
  ISTCookieValue: string;
  loggedUserName: string;

  constructor(private formBuilder: UntypedFormBuilder, private router: Router, private changeStatusSvc: StatusChangeService, private requestDetailSvc: RequestDetailService, private SpinnerService: NgxSpinnerService, private cookieService: CookieService) { }

  ngOnInit() {

    this.statusForm = this.formBuilder.group({
      reqStatusControl: new UntypedFormControl()
    });
    this.changeStatusForm = this.formBuilder.group({
      taskStatusCodeControl: new UntypedFormControl(),
      statusCommentControl: new UntypedFormControl()
    });

    this.ISTCookieValue = this.cookieService.get('ISTCookie2');
    console.log(this.ISTCookieValue);
    this.loggedUserName = this.ISTCookieValue.substring(this.ISTCookieValue.indexOf("username"), this.ISTCookieValue.indexOf("&")).replace("username=", '');
    console.log(this.loggedUserName);

    this.requestType = this.requestNo.substring(0, 4);
    this.requestNoPart = this.requestNo.substring(5, this.requestNo.length);


    if (this.requestNo.substring(0, 4) == "1427") {
      this.supplier = "FWPC Lvl2";
    } else {
      this.supplier = "Cognizant";
    }
    this.supplier = this.supplier.toLowerCase();

    this.requestDetailSvc.GetStatusColors().subscribe(data => {
      this.statusColors = data.table;
      //vembu- ISR-2121 Console error: fontColor - cannot read properties of undefined
    //});

    this.changeStatusSvc.GetSupplierTaskStatus(this.supplier, this.requestType, this.requestNoPart).subscribe(data => {
      //console.log(data);
      if (data.table.length > 0) {
        this.allTaskData = data.table;
        this.taskData = data.table[0];
        console.log('this.allTaskData - ' + JSON.stringify(this.allTaskData));
        this.supplierName = '';
        //Anjan - ISR-2114 - ISR Request overview: Supplier status improvements
        if (this.requestType == '1428') {
          this.supplierName = ' ' + this.taskData.supplierShortName;
        }
        this.reqStatus = this.taskData.statusVal;
        this.taskStatusCode = this.taskData.taskStatus;
        this.statusComment = this.taskData.supplierRemarks;
        this.taskId = this.taskData.taskId;
        this.oldStat = this.taskData.taskStatus;

      }
      else {
        this.reqStatus = 'N.a.';
        this.taskStatusCode = 512;
        this.statusComment = '';
        this.taskId = 0;
        this.oldStat = 512;
      }

      var statVal = this.reqStatus;
      //console.log(statVal);
      //console.log(this.reqStatus);
      this.statusFontColor = this.statusColors.filter(function (stat) {
        return stat.description == statVal;
      })[0].fontColor;

      this.statusBgColor = this.statusColors.filter(function (stat) {
        return stat.description == statVal;
      })[0].backgroundColor;

      this.statusVal.emit(this.reqStatus);
      //console.log(this.taskId);      

    });
    //vembu- ISR-2121 Console error: fontColor - cannot read properties of undefined
  });
    this.changeStatusSvc.GetStatusList().subscribe(data => {
      this.listAllStatus = data.table;
      //console.log(this.listAllStatus);
    });

  }

  changeStatus(taskId, supplierID) {
    //console.log('taskId - ' + taskId);
    var data = this.allTaskData.filter(function (itmId) {
      return itmId.taskId == taskId && itmId.supplierID == supplierID;
    });
    //console.log('Filterd Data - ' + JSON.stringify(data));

    this.taskId = data[0].taskId;
    this.reqStatus = data[0].statusVal;
    this.supplierID = data[0].supplierID;
    //console.log('data.taskStatus - ' + data.taskStatus);
    this.changeStatusForm.get('taskStatusCodeControl')?.setValue(data[0].taskStatus);
    //this.statusComment = data.supplierRemarks;
    this.changeStatusForm.get('statusCommentControl')?.setValue(data[0].supplierRemarks);
    this.supplier = data[0].supplierShortName;
    this.taskId = data[0].taskId;
    this.oldStat = data[0].taskStatus;
    this.showModal = true; // Show-Hide Modal Check
  }
  //Bootstrap Modal Close event
  hide() {
    this.showModal = false;
  }

  getStatusFontColor(statusValue) {
    return this.statusColors.filter(function (stat) {
      return stat.description == statusValue;
    })[0].fontColor;
  }

  getStatusBgColor(statusValue) {
    return this.statusColors.filter(function (stat) {
      return stat.description == statusValue;
    })[0].backgroundColor;
  }

  updateStatus(changeStatusForm) {
    console.log(changeStatusForm.value);
    //console.log(this.taskStatusCode);
    console.log(this.oldStat);

    if (changeStatusForm.value.taskStatusCodeControl != this.oldStat && changeStatusForm.value.taskStatusCodeControl != null) {
      this.SpinnerService.show();
      //console.log(this.taskId);
      // if (changeStatusForm.value.statusCommentControl == null || changeStatusForm.value.statusComment == undefined) {
      if (changeStatusForm.value.statusCommentControl == null || changeStatusForm.value.statusCommentControl == undefined) {
        changeStatusForm.value.statusCommentControl = '';
      }
      //console.log(changeStatusForm.value);

      var _formData = {
        requestNo: this.requestNoPart,
        status: changeStatusForm.value.taskStatusCodeControl,
        requsetType: this.requestType,
        supplierRemarks: changeStatusForm.value.statusCommentControl,
        requestNoFull: this.requestNo,
        supplierName: this.supplier,
        taskId: this.taskId,
        userName: this.loggedUserName
      }

      console.log(_formData);

      this.formData = new FormData();
      this.formData.append('formData', JSON.stringify(_formData));

      this.changeStatusSvc.UpdateTaskStatus(this.formData).subscribe(data => {
        if (data != "") {
          // this.open('hello');
        } else {

        }
        this.SpinnerService.hide();
        this.showModal = false;
        //this.gotoISRDetailPage();
        this.ngOnInit();
        this.statusVal.emit(this.reqStatus);
        window.location.reload();
      });
    }
    else {
      this.showModal = false;
      changeStatusForm.value.taskStatusCodeControl = this.oldStat;
      this.taskStatusCode = this.oldStat;
    }
  }
}

import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { FormControl,Validators } from '@angular/forms';
import { userModel } from '../model/user.model';
import { SharedService } from '../Shared/shared.service';
import { CookieService } from 'ngx-cookie-service';
import { ProfileInfoService } from './profile-info.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [userModel]
})
export class ProfileInfoComponent implements OnInit {
  pf_name:String = '';
  pf_department:String ='';
  pf_email:String = '';
  pf_mobile:String = '';
  //isValid:boolean = true;
  isError = false;
  errMessage:String = '';

  resultData: any;
  str: String = '';
  ISTCookieValue: string = '';


  constructor(private sharedService:SharedService,private objUserModel:userModel,
    private cookieService: CookieService,private profileInfoService:ProfileInfoService,
    private router:Router) { }
  //emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  //ctrl_email = new FormControl('', [Validators.required, Validators.email]);
  ctrl_name = new FormControl('', [Validators.required]);
  ctrl_department=new FormControl('', [Validators.required]);
  ctrl_mobileNo=new FormControl('', [Validators.required,Validators.pattern('^((\\+?[1-9][0-9])|0) ?-?(6 ?-? ?[0-9\\. ]{8,}|[1-9][0-9]{1,2} ?-? ?[0-9\\. ]{7,}|[1-9]{2}[0-9] ?-? ?[0-9\\. ]{6,})$')]);

  
  ngOnInit(): void {
    // var searchElement = document.getElementById('qSearchInfo');
    // searchElement.style.display = "none";
    this.LoadData();
  }

  LoadData(){
    try {
      this.ISTCookieValue = this.cookieService.get('ISTCookie2');
      this.objUserModel.email = this.ISTCookieValue.substring(this.ISTCookieValue.indexOf("username"), this.ISTCookieValue.indexOf("&")).replace("username=", '');
      this.sharedService.GetUserByEmail(this.objUserModel).subscribe(data=>{
        this.objUserModel=data.table[0];
        if (data != undefined && data != null && data.table.length > 0 && data.table[0] != null) {
        //this.pf_name= this.objUserModel.fullName; //data.table[0].fullName;          
        //this.pf_department =this.objUserModel.department; //data.table[0].department;
        this.pf_email = this.objUserModel.email;
        //this.pf_mobile = this.objUserModel.phone;
        this.ctrl_name.setValue(this.objUserModel.fullName.toString());
        this.ctrl_department.setValue(this.objUserModel.department.toString());
        this.ctrl_mobileNo.setValue(this.objUserModel.phone.toString());
        //this.ctrl_mobileNo = this.pf_mobile;
        }
      }); 
    
      /*this.createNewRequestSVC.GetMenu(this.email).subscribe(data => {
        this.resultData = data;       
      });*/
    }
    catch (e) {
      console.log(e);
    }
       
   
  }

validateMobileNumber(){
 // console.log(this.ctrl_mobileNo);
  /*if(this.ctrl_mobileNo.hasError('required'))
  {
  return 'Please Enter Mobile No!';
  }
  else {*/
  return this.ctrl_mobileNo.hasError('pattern') ? 'This is not a valid phone number!' : '';
  //}
}

  getErrorMessage() {
    this.isError = false;
    var err = '';
   
    var head = "<html><body>One or more fields is/are not correct:</br> <ul>"
  
    if (this.ctrl_name.hasError('required')) {      
      this.isError = true; 
      err = err + "<li>You have to fill in your name.</li>";
      //console.log('err',this.isError);
      //return 'You must enter a value';
    }
    if(this.ctrl_department.hasError('required'))
    {
      this.isError = true; 
      err = err + "<li>You have to fill in the name of your department.</li>";
    }
    if(this.ctrl_mobileNo.hasError('required'))
    {
      this.isError = true; 
      err = err + "<li>You have to fill in your phone number.</li>";
    }
    if(this.ctrl_mobileNo.hasError('pattern') &&  !this.isError)
    { 
      err = "";   
      return false;          
    }
    
if(this.isError == true)
{ //this.isValid = false;
  this.errMessage = head + err + "</ul><body></html>";
  return false;//this.errMessage
}
else
{
  //this.isValid = true;
  return true;
}
    }
    //return this.ctrl_email.hasError('email') ? 'Not a valid email' : '';
  
  btnCancel(){
    this.router.navigate(['/home']);
  }


  btnProfileSave(){
    //Yet to add validations here
    //var str= this.pf_name;
    
    if(this.getErrorMessage()){
    this.objUserModel.fullName = this.ctrl_name.value; //this.pf_name;
    this.objUserModel.department = this.ctrl_department.value;
    this.objUserModel.email = this.pf_email;
    this.objUserModel.phone = this.ctrl_mobileNo.value;

    this.profileInfoService.UpdateProfile(this.objUserModel).subscribe(data=>{
      //this.objUserModel=data.table[0];
      //var resp = data;
      var resp = data.table[0].resp;
      //console.log(resp);
      if (resp == "0")
      {
       //alert("please contact ENN tooling!"); 
       this.sharedService.showMessageBox("please contact ENN tooling!");
       return;
      }
      else if (resp == "3")
      {
        //alert("This name already exists. Please enter a unique name.");
        this.sharedService.showMessageBox("This name already exists. Please enter a unique name.");
        return;
      }
      else if (resp == "4" || resp =="1")
      {
        sessionStorage.setItem('isprofile','true');
      }
     
      this.router.navigate(['/home']);
     
      /*If (dsResult.Tables.Count > 0) Then
            If (dsResult.Tables(0).Rows.Count > 0) Then
                If (dsResult.Tables(0).Rows(0)(0).ToString() = "0") Then
                    Messagebox("Error", "Error, please contact ENN tooling!")
                    Exit Sub
                ElseIf (dsResult.Tables(0).Rows(0)(0).ToString() = "3") Then
                    Messagebox("Error", "This name already exists. Please enter a unique name.")
                    Exit Sub
                End If
            End If
        End If*/
    });
  }
}

}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
import {SharedService} from '../Shared/shared.service'
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class RequestOverviewService {

  constructor(private _http: HttpClient,private sharedService:SharedService) {}
  GetRequestOverviewCategory():Observable<any>{
    return this._http.get<any[]>(this.sharedService.API_URL + '/api/ISR/requestOverview/GetRequestOverviewCategory');
  };
  // GetRequestOverviewCategory():Promise<any>{
  //   var data1;
  //    this._http.get<any[]>(this.sharedService.API_URL + '/api/ISR/requestOverview/GetRequestOverviewCategory').toPromise()
  //    .then(
  //     function(data){
  //       data1= data;
        
  //     },function(err){
  // data1= err;
  //     })
  //     return data1;
  // };
  GetRequestOverviewRequestType(parentId):Observable<any>{
    return this._http.get<any[]>(this.sharedService.API_URL + '/api/ISR/requestOverview/GetRequestOverviewRequestType/' + parentId);
  }
  GetISRRequestOverviewData(requestCategory,requestType,requestListType):Observable<any>{
    return this._http.get<any[]>(this.sharedService.API_URL + '/api/ISR/requestOverview/GetISRRequestOverviewData/' + requestCategory + '/' + requestType + '/' + requestListType);
  }
  GetISRRequestFile(prerequestNo,requestNo):Observable<any>{
    return this._http.get<any[]>(this.sharedService.API_URL + 'api/ISR/requestOverview/GetRequestFile/' + prerequestNo + '/' + requestNo);
  }

  GetQuickSearchRequestOverviewResult(formdata):Observable<any>{
    //return this._http.get<any[]>(this.sharedService.API_URL + '/api/ISR/requestOverview/PostQuickSearchRequestOverviewResult/' + requestCategory );
    return this._http.post<any[]>(this.sharedService.API_URL + 'api/ISR/requestOverview/PostQuickSearchFromKeyword/', formdata);
  }

  loadSearchForm():Observable<any>{
    return this._http.get<any[]>(this.sharedService.API_URL + '/api/searchForm');
  }

  findSearchData(reqData):Observable<any>{
    
    return this._http.post<any[]>(this.sharedService.API_URL + '/api/searchRequestData/', reqData);
  };

  GetISRRequestOverviewSupplierData(supplierId):Observable<any>{
    
    return this._http.get<any[]>(this.sharedService.API_URL + '/api/ISR/requestOverview/GetISRRequestOverviewSupplierData/'+ supplierId);
  };

  GetRequestOverviewSupplier():Observable<any>{
    return this._http.get<any[]>(this.sharedService.API_URL + '/api/ISR/requestOverview/GetRequestOverviewSupplier');
  };
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
import {SharedService} from '../Shared/shared.service'

@Injectable({
  providedIn: 'root'
})
export class StatusChangeService {

  constructor(private _http: HttpClient,private sharedService:SharedService) { }

  GetSupplierTaskStatus(supplier,reqType,requestNo):Observable<any>{
    return this._http.get<any[]>(this.sharedService.API_URL + 'api/ISR/request/GetSupplierTaskStatus/' + supplier + '/' + reqType + '/' + requestNo);
  }

  GetStatusList():Observable<any>{
    return this._http.get<any[]>(this.sharedService.API_URL + 'api/ISR/request/getStatusList');
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data'
  })}

  UpdateTaskStatus(formData):Observable<any>{     
    //const formData: FormData = new FormData();  
    
    const HttpUploadOptions = {
        headers: new HttpHeaders({ "Accept": "application/json" })
    }
    //formData.append('templatefile',req.templateFile);
    return this._http.post<any[]>(this.sharedService.API_URL + '/api/ISR/request/UpdateTaskStatus/', formData,HttpUploadOptions);
  };

}

export enum StatusList  {
    Creating = 0,
    Requesting = 1,
    WaitingApproval = 2,
    Assigned = 4,
    WaitingDepartment = 8,
    WaitingCustomer = 16,
    WaitingSupplier = 32,
    WorkInProgress = 64,
    Canceled = 128,
    Completed = 256,
    Na = 512,
    New = 1024,
    Open = 2048,
    Closed = 4096
}
export enum StatusNames  {
    Creating = 'Creating',
    Requesting = 'Requesting',
    WaitingApproval = 'WaitingApproval',
    Assigned = 'Assigned',
    WaitingDepartment = 'WaitingDepartment',
    WaitingCustomer = 'WaitingCustomer',            
    WorkInProgress = 'WorkInProgress',
    Canceled = 'Canceled',
    Completed = 'Completed',
    Na = 'N.a.',
    New = 'New',
    Open = 'Open',
    Closed = 'Closed'
}
export enum TypeOfRequests{
    NRFC = 'Request Offer (New project / Network Request For Change)',
    Design='Design',
    EricssonInternal='Ericsson Internal',
    InstallHardware='Install Hardware',
    ExpansionOnExistingSystem='Expansion on existing system',
    RemoveCabling='Remove cabling',
    IMAC='IP Services (IMAC)',
    IPConnectivity='IP Connectivity',
    MinorNetworkChange='Minor Network Change',
    NumberBlockRequest='Number block request',
    RequestCorporateImplementation='Request Corporate Implementation',
    Roaming='Roaming',
    Ziggo='Ziggo',
    HELP='HELP',
    TechnologyCenterRequest='Technology Center Request',
    OrderingInfrastructureMaterials='Ordering Infrastructure Materials'
}
export enum SubTypeOfRequests{
    Decommissioning='Decommissioning',
    FirewallRFC='Firewall RFC',
    DNS_Mobile='DNS (Mobile)',
    Loadbalancer='Loadbalancer',
    InterconnectImplementationRequest='Interconnect Implementation Request',
    Citrix_XARAT_CAAS_VPN='Citrix (XARAT/CAAS-VPN)',
    Corporate_Text_Volume_SMS_VPN='Corporate Text Volume (SMS-VPN)',
    MVNO_VPN='MVNO (VPN)',
    IPDesignMobile='IP Design Mobile',
    ServiceIntakeForm='Service Intake Form',
    EMTUReservation='EMTU Reservation',
    OMINFRequest='OMINF Request',
    AutonomousSystemNumber_ASN='Autonomous System Number (ASN)',
    WorkOrderImplementation='Work order implementation',
    NewNational_VF_NL_NumberRangeRequest='New national (VF-NL) numberrange request',
    NewUpdateRemoveM2MGDSP='New / Update / Remove M2M GDSP',
    NewUpdateRemoveCorporateVoice='New / Update / Remove Corporate Voice',
    NewUpdateRemoveCGAData='New / Update / Remove CGA data',
    NewRoamingRequest='New roaming request',
    RoamingWillStart='Roaming will start',
    IR21VodafoneNL='IR21 Vodafone NL',
    SMSWelcome='SMSWelcome'
    //to do
}
export enum TaskList  {
    SWP= 'IPI-SWP',
    IPI= 'IPI-IPA',
    CDP= 'IPI-CDP',
    POS= 'TR-POS',
    CAB= 'TR-CAB',        
    TRSUP= 'TR-SUP',
    SUPPORT= 'TR-TRS',
    PW= 'PW-PWR',  
    PWSUP= 'PW-SUP',
    ACT= 'PW-ACT',  
    FWR= 'IPS-FWR',  
    DNS= 'IPS-DNS',  
    LBR= 'IPS-LBR',
    ANYIR= 'ANY-IR',
    ANYCC= 'ANY-CC',
    ANYDEL= 'ANY-DEL'
}
<div class="container">
  <div class="left">
    <button class="button-main" type="button" (click)="btnClick();">Create NEW Request <span
        title="You have an unfinished request." *ngIf="showUnfinishedRequest"
        style="font-size: x-large;">!</span></button><br>
    <!--<button class="button " (click)="btnRoClick();"><a href="http://localhost:4200/#/ro?user=kriti.a.sharma@ericsson.com&ver=1.34.21" style="color:black; text-decoration:none">Request Overview</a></button><br>-->
    <button class="button-main" (click)="btnOpenRequestClick();">My Open Requests </button><br>
    <button class="button-main" (click)="btnClosedRequestClick();">My Closed Requests </button><br>
    <button class="button-main" *ngIf="su_fwchanges" (click)="btnRoClick();">Request Overview</button><br>
    <button class="button-main" (click)="btnProfileClick();">My Profile</button>
  </div>
  <div class="right">
    Welcome to the <b>Implementation Services Request</b>(ISR) Tool.<br><br>
    Use the ISR tool to create standardized design & implementation requests and requests for<br>
    non-standard projects.<br><br>
    Click on the button 'NEW REQUEST' to create a new request.<br>
    If you have not finished a previously created request, clicking on the 'NEW REQUEST' button will provide<br>
    you with the choice to finish it or start a new one.<br><br>
    To view your earlier created requests click on 'REQUEST OVERVIEW'.<br><br>
    To change your profile information (name, phone, department) click on 'MY PROFILE'.
    <div class="box">
      <!-- <p style="color:blue; text-align:center; font-size: 130%;">ALL SUBMITTED TEXT ON ALL FORMS MUST BE IN ENGLISH!<br> -->
      <p style="color:blue; text-align:center">ALL SUBMITTED TEXT ON ALL FORMS MUST BE IN ENGLISH!<br>
        <span style="color:black;font-size: 70%;">If you require assistance, please contact </span><a
          style="color:blue;font-size: 70%;cursor: pointer;" href="mailto:enn.project.support@ericsson.com"><u>ENN
            Project Support.</u></a><span style="color:black;font-size: 70%;"> You can also create a new "HELP"
          request.</span>
      </p>
    </div>
  </div>
</div>